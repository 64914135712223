import { AxiosPromise } from 'axios';
import http from './httpBase';
import { TopNCategory, TopNCategoryListItem } from './types';
import { topNCategoryData, topNCategoriesListData } from './mockData';

const { delete: deleteType, get, post, mock } = http;
export const TOP_N_CATEGORY_URL = '/top-n-categories';

const getTopNCategoriesList = (): AxiosPromise<TopNCategoryListItem[]> =>
  get(`${TOP_N_CATEGORY_URL}`);

mock.onGet(TOP_N_CATEGORY_URL).reply(200, topNCategoriesListData);

const createTopNCategory = (body: TopNCategory): AxiosPromise<TopNCategory> =>
  post(`${TOP_N_CATEGORY_URL}`, body);

mock.onPost(TOP_N_CATEGORY_URL).reply(200, topNCategoryData);

const getTopNCategoryDetail = (id: number): AxiosPromise<TopNCategory> =>
  get(`${TOP_N_CATEGORY_URL}/${id}`);

mock.onGet(/\/top-n-categories\/\d+/).reply(200, topNCategoryData);

const updateTopNCategory = (body: TopNCategory): AxiosPromise<TopNCategory> =>
  post(`${TOP_N_CATEGORY_URL}/${body.id}`, body);

mock.onPost(/\/top-n-categories\/\d+/).reply(200, topNCategoryData);

const deleteTopNCategory = (id: number): AxiosPromise =>
  deleteType(`${TOP_N_CATEGORY_URL}/${id}`);

mock.onDelete(/\/top-n-categories\/\d+/).reply(200);

export default {
  getTopNCategoriesList,
  createTopNCategory,
  getTopNCategoryDetail,
  updateTopNCategory,
  deleteTopNCategory,
};
