import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import {
  DashboardProductRequest,
  DashboardProductResponse, PriceCalculationResponse,
  Product,
  ProductDetail, RecalculationStrategy,
} from '../api/types';
import { StoreModel } from './types';
import { productsApi } from '../api';

export const defaultProduct: ProductDetail = {
  actualPrice: 0,
  actualPriceDiscount: 0,
  purchasePrice: 0,
  purchasePriceCurrency: '',
  sellingPriceCurrency: '',
  basePrice: 0,
  newPrice: 0,
  newPriceDiscount: 0,
  productDescription: '',
  id: -1,
  productId: '',
  supplier: { id: '', name: '' },
  category: { id: '', name: '' },
  parameters: [],
  ean: '',
  skipTopN: false,
  skipMinMargin: false,
  skipMaxDiscount: false,
  rrp: 0,
  rsp: 0,
  sellingPriceRate: 0,
  rounding: 0,
  change: 0,
  pricingError: false,
  rules: [],
};

export interface DashboardProductsStore {
  isLoading: boolean;
  list: OrderedMap<string, Product>;
  pagination: {
    total: number;
  };
  detail: ProductDetail;
  calculationState: PriceCalculationResponse;
  selectedDetail?: { id: number };
  lastFetchListRequest?: DashboardProductRequest;
  setSelectedDetail: Action<DashboardProductsStore, { id: number }>;
  setFetchListRequest: Action<DashboardProductsStore, DashboardProductRequest>;
  setLoading: Action<DashboardProductsStore, boolean>;
  setList: Action<DashboardProductsStore, DashboardProductResponse>;
  setDetail: Action<DashboardProductsStore, ProductDetail>;
  setCalculationState: Action<DashboardProductsStore, PriceCalculationResponse>;
  recalculatePrices: Thunk<DashboardProductsStore, RecalculationStrategy, any, StoreModel>;
  confirmAllDraftRules: Thunk<DashboardProductsStore, void, any, StoreModel>;
  fetchList: Thunk<DashboardProductsStore,
    DashboardProductRequest,
    any,
    StoreModel>;
  fetchListByLastRequest: Thunk<DashboardProductsStore, void, any, StoreModel>;
  fetchProductDetail: Thunk<DashboardProductsStore,
    { id: number },
    any,
    StoreModel>;
  fetchCalculationState: Thunk<DashboardProductsStore, void, any, StoreModel>
}

const dashboardProducts: DashboardProductsStore = {
  isLoading: false,
  list: OrderedMap(),
  pagination: {
    total: 0,
  },
  detail: defaultProduct,
  calculationState: { running: false, lastRunOutcome: 'OK' },
  selectedDetail: undefined,
  setSelectedDetail: action((state, payload) => {
    state.selectedDetail = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, { data, page }) => {
    state.list = OrderedMap(data.map(v => [`${v.id}`, v]));
    state.pagination.total = page.total;
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  setCalculationState: action((state, payload) => {
    state.calculationState = payload;
  }),
  setFetchListRequest: action((state, payload) => {
    state.lastFetchListRequest = payload;
  }),
  fetchList: thunk(async (actions, request) => {
    try {
      actions.setLoading(true);
      const { data } = await productsApi.getProducts(request);

      actions.setList(data);
      actions.setLoading(false);
      actions.setFetchListRequest(request);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchListByLastRequest: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const request = getState().lastFetchListRequest;
    if (request) {
      getStoreActions().dashboardProducts.fetchList(request);
    }
  }),
  fetchProductDetail: thunk(async (actions, value) => {
    try {
      const { data } = await productsApi.getProductDetail(value.id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  fetchCalculationState: thunk(async (actions) => {
    const { data } = await productsApi.getCalculationState();
    actions.setCalculationState(data);
  }),
  recalculatePrices: thunk(async (actions, payload) => {
    const { data } = await productsApi.recalculatePrices(payload);
    actions.setCalculationState(data);
  }),
  confirmAllDraftRules: thunk(async (actions, payload) => {
    const { data } = await productsApi.confirmAllDraftRules();
    actions.setCalculationState(data);
  }),
};

export default dashboardProducts;
