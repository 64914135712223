import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const NewButton: React.FC<ButtonProps> = props => (
  <Button className='new-button' type='dashed' block icon='plus' {...props}>
    {props.title && <span className='new-button-help'>{props.title}</span>}
  </Button>
);
export default NewButton;
