import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import {
  Parameter, ProductBatchStateUpdateBody,
  ProductException,
  ProductExceptionDeleteBody,
  ProductExceptionIn,
  ProductExceptionsListItem,
  RuleStates,
} from '../api/types';
import { StoreModel } from './types';
import { productExceptionsApi } from '../api';

export const defaultPE: ProductExceptionIn = {
  id: -1,
  state: RuleStates.DRAFT,
  productId: '',
  ean: '',
  evaluationExpression: '',
  parameters: [] as Parameter[],
  validFrom: '',
  validTo: '',
  active: true,
};

export interface ProductExceptionsStore {
  isLoading: boolean;
  list: OrderedMap<number, ProductExceptionsListItem>;
  detail: ProductException;
  searchValue?: number;
  selectedRowKeys: number[] | string[];
  setLoading: Action<ProductExceptionsStore, boolean>;
  setList: Action<ProductExceptionsStore, ProductExceptionsListItem[]>;
  setDetail: Action<ProductExceptionsStore, ProductException>;
  setSearchValue: Action<ProductExceptionsStore, number | undefined>;
  setSelectedRowKeys: Action<ProductExceptionsStore, number[] | string[]>;
  fetchList: Thunk<ProductExceptionsStore, void, any, StoreModel>;
  fetchPE: Thunk<ProductExceptionsStore,
    number,
    any,
    StoreModel,
    Promise<boolean>>;
  createPE: Thunk<ProductExceptionsStore,
    ProductException,
    any,
    StoreModel,
    Promise<boolean>>;
  updatePE: Thunk<ProductExceptionsStore,
    ProductException,
    any,
    StoreModel,
    Promise<boolean>>;
  deletePE: Thunk<ProductExceptionsStore,
    number[] | string[],
    any,
    StoreModel,
    Promise<boolean>>;
  batchUpdatePE: Thunk<ProductExceptionsStore,
    { ids: number[] | string[]; state: RuleStates },
    any,
    StoreModel,
    Promise<boolean>>;
  uploadCSV: Thunk<ProductExceptionsStore,
    string,
    any,
    StoreModel,
    Promise<boolean>>;
}

const productExceptions: ProductExceptionsStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultPE,
  searchValue: undefined,
  selectedRowKeys: [],
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  setSearchValue: action((state, payload) => {
    state.searchValue = payload;
  }),
  setSelectedRowKeys: action((state, payload) => {
    state.selectedRowKeys = payload;
  }),
  fetchList: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setLoading(true);
      actions.setSelectedRowKeys([]);
      const { searchValue } = getState();
      const { data } = await productExceptionsApi.getProductExceptionsList(
        searchValue,
      );
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchPE: thunk(async (actions, id) => {
    try {
      const { data } = await productExceptionsApi.getProductExceptionDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createPE: thunk(async (actions, value) => {
    try {
      await productExceptionsApi.createProductException(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updatePE: thunk(async (actions, value) => {
    try {
      await productExceptionsApi.updateProductException(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  deletePE: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await productExceptionsApi.deleteProductExceptions({
        ids: value as number[],
      } as ProductExceptionDeleteBody);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  batchUpdatePE: thunk(async (actions, { ids, state }) => {
    try {
      actions.setLoading(true);
      await productExceptionsApi.updateProductExceptions({
        ids: ids as number[],
        state,
      } as ProductBatchStateUpdateBody);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
  uploadCSV: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await productExceptionsApi.importProductExceptions(value);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default productExceptions;
