import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal } from 'antd';
import NewButton from '../components/NewButton';
import useFormRef from '../hooks/useFormRef';
import { Mode } from '../store/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { dateFormat } from '../components/PEForm';
import PricingRulesTable from '../components/PricingRulesTable';
import { PricingRule, RuleStates } from '../api/types';
import { defaultPricingRule } from '../store/pricingRules';
import PricingRuleForm from '../components/PricingRuleForm';
import { removeFieldExternalId, removeFieldExternalIdFromGroups } from '../utils/DataManagement';
import RuleFormTitle from '../components/RuleFromTitle';
import RuleDetailFormButtons from '../components/RuleDetailFormButtons';

const PricingRulesPage: React.FC = () => {
    const [isVisible, setVisible] = useState(false);
    const [isVisibleDC, setVisibleDC] = useState(false);

    const [mode, setMode] = useState<Mode>(Mode.VIEW);
    const { saveFormRef, formRef } = useFormRef<PricingRule>();
    const {
      setDetail,
      fetchPricingRule,
      createPricingRule,
      updatePricingRule,
      deletePricingRule,
      fetchList,
      setSelectedDetailId,
    } = useStoreActions(actions => actions.pricingRules);
    const { detail, selectedDetailId } = useStoreState(
      state => state.pricingRules,
    );
    const { fetchCategories } = useStoreActions(actions => actions.general);
    const { formatMessage } = useIntl();

    useEffect(() => {
      fetchList();
    }, [fetchList]);

    const handleEdit = async (id: number) => {
      await fetchPricingRule(id);
      await fetchCategories();
      setMode(Mode.EDIT);
      setVisible(true);
    };

    const handleRemove = async (id: number) => {
      setSelectedDetailId(id);
      setVisibleDC(true);
    };

    const handleView = async (id: number) => {
      await fetchPricingRule(id);
      setMode(Mode.VIEW);
      setVisible(true);
    };

    const handleNew = () => {
      fetchCategories();

      // TODO: this fix problem when some parameterGroups information are modified
      //  in defaultPricingRule after filling them on form somewhat
      const cloned = JSON.parse(JSON.stringify(defaultPricingRule));
      setDetail(cloned);
      setMode(Mode.CREATE);
      setVisible(true);
    };

    const handleSave = (newState: RuleStates) => {
      //  e.preventDefault();
      formRef!.validateFields(
        // @ts-ignore
        (err, { validFromM, validToM, suppliers, categories, parameterGroups, ...fieldsValue }) => {
          // @ts-ignore
          const inValues = {
            ...fieldsValue,
            validFrom: validFromM != null ? validFromM.format(dateFormat) : '',
            // @ts-ignore
            validTo: validToM != null ? validToM.format(dateFormat) : '',
            // @ts-ignore
            suppliers: removeFieldExternalId(suppliers, false),
            // @ts-ignore
            categories: removeFieldExternalId(categories, false),
            // @ts-ignore
            parameterGroups: removeFieldExternalIdFromGroups(parameterGroups),
          } as PricingRule;

          if (!err) {
            switch (mode) {
              case Mode.CREATE:
                inValues.state = RuleStates.DRAFT;
                createPricingRule(inValues).then(res => {
                  if (res) {
                    message.success(
                      formatMessage({ id: 'common.message.success_new' }),
                    );
                    setVisible(false);
                  }
                });

                break;
              case Mode.EDIT:
                inValues.state = newState;
                updatePricingRule(inValues).then(res => {
                  if (res) {
                    message.success(
                      formatMessage({ id: 'common.message.success_edit' }),
                    );
                    setVisible(false);
                  }
                });

                break;
              default:
                break;
            }
          }
        },
      );
    };

    const handleDeleteSelected = () => {
      deletePricingRule(selectedDetailId).then(res => {
        setVisibleDC(false);
        if (res) {
          message.success(formatMessage({ id: 'common.message.success_delete' }));
          setVisible(false);
        }
      });
    };

    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setVisible(false);
      formRef!.resetFields();
    };

    return (
      <div className='max-discounts'>
        <NewButton onClick={handleNew} />
        <Modal
          title={<FormattedMessage id='common.confirmation' />}
          visible={isVisibleDC}
          width={700}
          onCancel={e => {
            e.preventDefault();
            setVisibleDC(false);
          }}
          okText={<FormattedMessage id='common.delete' />}
          onOk={handleDeleteSelected}
        >
          <FormattedMessage id='common.confirm_delete' />
        </Modal>
        <PricingRulesTable onEdit={handleEdit} onView={handleView} onRemove={handleRemove} />
        <Modal
          title={<RuleFormTitle mode={mode} ruleState={detail.state} messageId='pricing_rules.detail.title' />}
          visible={isVisible}
          width={850}
          footer={mode === Mode.VIEW ? null : <RuleDetailFormButtons
            ruleState={detail.state}
            mode={mode}
            onCancel={onCancel}
            onSave={newState => handleSave(newState)}
            onDelete={() => handleRemove(detail.id)}
          />}
          onCancel={onCancel}
        >
          <PricingRuleForm ref={saveFormRef} data={detail} mode={mode} />
        </Modal>
      </div>
    );
  }
;
export default PricingRulesPage;
