import { AxiosPromise } from 'axios';
import http from './httpBase';
import { MinMargin, MinMarginListItem } from './types';
import { minMarginsData, minMarginsListData } from './mockData';

const { delete: deleteType, get, post, mock } = http;
export const MIN_MARGINS_URL = '/min-margins';

const getMinMarginsList = (): AxiosPromise<MinMarginListItem[]> =>
  get(`${MIN_MARGINS_URL}`);

mock.onGet(MIN_MARGINS_URL).reply(200, minMarginsListData);

const createMinMargin = (
  body: MinMargin,
): AxiosPromise<MinMargin> => post(`${MIN_MARGINS_URL}`, body);

mock.onPost(MIN_MARGINS_URL).reply(200, minMarginsData);

const getMinMarginDetail = (
  id: number,
): AxiosPromise<MinMargin> => get(`${MIN_MARGINS_URL}/${id}`);

mock.onGet(/\/min-margins\/\d+/).reply(200, minMarginsData);

const updateMinMargin = (
  body: MinMargin,
): AxiosPromise<MinMargin> =>
  post(`${MIN_MARGINS_URL}/${body.id}`, body);

mock.onPost(/\/min-margins\/\d+/).reply(200, minMarginsData);

const deleteMinMargin = (
  id: number,
): AxiosPromise => deleteType(`${MIN_MARGINS_URL}/${id}`);

mock.onDelete(/\/min-margins\/\d+/).reply(200);

export default {
  getMinMarginsList,
  createMinMargin,
  getMinMarginDetail,
  updateMinMargin,
  deleteMinMargin,
};
