import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProductExceptionsPage from '../pages/ProductExceptionsPage';
import { useStoreState } from '../store/hooks';
import PrivateRoute from './PrivateRoute';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import MaxDiscountsPage from '../pages/MaxDiscountsPage';
import MinMarginsPage from '../pages/MinMarginsPage';
import PricingRulesPage from '../pages/PricingRulesPage';
import TopNCategoriesPage from '../pages/TopNCategoriesPage';
import DashboardListPage from '../pages/DashboardListPage';
import DashboardReportsPage from '../pages/DashboardReportsPage';

export const INDEX = '/';
export const LOGIN = '/login';
export const PRODUCT_EXCEPTIONS = '/product-exceptions';
export const PRICING_RULES = '/pricing-rules';
export const MAX_DISCOUNTS = '/max-discounts';
export const MIN_MARGINS = '/min-margins';
export const TOP_N_CATEGORIES = '/top-n-categories';
export const LOGOUT = '/logout';
export const DASHBOARD_LIST = '/dashboard-list';
export const DASHBOARD_REPORTS = '/dashboard-reports';

export const getHomeLink = (isLoggedIn: boolean) =>
  isLoggedIn ? PRODUCT_EXCEPTIONS : LOGIN;

const Routes: React.FC = () => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

  return (
    <Switch>
      <Redirect push exact from={INDEX} to={getHomeLink(isLoggedIn)} />
      {!isLoggedIn && <Route path={LOGIN} component={LoginPage} />}
      <PrivateRoute
        path={PRODUCT_EXCEPTIONS}
        component={ProductExceptionsPage}
      />
      <PrivateRoute path={PRICING_RULES} component={PricingRulesPage} />
      <PrivateRoute path={TOP_N_CATEGORIES} component={TopNCategoriesPage} />
      <PrivateRoute path={MIN_MARGINS} component={MinMarginsPage} />
      <PrivateRoute path={MAX_DISCOUNTS} component={MaxDiscountsPage} />
      <PrivateRoute path={DASHBOARD_LIST} component={DashboardListPage} />
      <PrivateRoute path={DASHBOARD_REPORTS} component={DashboardReportsPage} />
      <PrivateRoute path={LOGOUT} component={LogoutPage} />
      <Redirect to={INDEX} push />
    </Switch>
  );
};

export default Routes;
