import React from 'react';
import { Icon, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { RuleStates, TopNCategoryListItem } from '../api/types';
import UpdateButton from './UpdateButton';
import {
  flatIdAndName,
  numberToText,
  parseExpressionValue,
} from '../utils/DataManagement';
import RuleStateCell from './RuleStateCell';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
}

const TopNCategoriesTable: React.FC<Props> = ({ onEdit, onView, onRemove }) => {
  const { list: data, isLoading } = useStoreState(
    state => state.topNCategories,
  );
  const { formatMessage } = useIntl();

  return (
    <Table<TopNCategoryListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      childrenColumnName='sourceTopNCategories'
      rowClassName={() => `c-pointer`}
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'top_n_categories.table.titles.id' }),
          fixed: 'left',
          width: 70,
          align: 'center',
        },
        {
          dataIndex: 'category',
          title: formatMessage({
            id: 'top_n_categories.table.titles.category',
          }),
          render: (text, { category }) => <>{category ? category.name : ''}</>,
        },
        {
          dataIndex: 'priority',
          title: formatMessage({
            id: 'top_n_categories.table.titles.priority',
          }),
          render: (text, { priority }) => numberToText(priority),
        },
        {
          dataIndex: 'priceFrom',
          title: formatMessage({
            id: 'top_n_categories.table.titles.price_from',
          }),
          width: 90,
          render: (text, { priceFrom }) => numberToText(priceFrom),
        },
        {
          dataIndex: 'priceTo',
          title: formatMessage({
            id: 'top_n_categories.table.titles.price_to',
          }),
          width: 90,
          render: (text, { priceTo }) => numberToText(priceTo),
        },
        {
          dataIndex: 'topN',
          title: formatMessage({
            id: 'top_n_categories.table.titles.top_n',
          }),
          width: 100,
        },
        {
          dataIndex: 'topNExpression',
          title: formatMessage({
            id: 'top_n_categories.table.titles.top_n_expression',
          }),
          render: (text, { topNExpression }) => {
            if (!topNExpression) {
              return '';
            }
            const parsed = parseExpressionValue(topNExpression);
            return (
              formatMessage({
                id: `top_n_categories.detail.top_n_expression_choice.${parsed.base}_help`,
              }) +
              parsed.operator +
              parsed.numberValue
            );
          },
        },
        {
          dataIndex: 'roudingType',
          title: formatMessage({
            id: 'top_n_categories.table.titles.rounding',
          }),
          width: 100,
          render: (text, { roudingType }) =>
            formatMessage({ id: `enums.rounding.${roudingType}` }),
        },
        {
          dataIndex: 'comparedShops',
          title: formatMessage({
            id: 'top_n_categories.table.titles.compared_shops_ids',
          }),
          width: 170,
          render: (text, { comparedShops }) => (
            <>{flatIdAndName(comparedShops, formatMessage, ', ', 'id')}</>
          ),
        },
        {
          dataIndex: 'state',
          title: formatMessage({
            id: 'top_n_categories.table.titles.state',
          }),
          render: (text, { state }) => <RuleStateCell ruleState={state} />,
          width: 90,
        },
        {
          key: 'update-button',
          align: 'left',
          width: 125,
          className: 'action-column',
          render: (text, { id, state }) =>
            state !== RuleStates.LOCKED ? (
              <>
                <UpdateButton
                  onClick={e => {
                    e.preventDefault();
                    onEdit(id);
                  }}
                />
                {(state === RuleStates.DRAFT ||
                  state === RuleStates.TO_DELETE) && (
                  <Icon
                    className='delete-icon'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove(id);
                    }}
                    type='delete'
                  />
                )}
              </>
            ) : (
              <></>
            ),
        },
      ]}
    />
  );
};

export default TopNCategoriesTable;
