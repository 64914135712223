import productExceptions from './productExceptions';
import user from './user';
import maxDiscounts from './maxDiscounts';
import minMargins from './minMargins';
import pricingRules from './pricingRules';
import business from './business';
import topNCategories from './topNCategories';
import products from './products';
import dashboard from './dashboard';

export const productExceptionsApi = productExceptions;
export const maxDiscountsApi = maxDiscounts;
export const userApi = user;
export const minMarginsApi = minMargins;
export const pricingRulesApi = pricingRules;
export const businessApi = business;
export const topNCategoriesApi = topNCategories;
export const dashboardApi = dashboard;
export const productsApi = products;
