import React, { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import {
  DASHBOARD_LIST,
  DASHBOARD_REPORTS,
  MAX_DISCOUNTS,
  MIN_MARGINS,
  PRICING_RULES,
  PRODUCT_EXCEPTIONS,
  TOP_N_CATEGORIES,
} from '../routes/Routes';
import { useStoreActions, useStoreState } from '../store/hooks';
import http, { getWithExpiry } from '../api/httpBase';
import packageJson from '../../package.json';

const { Item, SubMenu } = Menu;
const { setAuth } = http;

export interface Props {
}

const SideBar: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const collapsed = useStoreState(state => state.general.collapsed);
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
  const { setLoggedIn, setUser } = useStoreActions(actions => actions.user);

  useEffect(() => {
    const credentials = getWithExpiry();
    if (
      credentials &&
      credentials.username !== '' &&
      credentials.password !== ''
    ) {
      setLoggedIn(true);
      setUser({ username: credentials.username, name: credentials.username });
      setAuth({
        username: credentials.username,
        password: credentials.password,
      });
    }
  }, [setLoggedIn, setUser]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className='side-bar'
      width={230}
    >
      <div className='logo'>PMS Admin</div>
      {isLoggedIn && (
        <Menu theme='dark' mode='inline' selectedKeys={[pathname]} defaultOpenKeys={['sub1']}>
          <Item key={PRODUCT_EXCEPTIONS}>
            <Link to={PRODUCT_EXCEPTIONS}>
              <FormattedMessage id='side_bar.product_exceptions' />
            </Link>
          </Item>
          <Item key={PRICING_RULES}>
            <Link to={PRICING_RULES}>
              <FormattedMessage id='side_bar.pricing_rules' />
            </Link>
          </Item>
          <Item key={TOP_N_CATEGORIES}>
            <Link to={TOP_N_CATEGORIES}>
              <FormattedMessage id='side_bar.top_n_categories' />
            </Link>
          </Item>
          <Item key={MIN_MARGINS}>
            <Link to={MIN_MARGINS}>
              <FormattedMessage id='side_bar.min_margins' />
            </Link>
          </Item>
          <Item key={MAX_DISCOUNTS}>
            <Link to={MAX_DISCOUNTS}>
              <FormattedMessage id='side_bar.max_discounts' />
            </Link>
          </Item>
          <SubMenu
            key='sub1'
            title={<FormattedMessage id='side_bar.dashboard' />}
          >
            <Item key={DASHBOARD_LIST}>
              <Link to={DASHBOARD_LIST}>
                <FormattedMessage id='side_bar.dashboard_list' />
              </Link>
            </Item>
            <Item key={DASHBOARD_REPORTS}>
              <Link to={DASHBOARD_REPORTS}>
                <FormattedMessage id='side_bar.dashboard_reports' />
              </Link>
            </Item>
          </SubMenu>
        </Menu>
      )}
      <div className='version'><FormattedMessage id='side_bar.version' /> {packageJson.version}</div>
    </Layout.Sider>
  );
};

export default SideBar;
