import { Action, action, thunk, Thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { IdAndNameType } from '../api/types';
import { StoreModel } from './types';
import { businessApi } from '../api';

export const GENERAL_PAGE_SIZE = 50;

export interface GeneralStore {
  collapsed: boolean;
  loadingSuppliers: boolean;
  loadingParameters: boolean;
  searchParameter: string;
  searchCategory: string;
  searchSupplier: string;
  supplier?: IdAndNameType;
  parameter?: IdAndNameType;
  parametersList: OrderedMap<string, IdAndNameType>;
  categoriesList: OrderedMap<string, IdAndNameType>;
  suppliersList: OrderedMap<string, IdAndNameType>;
  suppliersTotal: number;
  parametersTotal: number;
  categoriesTotal: number;
  heurekaShopTotal: number;
  heurekaShopList: OrderedMap<string, IdAndNameType>;
  setCollapsed: Action<GeneralStore, boolean>;
  setLoadingSuppliers: Action<GeneralStore, boolean>;
  setLoadingParameters: Action<GeneralStore, boolean>;
  setSearchParameter: Action<GeneralStore, string>;
  setSearchCategory: Action<GeneralStore, string>;
  setSearchSupplier: Action<GeneralStore, string>;
  setParametersList: Action<GeneralStore, IdAndNameType[]>;
  setCategoriesList: Action<GeneralStore, IdAndNameType[]>;
  setSuppliersList: Action<GeneralStore, IdAndNameType[]>;
  setSuppliersTotal: Action<GeneralStore, number>;
  setSupplier: Action<GeneralStore, IdAndNameType>;
  setParameter: Action<GeneralStore, IdAndNameType>;
  setParametersTotal: Action<GeneralStore, number>;
  setCategoriesTotal: Action<GeneralStore, number>;
  setHeurekaShopTotal: Action<GeneralStore, number>;
  setHeurekaShopList: Action<GeneralStore, IdAndNameType[]>;
  fetchParameters: Thunk<GeneralStore, void, any, StoreModel>;
  fetchSuppliers: Thunk<GeneralStore, void, any, StoreModel>;
  fetchSupplier: Thunk<GeneralStore, string, any, StoreModel>;
  fetchParameter: Thunk<GeneralStore, string, any, StoreModel>;
  fetchCategories: Thunk<GeneralStore, void, any, StoreModel>;
  fetchHeurekaShop: Thunk<GeneralStore, string, any, StoreModel>;
}

const general: GeneralStore = {
  collapsed: false,
  loadingSuppliers: false,
  loadingParameters: false,
  searchParameter: '',
  searchCategory: '',
  searchSupplier: '',
  suppliersTotal: 0,
  categoriesTotal: 0,
  parametersTotal: 0,
  heurekaShopTotal: 0,
  parametersList: OrderedMap(),
  categoriesList: OrderedMap(),
  suppliersList: OrderedMap(),
  heurekaShopList: OrderedMap(),
  setCollapsed: action((state, payload) => {
    state.collapsed = payload;
  }),
  setLoadingSuppliers: action((state, payload) => {
    state.loadingSuppliers = payload;
  }),
  setLoadingParameters: action((state, payload) => {
    state.loadingParameters = payload;
  }),
  setSearchParameter: action((state, payload) => {
    state.searchParameter = payload;
  }),
  setSearchCategory: action((state, payload) => {
    state.searchCategory = payload;
  }),
  setSearchSupplier: action((state, payload) => {
    state.searchSupplier = payload;
  }),
  setParametersList: action((state, payload) => {
    state.parametersList = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setCategoriesList: action((state, payload) => {
    state.categoriesList = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setSuppliersList: action((state, payload) => {
    state.suppliersList = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setSuppliersTotal: action((state, payload) => {
    state.suppliersTotal = payload;
  }),
  setCategoriesTotal: action((state, payload) => {
    state.categoriesTotal = payload;
  }),
  setParametersTotal: action((state, payload) => {
    state.parametersTotal = payload;
  }),
  setHeurekaShopTotal: action((state, payload) => {
    state.heurekaShopTotal = payload;
  }),
  setSupplier: action((state, payload) => {
    state.supplier = payload;
  }),
  setParameter: action((state, payload) => {
    state.parameter = payload;
  }),
  setHeurekaShopList: action((state, payload) => {
    state.heurekaShopList = OrderedMap(payload.map(v => [v.id, v]));
  }),
  fetchParameters: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setLoadingParameters(true);
      const { searchParameter } = getState();
      const { data } = await businessApi.getParameters(
        searchParameter,
        GENERAL_PAGE_SIZE,
      );
      actions.setParametersList(data ? data.data : []);
      await actions.setParametersTotal(data ? data.total : 0);
      actions.setLoadingParameters(false);
      return data;
    } catch (error) {
      actions.setLoadingParameters(false);
      return false;
    }
  }),
  fetchParameter: thunk(async (actions, payload) => {
    try {
      const { data } = await businessApi.getParameter(payload);
      return data;
    } catch (error) {
      return false;
    }
  }),
  fetchCategories: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setSearchCategory('');
      const { searchCategory } = getState();
      const { data } = await businessApi.getCategories(
        searchCategory,
        10000,
      );
      await actions.setCategoriesList(data ? data.data : []);
      await actions.setCategoriesTotal(data ? data.total : 0);
    } catch (error) {
      // comment
    }
  }),
  fetchSuppliers: thunk(async (actions, payload, { getState }) => {
    try {
      actions.setLoadingSuppliers(true);
      const { searchSupplier } = getState();
      const { data } = await businessApi.getSuppliers(
        searchSupplier,
        GENERAL_PAGE_SIZE,
      );
      await actions.setSuppliersList(data ? data.data : []);
      await actions.setSuppliersTotal(data ? data.total : 0);
      actions.setLoadingSuppliers(false);
      return data;
    } catch (error) {
      actions.setLoadingSuppliers(false);
      return false;
    }
  }),
  fetchSupplier: thunk(async (actions, payload) => {
    try {
      const { data } = await businessApi.getSupplier(payload);
      return data;
    } catch (error) {
      return false;
    }
  }),
  fetchHeurekaShop: thunk(async (actions, payload) => {
    try {
      const { data } = await businessApi.getHeurekaShops(
        payload,
        GENERAL_PAGE_SIZE,
      );
      actions.setHeurekaShopList(data ? data.data : []);
      await actions.setHeurekaShopTotal(data ? data.total : 0);
    } catch (error) {
      // comment
    }
  }),
};

export default general;
