import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Icon, message, Modal, Upload } from 'antd';
import ProductExceptionsTable from '../components/ProductExceptionsTable';
import NewButton from '../components/NewButton';
import useFormRef from '../hooks/useFormRef';
import { Mode } from '../store/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { ProductExceptionIn, RuleStates } from '../api/types';
import { defaultPE } from '../store/productExceptions';
import PEForm, { dateFormat } from '../components/PEForm';
import PESearchButton from '../components/PESearchButton';
import { removeFieldExternalIdToParameters } from '../utils/DataManagement';
import RuleDetailFormButtons from '../components/RuleDetailFormButtons';
import RuleFormTitle from '../components/RuleFromTitle';

const ProductExceptionsPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [isVisibleDC, setVisibleDC] = useState<
    '' | 'delete' | 'confirm' | 'markAsDelete'
  >('');
  const [isVisibleHint, setVisibleHint] = useState(false);
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<ProductExceptionIn>();
  const {
    setDetail,
    fetchPE,
    createPE,
    updatePE,
    deletePE,
    batchUpdatePE,
    uploadCSV,
    fetchList,
  } = useStoreActions(actions => actions.productExceptions);
  const { list, detail, selectedRowKeys } = useStoreState(
    state => state.productExceptions,
  );

  const { setSelectedRowKeys } = useStoreActions(
    state => state.productExceptions,
  );

  const { formatMessage } = useIntl();

  const handleEdit = async (id: number) => {
    await fetchPE(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleView = async (id: number) => {
    await fetchPE(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const handleNew = () => {
    setDetail(defaultPE);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const showConfirmation = (type: 'delete' | 'confirm' | 'markAsDelete') => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      message.warning(
        formatMessage({ id: 'common.message.no_records_selected' }),
      );
      return;
    }
    setVisibleDC(type);
  };

  const handleSave = (newState: RuleStates) => {
    formRef!.validateFields(
      // @ts-ignore
      (err, { validFromM, validToM, parameters, ...fieldsValue }) => {
        // @ts-ignore
        const inValues = {
          ...fieldsValue,
          validFrom: validFromM != null ? validFromM.format(dateFormat) : '',
          // @ts-ignore
          validTo: validToM != null ? validToM.format(dateFormat) : '',
          // @ts-ignore
          parameters: removeFieldExternalIdToParameters(parameters, false),
        } as ProductExceptionIn;

        if (!err) {
          switch (mode) {
            case Mode.CREATE:
              inValues.state = RuleStates.DRAFT;
              createPE(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_new' }),
                  );
                  setVisible(false);
                }
              });

              break;
            case Mode.EDIT:
              inValues.state = newState;
              updatePE(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_edit' }),
                  );
                  setVisible(false);
                }
              });

              break;
            default:
              break;
          }
        }
      },
    );
  };

  const handleSelectedAction = () => {
    if (isVisibleDC === 'delete') {
      deletePE(selectedRowKeys).then(res => {
        setVisibleDC('');
        if (res) {
          message.success(
            formatMessage({ id: 'common.message.success_delete' }),
          );
          setVisible(false);
          setSelectAllChecked(false);
        }
      });
    } else if (isVisibleDC === 'confirm') {
      batchUpdatePE({ ids: selectedRowKeys, state: RuleStates.FINAL }).then(
        res => {
          setVisibleDC('');
          if (res) {
            message.success(
              formatMessage({ id: 'common.message.success_edit' }),
            );
            setVisible(false);
            setSelectAllChecked(false);
          }
        },
      );
    } else {
      batchUpdatePE({ ids: selectedRowKeys, state: RuleStates.TO_DELETE }).then(
        res => {
          setVisibleDC('');
          if (res) {
            message.success(
              formatMessage({ id: 'common.message.success_edit' }),
            );
            setVisible(false);
            setSelectAllChecked(false);
          }
        },
      );
    }
  };

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    showUploadList: false,
    onChange(info: any) {
      if (info.file.status === 'done') {
        message.success(formatMessage({ id: 'common.message.success_upload' }));
      } else if (info.file.status === 'error') {
        message.error(formatMessage({ id: 'common.message.error_upload' }));
      }
    },
    customRequest: (options: {
      onSuccess: any;
      onError: any;
      file: any;
      onProgress: any;
    }) => {
      const { onSuccess, onError, file } = options;
      try {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          uploadCSV(reader.result as string).then((value: boolean) => {
            if (value) {
              fetchList();
              onSuccess('Ok');
            }
          });
        };
        setSelectAllChecked(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('customRequest error: ', err);
        onError({ err });
      }
    },
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisible(false);
    formRef!.resetFields();
  };

  const handleSelectAll = () => {
    const allRowKeys = Array.from(list.keys());
    if (selectedRowKeys.length === allRowKeys.length) {
      setSelectAllChecked(false);
      setSelectedRowKeys([]);
    } else {
      setSelectAllChecked(true);
      setSelectedRowKeys(allRowKeys);
    }
  };

  return (
    <div className='product-exceptions'>
      {/* @ts-ignore */}
      <Upload {...uploadProps}>
        <Button
          type='primary'
          className='import-button'
          onClick={e => {
            e.preventDefault();
          }}
        >
          <FormattedMessage id='product_exceptions.import_csv' />
        </Button>
      </Upload>
      <Icon
        type='question-circle'
        style={{
          fontSize: '24px',
          margin: '5px 10px 3px 0',
          color: '#1890ff',
          position: 'relative',
          top: '4px',
          cursor: 'pointer',
        }}
        title={formatMessage({ id: 'product_exceptions.import_tooltip' })}
        onClick={e => {
          e.preventDefault();
          setVisibleHint(true);
        }}
      />
      <Modal
        title={<FormattedMessage id='product_exceptions.import_hint_title' />}
        visible={isVisibleHint}
        width={820}
        cancelButtonProps={{ hidden: true }}
        onCancel={e => {
          e.preventDefault();
          setVisibleHint(false);
        }}
        okText={<FormattedMessage id='common.ok' />}
        onOk={e => {
          e.preventDefault();
          setVisibleHint(false);
        }}
      >
        <FormattedMessage id='product_exceptions.import_hint_text_example' />
        <div
          className='ant-typography'
          style={{
            background: 'rgba(0, 0, 0, 0.06)',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <code
            style={{ background: 'transparent', border: 'none', padding: '0' }}
            dangerouslySetInnerHTML={{
              __html: formatMessage({
                id: 'product_exceptions.import_hint_text_csv',
              }),
            }}
          />
        </div>
        <FormattedMessage id='product_exceptions.import_hint_text' />
        <div
          style={{
            background: 'transparent',
            border: 'none',
            padding: '0',
            marginTop: '5px',
            marginBottom: '10px',
          }}
          dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: 'product_exceptions.import_hint_text_csv2',
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              { id: 'product_exceptions.import_hint_link' },
              { link: './product_exception_csv_import_example.csv' },
            ),
          }}
        />
      </Modal>
      <PESearchButton
        selectAll={setSelectAllChecked}
        className='search-input import-button'
      />
      <Checkbox checked={isSelectAllChecked} onChange={handleSelectAll}>
        <FormattedMessage id='product_exceptions.select_all' />
      </Checkbox>
      <Button
        type='danger'
        className='import-button'
        onClick={e => {
          e.preventDefault();
          showConfirmation('delete');
        }}
      >
        <FormattedMessage id='product_exceptions.delete_selected' />
      </Button>
      <Button
        type='danger'
        className='import-button'
        onClick={e => {
          e.preventDefault();
          showConfirmation('markAsDelete');
        }}
      >
        <FormattedMessage id='product_exceptions.mark_delete_selected' />
      </Button>
      <Button
        type='primary'
        className='import-button'
        onClick={e => {
          e.preventDefault();
          showConfirmation('confirm');
        }}
      >
        <FormattedMessage id='product_exceptions.confirm_selected' />
      </Button>
      <Modal
        title={<FormattedMessage id='common.confirmation' />}
        visible={isVisibleDC !== ''}
        width={700}
        onCancel={e => {
          e.preventDefault();
          setVisibleDC('');
        }}
        okText={
          isVisibleDC === 'delete' ? (
            <FormattedMessage id='common.delete' />
          ) : (
            <FormattedMessage id='common.ok' />
          )
        }
        onOk={handleSelectedAction}
      >
        {isVisibleDC === 'delete' || isVisibleDC === 'markAsDelete' ? (
          <FormattedMessage id='common.confirm_delete_selected' />
        ) : (
          <FormattedMessage id='common.confirm_selected' />
        )}
      </Modal>
      <NewButton onClick={handleNew} />
      <ProductExceptionsTable onEdit={handleEdit} onView={handleView} />
      <Modal
        title={
          <RuleFormTitle
            mode={mode}
            ruleState={detail.state}
            messageId='product_exceptions.detail.title'
          />
        }
        visible={isVisible}
        width={850}
        footer={
          mode === Mode.VIEW ? null : (
            <RuleDetailFormButtons
              ruleState={detail.state}
              mode={mode}
              onCancel={onCancel}
              onSave={newState => handleSave(newState)}
              onDelete={() => {
                deletePE([detail.id]).then(res => {
                  if (res) {
                    message.success(
                      formatMessage({ id: 'common.message.success_delete' }),
                    );
                    setVisible(false);
                  }
                });
              }}
            />
          )
        }
        onCancel={onCancel}
      >
        <PEForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};
export default ProductExceptionsPage;
