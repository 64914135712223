import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RuleStates } from '../api/types';
import { Mode } from '../store/types';

interface Props {
  messageId: string;
  mode: Mode;
  ruleState: RuleStates;
}

const RuleFormTitle: React.FC<Props> = ({ messageId, mode, ruleState }) => {
  let stateLabel = `common.ruleStates.${ruleState}`;

  if (mode === Mode.CREATE) {
    stateLabel = 'common.ruleStates.newRule';
  }
  return (
    <>
      <FormattedMessage id={messageId} /> (<FormattedMessage id={stateLabel} />)
    </>
  );
};

export default RuleFormTitle;
