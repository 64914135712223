import React from 'react';
import { Icon, Table, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import {
  IdAndNameType,
  ParameterGroup,
  PricingRule,
  RuleStates,
} from '../api/types';
import UpdateButton from './UpdateButton';
import RuleStateCell from './RuleStateCell';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
}

const PricingRulesTable: React.FC<Props> = ({ onEdit, onView, onRemove }) => {
  const { list: data, isLoading } = useStoreState(state => state.pricingRules);
  const { formatMessage } = useIntl();

  const getParamGroupTooltip = (parameterGroups: ParameterGroup[]) => {
    parameterGroups.sort((a, b) => {
      if ((a.priority ?? 0) < (b.priority ?? 0)) {
        return 1;
      }
      if ((a.priority ?? 0) > (b.priority ?? 0)) {
        return -1;
      }
      return 0;
    });

    return (
      <>
        {parameterGroups.map(g => (
          <div>
            {g.priority} - {g.name}
          </div>
        ))}
      </>
    );
  };

  const getTooltip = (list: IdAndNameType[]) => {
    list.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return (
      <>
        {list.map(l => (
          <div>{l.name}</div>
        ))}
      </>
    );
  };

  return (
    <Table<PricingRule>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      childrenColumnName='sourceRules'
      rowClassName={record =>
        `c-pointer${!record.active ? ' inactive-row' : ''}`
      }
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'pricing_rules.table.titles.id' }),
          fixed: 'left',
          width: 70,
          align: 'center',
        },
        {
          dataIndex: 'name',
          title: formatMessage({
            id: 'pricing_rules.table.titles.name',
          }),
          width: 250,
        },
        {
          dataIndex: 'categories',
          title: formatMessage({
            id: 'pricing_rules.table.titles.categories',
          }),
          render: (text, { categories }) => (
            <>
              <Tooltip title={getTooltip(categories)}>
                ({categories ? categories.length : '0'})
              </Tooltip>
            </>
          ),
          width: 90,
          align: 'center',
        },
        {
          dataIndex: 'suppliers',
          title: formatMessage({
            id: 'pricing_rules.table.titles.suppliers',
          }),
          render: (text, { suppliers }) => (
            <>
              <Tooltip title={getTooltip(suppliers)}>
                ({suppliers ? suppliers.length : '0'})
              </Tooltip>
            </>
          ),
          width: 90,
          align: 'center',
        },

        {
          dataIndex: 'parameterGroups',
          title: formatMessage({
            id: 'pricing_rules.table.titles.parameters',
          }),
          render: (text, { parameterGroups }) => (
            <>
              <Tooltip title={getParamGroupTooltip(parameterGroups)}>
                ({parameterGroups ? parameterGroups.length : '0'})
              </Tooltip>
            </>
          ),
          width: 90,
          align: 'center',
        },
        {
          dataIndex: 'validFrom',
          title: formatMessage({
            id: 'pricing_rules.table.titles.valid_from',
          }),
          width: 110,
        },
        {
          dataIndex: 'validTo',
          title: formatMessage({
            id: 'pricing_rules.table.titles.valid_to',
          }),
          width: 110,
        },
        {
          dataIndex: 'active',
          title: formatMessage({
            id: 'pricing_rules.table.titles.active',
          }),
          render: (text, { active }) => <>{active ? 'true' : 'false'}</>,
          width: 80,
        },
        {
          dataIndex: 'state',
          title: formatMessage({
            id: 'pricing_rules.table.titles.state',
          }),
          render: (text, { state }) => <RuleStateCell ruleState={state} />,
          width: 90,
        },
        {
          key: 'update-button',
          align: 'left',
          width: 125,
          className: 'action-column',
          render: (text, { id, state }) =>
            state !== RuleStates.LOCKED ? (
              <>
                <UpdateButton
                  onClick={e => {
                    e.preventDefault();
                    onEdit(id);
                  }}
                />
                {(state === RuleStates.DRAFT ||
                  state === RuleStates.TO_DELETE) && (
                  <Icon
                    className='delete-icon'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove(id);
                    }}
                    type='delete'
                  />
                )}
              </>
            ) : (
              <></>
            ),
        },
      ]}
    />
  );
};

export default PricingRulesTable;
