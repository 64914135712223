import { UserStore } from './user';
import { GeneralStore } from './general';
import { ProductExceptionsStore } from './productExceptions';
import { MaxDiscountsStore } from './maxDiscounts';
import { MinMarginsStore } from './minMargins';
import { PricingRulesStore } from './pricingRules';
import { TopNCategoriesStore } from './topNCategories';
import { DashboardReportsStore } from './dashboardReports';
import { DashboardProductsStore } from './dashboardProducts';

export interface StoreModel {
  user: UserStore;
  general: GeneralStore;
  productExceptions: ProductExceptionsStore;
  maxDiscounts: MaxDiscountsStore;
  minMargins: MinMarginsStore;
  pricingRules: PricingRulesStore;
  topNCategories: TopNCategoriesStore;
  dashboardReports: DashboardReportsStore;
  dashboardProducts: DashboardProductsStore;
}

export enum Mode {
  VIEW,
  CREATE,
  EDIT,
}
