import { AxiosPromise } from 'axios';
import http from './httpBase';
import { PricingRule } from './types';
import { pricingRulesData, pricingRulesListData } from './mockData';

const { delete: deleteType, get, post, mock } = http;
export const PRICING_RULES_URL = '/pricing-rules';

const getPricingRulesList = (): AxiosPromise<PricingRule[]> =>
  get(`${PRICING_RULES_URL}`);

mock.onGet(PRICING_RULES_URL).reply(200, pricingRulesListData);

const createPricingRule = (
  body: PricingRule,
): AxiosPromise<PricingRule> => post(`${PRICING_RULES_URL}`, body);

mock.onPost(PRICING_RULES_URL).reply(200, pricingRulesData);

const getPricingRuleDetail = (
  id: number,
): AxiosPromise<PricingRule> => get(`${PRICING_RULES_URL}/${id}`);

mock.onGet(/\/pricing-rules\/\d+/).reply(200, pricingRulesData);

const updatePricingRule = (
  body: PricingRule,
): AxiosPromise<PricingRule> =>
  post(`${PRICING_RULES_URL}/${body.id}`, body);

mock.onPost(/\/pricing-rules\/\d+/).reply(200, pricingRulesData);

const deletePricingRule = (
  id: number,
): AxiosPromise => deleteType(`${PRICING_RULES_URL}/${id}`);

mock.onDelete(/\/pricing-rules\/\d+/).reply(200);

export default {
  getPricingRulesList,
  createPricingRule,
  getPricingRuleDetail,
  updatePricingRule,
  deletePricingRule,
};
