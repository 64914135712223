import { IntlFormatters } from 'react-intl';
import { Form } from 'antd';
import {
  IdAndNameType,
  IdAndNameTypeIn,
  Parameter,
  ParameterGroup,
  ParameterGroupIn,
  ParameterIn,
} from '../api/types';

const czNumberFormatter = new Intl.NumberFormat('cs-CZ', {});

export function addFieldExternalIdToParameters(
  parameters: Parameter[],
): ParameterIn[] {
  if (!parameters) {
    return [] as ParameterIn[];
  }

  const result: ParameterIn[] = [];
  for (let i = 0; i < parameters.length; i++) {
    result.push({ ...parameters[i], externalId: parameters[i].id });
  }

  return result;
}

export function addFieldExternalId(values: IdAndNameType[]): IdAndNameTypeIn[] {
  if (!values) {
    return [] as IdAndNameTypeIn[];
  }

  const result: IdAndNameTypeIn[] = [];
  for (let i = 0; i < values.length; i++) {
    result.push({ ...values[i], externalId: values[i].id });
  }

  return result;
}

export function addFieldExternalIdToParamGroup(
  values: ParameterGroup[],
): ParameterGroupIn[] {
  if (!values) {
    return [] as ParameterGroupIn[];
  }

  const result: ParameterGroupIn[] = [];
  for (let i = 0; i < values.length; i++) {
    const newValue = values[i] as ParameterGroupIn;
    newValue.parameters = addFieldExternalIdToParameters(newValue.parameters);
    result.push(newValue);
  }

  return result;
}

export function removeFieldExternalIdToParameters(
  parameters: ParameterIn[],
  removeEmpty: boolean = true,
): Parameter[] {
  if (!parameters) {
    return [];
  }

  const result: Parameter[] = [];
  for (let i = 0; i < parameters.length; i++) {
    if (parameters[i].externalId || !removeEmpty) {
      result.push({ id: parameters[i].externalId, name: parameters[i].name });
    }
  }

  return result;
}

export function removeFieldExternalId(
  values: IdAndNameTypeIn[],
  removeEmpty: boolean = true,
): IdAndNameType[] {
  if (!values) {
    return [];
  }

  const result: IdAndNameType[] = [];
  for (let i = 0; i < values.length; i++) {
    if (values[i].externalId || !removeEmpty) {
      result.push({ id: values[i].externalId, name: values[i].name });
    }
  }

  return result;
}

export function removeFieldExternalIdFromGroups(
  values: ParameterGroupIn[],
): ParameterGroup[] {
  if (!values) {
    return [];
  }

  const result: ParameterGroup[] = [];
  for (let i = 0; i < values.length; i++) {
    const newValue = {
      name: values[i].name,
      intervals: values[i].intervals,
      id: values[i].id,
      priority: values[i].priority,
    } as ParameterGroup;
    newValue.parameters = removeFieldExternalIdToParameters(
      values[i].parameters,
      false,
    );
    result.push(newValue);
  }

  return result;
}

export function flatParameters(
  pars: Parameter[],
  formatMessage: IntlFormatters['formatMessage'],
  separator = ', ',
): string {
  let result = '';
  if (pars) {
    pars.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    for (let i = 0; i < pars.length; i++) {
      result += pars[i].name + (i === pars.length - 1 ? '' : separator);
    }
  }
  return result;
}

export function transformFormData(obj: any): object {
  if (!obj) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, cv) => {
    return {
      ...acc,
      [cv]:
        typeof obj[cv] === 'object' && !Array.isArray(obj[cv])
          ? transformFormData(obj[cv])
          : Form.createFormField({
              value: obj[cv],
            }),
    };
  }, {});
}

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number = 500,
) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export function flatIdAndName(
  pars: IdAndNameType[],
  formatMessage: IntlFormatters['formatMessage'],
  separator = ', ',
  field = 'name',
): string {
  let result = '';
  if (pars) {
    pars.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    for (let i = 0; i < pars.length; i++) {
      result += pars[i][field] + (i === pars.length - 1 ? '' : separator);
    }
  }
  return result;
}

export function parseExpressionValue(
  composeValue: string | undefined,
): { base: string; operator: string; numberValue?: number } {
  const parsed = {
    base: '',
    operator: '',
  } as { base: string; operator: string; numberValue?: number };
  if (!composeValue) {
    return parsed;
  }

  try {
    // only number
    if (/^\d+(\.\d{1,3})?$/i.test(composeValue)) {
      parsed.numberValue = parseFloat(composeValue);

      return parsed;
    }

    const {
      // @ts-ignore
      groups: { expressionBase, operator, numberValue },
    } = /^(?<expressionBase>[DPC | MOC | pPRICE | bPRICE | sPRICE]*)(?<operator>[+-/*]*)(?<numberValue>[0-9.]*)$/gi.exec(
      composeValue,
    );

    parsed.base = expressionBase;
    parsed.operator = operator;
    parsed.numberValue =
      numberValue && !Number.isNaN(numberValue)
        ? parseFloat(numberValue)
        : undefined;
  } catch (e) {
    console.log('parseExpressionValue error: ', e);
  }
  return parsed;
}

export function numberToText(number?: number): string {
  if (number === 0) {
    return '0';
  }
  if (!number) {
    return '';
  }
  return czNumberFormatter.format(number).replace(',', '.');
}
