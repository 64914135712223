import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Divider, Form, Icon, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType, ParameterIn } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { GENERAL_PAGE_SIZE } from '../store/general';
import { debounce } from '../utils/DataManagement';

export interface Props {
  value?: ParameterIn[];
  onChange?: Function;
  disabled?: boolean;
}

const ParameterInputs = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const { setSearchParameter, fetchParameters } = useStoreActions(actions => actions.general);
    const loadingParameters = useStoreState(state => state.general.loadingParameters);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>([]);
    const [total, setTotal] = useState(-1);
    const paramFormItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    const handleChange = (
      index: number,
      val: LabeledValue,
    ) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          if (val) {
            // @ts-ignore
            valueClone[index].externalId = val?.label?.props?.children[0].props.children;
            // @ts-ignore
            valueClone[index].name = val?.label?.props?.children[1].props.children;
          }
          onChange(valueClone);
        }
      }
    };

    const handleRemove = (obj: ParameterIn) => {
      if (onChange && value) {
        onChange(value.filter(v => v !== obj));
      }
    };

    const onSearch = (searchText: string) => {
      setSearchParameter(searchText);

      // if (!searchText || searchText.length < 3) {
      //   setDataSource([]);
      //   setTotal(-1);
      //   return;
      // }

      fetchParameters().then((data: any) => {
        if (data) {
          setDataSource(data.data);
          setTotal(data.total);
        } else {
          setDataSource([]);
          setTotal(-1);
        }
      });
    };

    const onSearchDebounce = debounce(onSearch, 700);

    const onFocus = () => {
      onSearchDebounce('');
    };

    const formatOptionText = (
      oneValue: IdAndNameType,
    ): any => {

      return (
        <>
          <span
            className='text-ellipsis'
            style={{ float: 'left', maxWidth: '130px', display: 'inline-block' }}
          >{oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{ float: 'right', width: '130px', maxWidth: '130px', textAlign: 'right' }}
          >{oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div><FormattedMessage id='product_exceptions.detail.params.param_id' />
          <span style={{ float: 'right' }}> <FormattedMessage id='product_exceptions.detail.params.param_name' /></span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPPARAMS' label={renderGroupTitle()}>
        {dataSource.map(data =>
          <Option
            key={`${data.id}`}
            value={`${data.id} - ${data.name}`}
          >{formatOptionText(data)}
          </Option>)}
      </OptGroup>,
    ];

    return (
      <div ref={ref}>
        {value
          ? value.map((parameter, index) => (
            <Row
              // eslint-disable-next-line react/no-array-index-key
              key={`${parameter.id}-${index}`}
              type='flex'
              align='middle'
              justify='space-around'
            >
              <Col span={23}>
                <Form.Item
                  label={
                    <FormattedMessage id='product_exceptions.detail.params.param' />
                  }
                  required
                  // help={<FormattedMessage id='common.combo_search_placeholder' />}
                  {...paramFormItemLayout}
                >
                  <Select
                    showSearch
                    disabled={disabled}
                    labelInValue
                    filterOption={false}
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    onChange={(val: LabeledValue) =>
                      handleChange(
                        index,
                        val,
                      )
                    }
                    dropdownStyle={{ width: 300 }}
                    onSearch={onSearchDebounce}
                    value={{
                      key: parameter.externalId,
                      label: parameter.externalId || parameter.name ? `${parameter.externalId} - ${parameter.name}` : undefined,
                    } as LabeledValue}
                    onFocus={() => {
                      onFocus();
                    }}
                    notFoundContent={null}
                    loading={loadingParameters}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        {total > GENERAL_PAGE_SIZE && (
                          <>
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ padding: '5px 10px' }} className='ant-form-explain'>
                              <FormattedMessage
                                id='common.combo_search_more_results'
                                values={{ total, limit: GENERAL_PAGE_SIZE }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Col>
              {!disabled && (
                <Col span={1}>
                  <Icon
                    className='delete-icon'
                    onClick={e => {
                      e.preventDefault();
                      handleRemove(parameter);
                    }}
                    type='delete'
                  />
                </Col>
              )}
            </Row>
          ))
          : null}
      </div>
    );
  },
);

export default ParameterInputs;
