import { createStore } from 'easy-peasy';
import user from './user';
import general from './general';
import productExceptions from './productExceptions';
import { StoreModel } from './types';
import maxDiscounts from './maxDiscounts';
import minMargins from './minMargins';
import pricingRules from './pricingRules';
import topNCategories from './topNCategories';
import dashboardProducts from './dashboardProducts';
import dashboardReports from './dashboardReports';

export const store: StoreModel = {
  user,
  general,
  productExceptions,
  maxDiscounts,
  minMargins,
  pricingRules,
  topNCategories,
  dashboardReports,
  dashboardProducts,
};

export default createStore(store);
