import React from 'react';
import { Button, Card, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import Title from 'antd/lib/typography/Title';
import { Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosBasicCredentials } from 'axios';
import { useStoreActions, useStoreState } from '../store/hooks';
import { getHomeLink } from '../routes/Routes';

const Login: React.FC<FormComponentProps<AxiosBasicCredentials>> = ({
  form: { getFieldDecorator, validateFields },
}) => {
  const location = useLocation<{ from: string }>();
  const login = useStoreActions(actions => actions.user.login);
  const setLoading = useStoreActions(actions => actions.user.setLoading);
  const isLoading = useStoreState(state => state.user.isLoading);
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
  const { formatMessage } = useIntl();

  const { from } = location.state || {
    from: { pathname: getHomeLink(isLoggedIn) },
  };

  return isLoggedIn ? (
    <Redirect to={from} />
  ) : (
    <div className='login'>
      <Card className='form'>
        <Form
          onSubmit={async e => {
            setLoading(true);
            e.preventDefault();
            await new Promise<void>(resolve => {
              setTimeout(resolve, 1000);
            });
            validateFields((err, values) => {
              if (!err) {
                login(values);
              }
            });
            setLoading(false);
          }}
        >
          <Title level={3}>
            <FormattedMessage id='login.title' />
          </Title>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='login.validations.username' />,
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder={formatMessage({ id: 'login.username' })}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='login.validations.password' />,
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type='password'
                placeholder={formatMessage({ id: 'login.password' })}
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='submit'
              loading={isLoading}
            >
              <FormattedMessage id='login.button' />
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
export default Form.create()(Login);
