import { AxiosPromise } from 'axios';
import http from './httpBase';
import {
  ProductBatchStateUpdateBody,
  ProductException,
  ProductExceptionDeleteBody,
  ProductExceptionsListItem,
} from './types';
import { productExceptionsData, productExceptionsListData } from './mockData';

const { get, post, mock } = http;
export const PRODUCT_EXCEPTIONS_URL = '/product-exceptions';

const getProductExceptionsList = (
  batchNumber?: number,
): AxiosPromise<ProductExceptionsListItem[]> =>
  get(`${PRODUCT_EXCEPTIONS_URL}`, {
    params: { batchNumber },
  });

mock.onGet(PRODUCT_EXCEPTIONS_URL).reply(200, productExceptionsListData);

const createProductException = (
  body: ProductException,
): AxiosPromise<ProductException> => post(`${PRODUCT_EXCEPTIONS_URL}`, body);

mock.onPost(PRODUCT_EXCEPTIONS_URL).reply(200, productExceptionsData);

const getProductExceptionDetail = (
  id: number,
): AxiosPromise<ProductException> => get(`${PRODUCT_EXCEPTIONS_URL}/${id}`);

mock.onGet(/\/product-exceptions\/\d+/).reply(200, productExceptionsData);

const updateProductException = (
  body: ProductException,
): AxiosPromise<ProductException> =>
  post(`${PRODUCT_EXCEPTIONS_URL}/${body.id}`, body);

mock.onPost(/\/product-exceptions\/\d+/).reply(200, productExceptionsData);

const deleteProductExceptions = (
  body: ProductExceptionDeleteBody,
): AxiosPromise => post(`${PRODUCT_EXCEPTIONS_URL}/delete`, body);

mock.onPost(/\/product-exceptions\/delete+/).reply(200);

const updateProductExceptions = (
  body: ProductBatchStateUpdateBody,
): AxiosPromise => post(`${PRODUCT_EXCEPTIONS_URL}/batch-update`, body);

mock.onPost(/\/product-exceptions\/batch-update+/).reply(200);

const importProductExceptions = (body: string): AxiosPromise =>
  post(`${PRODUCT_EXCEPTIONS_URL}/upload`, body, {
    headers: { 'Content-Type': 'text/plain' },
  });

mock.onPost(/\/product-exceptions\/upload+/).reply(200);

export default {
  getProductExceptionsList,
  createProductException,
  getProductExceptionDetail,
  updateProductException,
  deleteProductExceptions,
  updateProductExceptions,
  importProductExceptions,
};
