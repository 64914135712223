import React from 'react';
import './styles/styles.scss';

import { StoreProvider } from 'easy-peasy';
import { Layout } from 'antd';
import store from './store';
import Routes from './routes/Routes';
import LanguageProvider from './components/LanguageProvider';
import SideBar from './components/SideBar';
import HeaderBar from './components/HeaderBar';

const { Content } = Layout;

const App: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <LanguageProvider>
        <Layout className='app'>
          <SideBar />
          <Layout>
            <HeaderBar />
            <Content className='content'>
              <Routes />
            </Content>
          </Layout>
        </Layout>
      </LanguageProvider>
    </StoreProvider>
  );
};

export default App;
