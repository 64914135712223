import { Action, action, Thunk, thunk } from 'easy-peasy';
import { AxiosBasicCredentials } from 'axios';
import { locale } from '../translations';
import { StoreModel } from './types';
import { userApi } from '../api';
import http from '../api/httpBase';

const { setAuth } = http;

export interface UserStore {
  locale: locale;
  isLoggedIn: boolean;
  isLoading: boolean;
  name: string;
  username: string;
  setLoggedIn: Action<UserStore, boolean>;
  setLoading: Action<UserStore, boolean>;
  setUser: Action<UserStore, Pick<UserStore, 'name' | 'username'>>;
  login: Thunk<
    UserStore,
    AxiosBasicCredentials,
    any,
    StoreModel,
    Promise<boolean>
  >;
  logout: Action<UserStore>;
}

const user: UserStore = {
  locale: locale.CZECH,
  isLoggedIn: false,
  isLoading: false,
  name: '',
  username: '',
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setUser: action((state, payload) => {
    state.name = payload.name;
    state.username = payload.username;
  }),
  login: thunk(async ({ setLoggedIn, setUser, setLoading }, value) => {
    setLoading(true);
    try {
      const {
        data: { name, login },
      } = await userApi.getLoggedUser(value);
      setUser({ username: login, name });
      setAuth(value);
      setLoggedIn(true);
    } catch (error) {
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  }),
  logout: action(state => {
    setAuth({ password: '', username: '' });
    state.isLoggedIn = false;
  }),
};

export default user;
