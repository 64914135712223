import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { IdAndNameTypeIn, MaxDiscount } from '../api/types';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';
import CategoryInput from './CategoryInput';
import SupplierInput from './SupplierInput';
import ParameterInput from './ParameterInput';

interface Props extends FormComponentProps<MaxDiscount> {
  data: MaxDiscount;
  mode: Mode;
}

export const dateFormat = 'YYYY-MM-DD';

const MaxDiscountForm: React.FC<Props> = ({
                                            form: { getFieldDecorator },
                                            mode,
                                            data,
                                          }) => {
  const isView = mode === Mode.VIEW;
  const { formatMessage } = useIntl();
  const types = ['PARAMETER', 'PRODUCT'];
  const [subFormType, setSubFormType] = useState(data.type);

  getFieldDecorator('id');
  getFieldDecorator('originalType');

  useEffect(() => {
    setSubFormType(data.type);
  }, [data, setSubFormType]);

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      className='pup-form'
    >
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item
            label={<FormattedMessage id='max_discounts.table.titles.type' />}
          >
            {getFieldDecorator(
              'type',
              {},
            )(
              <Select
                disabled={isView}
                onChange={val => setSubFormType(val as 'PARAMETER' | 'PRODUCT')}
              >
                {types.map(value => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='max_discounts.table.titles.valid_from' />
            }
          >
            {getFieldDecorator('validFromM', {
              initialValue:
                data.validFrom != null &&
                data.validFrom &&
                data.validFrom !== ''
                  ? moment(data.validFrom, dateFormat)
                  : null,
            })(
              <DatePicker
                format={dateFormat}
                disabled={isView}
                placeholder={formatMessage({ id: 'common.date_mask' })}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='max_discounts.table.titles.max_discount' />
            }
          >
            {getFieldDecorator('maxDiscount', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <InputNumber
                disabled={isView}
                formatter={value => (value ? `${value}%` : '')}
                min={0}
                max={100}
                step={1}
                parser={value => (value ? value.replace('%', '') : '')}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='max_discounts.table.titles.valid_to' />
            }
          >
            {getFieldDecorator('validToM', {
              initialValue:
                data.validTo != null && data.validTo && data.validTo !== ''
                  ? moment(data.validTo, dateFormat)
                  : null,
            })(
              <DatePicker
                format={dateFormat}
                disabled={isView}
                placeholder={formatMessage({ id: 'common.date_mask' })}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={32} className='params-title' style={{ height: '10px' }}>
          <Form.Item>
            <div className='ant-form-text ant-modal-title'>&nbsp;</div>
          </Form.Item>
        </Col>
      </Row>

      {subFormType === 'PRODUCT' && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={<FormattedMessage id='max_discounts.table.titles.ean' />}
            >
              {getFieldDecorator('ean', { preserve: true })(
                <Input disabled={isView} />,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <FormattedMessage id='max_discounts.table.titles.productId' />
              }
            >
              {getFieldDecorator('productId', { preserve: true })(
                <Input disabled={isView} />,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}

      {subFormType === 'PARAMETER' && (
        <>
          <Row
            gutter={16}
            justify='space-around'
            align='middle'
            style={{ marginRight: 0 }}
          >
            <Col span={24}>
              {getFieldDecorator('category.id', { preserve: true })(
                <CategoryInput disabled={isView} />,
              )}
            </Col>
          </Row>
          <Row
            gutter={16}
            justify='space-around'
            align='middle'
            style={{ marginRight: 0 }}
          >
            <Col span={24}>
              {getFieldDecorator('supplier.id', { preserve: true })(
                <SupplierInput disabled={isView} />,
              )}
            </Col>
          </Row>
          <Row
            gutter={16}
            justify='space-around'
            align='middle'
            style={{ marginRight: 0 }}
          >
            <Col span={24}>
              {getFieldDecorator('parameter.id', { preserve: true })(
                <ParameterInput disabled={isView} />,
              )}
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({
                       data: { validFrom, validTo, category, supplier, parameter, ...rest },
                     }) =>
    transformFormData({
      validFromM:
        validFrom != null && validFrom && validFrom !== ''
          ? moment(validFrom, dateFormat)
          : null,
      validToM:
        validTo != null && validTo && validTo !== ''
          ? moment(validTo, dateFormat)
          : null,
      category: category ? { ...category, externalId: category.id } as IdAndNameTypeIn : {} as IdAndNameTypeIn,
      supplier: supplier ? { ...supplier, externalId: supplier.id } as IdAndNameTypeIn : {} as IdAndNameTypeIn,
      parameter: parameter ? { ...parameter, externalId: parameter.id } as IdAndNameTypeIn : {} as IdAndNameTypeIn,
      originalType: rest.type,
      ...rest,
    }),
})(MaxDiscountForm);
