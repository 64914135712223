import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal } from 'antd';
import NewButton from '../components/NewButton';
import useFormRef from '../hooks/useFormRef';
import { Mode } from '../store/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { IdAndNameType, RuleStates, TopNCategory } from '../api/types';
import TopNCategoriesTable from '../components/TopNCategoriesTable';
import { defaultTopNCategory } from '../store/topNCategories';
import TopNCategoryForm from '../components/TopNCategoryForm';
import { removeFieldExternalId } from '../utils/DataManagement';
import RuleDetailFormButtons from '../components/RuleDetailFormButtons';
import RuleFormTitle from '../components/RuleFromTitle';

const TopNCategoriesPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [isVisibleDC, setVisibleDC] = useState(false);

  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<TopNCategory>();
  const {
    setDetail,
    fetchTopNCategory,
    createTopNCategory,
    updateTopNCategory,
    deleteTopNCategory,
    fetchList,
    setSelectedDetailId,
  } = useStoreActions(actions => actions.topNCategories);
  const { detail, selectedDetailId } = useStoreState(
    state => state.topNCategories,
  );
  const { fetchCategories, fetchHeurekaShop } = useStoreActions(
    actions => actions.general,
  );
  const categoriesList = useStoreState(state => state.general.categoriesList);
  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchList();
    fetchCategories();
  }, [fetchList, fetchCategories]);

  const handleEdit = async (id: number) => {
    await fetchHeurekaShop('');
    await fetchTopNCategory(id);
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleRemove = async (id: number) => {
    setSelectedDetailId(id);
    setVisibleDC(true);
  };

  const handleView = async (id: number) => {
    await fetchTopNCategory(id);
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const getDefaultValueCategory = (): IdAndNameType => {
    if (!categoriesList || categoriesList.count() === 0) {
      return { id: '', name: '' };
    }

    const val = categoriesList.find(cL => cL.id === 'DEFAULT');
    return val ? { id: val.id, name: val.name } : { id: '', name: '' };
  };

  const handleNew = () => {
    fetchHeurekaShop('');
    if (categoriesList && categoriesList.count() > 0) {
      defaultTopNCategory.category = getDefaultValueCategory();
    }

    setDetail(defaultTopNCategory);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const getCategoryValue = (category: IdAndNameType): IdAndNameType => {
    if (
      !category ||
      !category.id ||
      !categoriesList ||
      categoriesList.count() === 0
    ) {
      return { id: '', name: '' };
    }

    const val = categoriesList.find(cL => cL.id === category.id);
    return val ? { id: val.id, name: val.name } : { id: '', name: '' };
  };

  const handleSave = (newState: RuleStates) => {
    formRef!.validateFields(
      // @ts-ignore
      (err, { category, comparedShops, ...fieldsValue }) => {
        // @ts-ignore
        const inValues = {
          ...fieldsValue,
          // @ts-ignore
          category: getCategoryValue(category),
          // @ts-ignore
          comparedShops: removeFieldExternalId(comparedShops),
        } as TopNCategory;

        if (!err) {
          switch (mode) {
            case Mode.CREATE:
              inValues.state = RuleStates.DRAFT;
              createTopNCategory(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_new' }),
                  );
                  setVisible(false);
                }
              });

              break;
            case Mode.EDIT:
              inValues.state = newState;
              updateTopNCategory(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_edit' }),
                  );
                  setVisible(false);
                }
              });

              break;
            default:
              break;
          }
        }
      },
    );
  };

  const handleDeleteSelected = () => {
    deleteTopNCategory(selectedDetailId).then(res => {
      setVisibleDC(false);
      if (res) {
        message.success(formatMessage({ id: 'common.message.success_delete' }));
        setVisible(false);
      }
    });
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisible(false);
    formRef!.resetFields();
  };

  return (
    <div className='max-discounts'>
      <NewButton onClick={handleNew} />
      <Modal
        title={<FormattedMessage id='common.confirmation' />}
        visible={isVisibleDC}
        width={700}
        onCancel={e => {
          e.preventDefault();
          setVisibleDC(false);
        }}
        okText={<FormattedMessage id='common.delete' />}
        onOk={handleDeleteSelected}
      >
        <FormattedMessage id='common.confirm_delete' />
      </Modal>
      <TopNCategoriesTable
        onEdit={handleEdit}
        onView={handleView}
        onRemove={handleRemove}
      />
      <Modal
        title={
          <RuleFormTitle
            mode={mode}
            ruleState={detail.state}
            messageId='top_n_categories.detail.title'
          />
        }
        visible={isVisible}
        width={800}
        footer={
          mode === Mode.VIEW ? null : (
            <RuleDetailFormButtons
              ruleState={detail.state}
              mode={mode}
              onCancel={onCancel}
              onSave={newState => handleSave(newState)}
              onDelete={() => handleRemove(detail.id)}
            />
          )
        }
        onCancel={onCancel}
      >
        <TopNCategoryForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};
export default TopNCategoriesPage;
