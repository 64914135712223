import React from 'react';
import { Icon, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import { MaxDiscountListItem, RuleStates } from '../api/types';
import UpdateButton from './UpdateButton';
import RuleStateCell from './RuleStateCell';

export interface Props {
  onView: (id: number, type: 'PRODUCT' | 'PARAMETER') => void;
  onEdit: (id: number, type: 'PRODUCT' | 'PARAMETER') => void;
  onRemove: (id: number, type: 'PRODUCT' | 'PARAMETER') => void;
}

const MaxDiscountsTable: React.FC<Props> = ({ onEdit, onView, onRemove }) => {
  const { list: data, isLoading } = useStoreState(
    state => state.maxDiscounts,
  );
  const { formatMessage } = useIntl();

  return (
    <Table<MaxDiscountListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      childrenColumnName='sourceMaxDiscounts'
      rowKey={rec =>
        `${rec.id}_${rec.type}`
      }
      rowClassName={() =>
        `c-pointer`
      }
      onRow={({ id, type }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id, type);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'max_discounts.table.titles.id' }),
          fixed: 'left',
          width: 70,
          align: 'center',
        },
        {
          dataIndex: 'type',
          title: formatMessage({
            id: 'max_discounts.table.titles.type',
          }),
          width: 120,
        },
        {
          dataIndex: 'category',
          title: formatMessage({
            id: 'max_discounts.table.titles.category',
          }),
          render: (text, { category }) => <>{category ? category.name : ''}</>,
        },
        {
          dataIndex: 'supplier',
          title: formatMessage({
            id: 'max_discounts.table.titles.supplier',
          }),
          render: (text, { supplier }) => <>{supplier ? supplier.name : ''}</>,
        },
        {
          dataIndex: 'parameter',
          title: formatMessage({
            id: 'max_discounts.table.titles.parameter',
          }),
          render: (text, { parameter }) => <>{parameter ? parameter.name : ''}</>,
        },
        {
          dataIndex: 'productId',
          title: formatMessage({
            id: 'max_discounts.table.titles.productId',
          }),
          width: 120,
        },
        {
          dataIndex: 'ean',
          title: formatMessage({
            id: 'max_discounts.table.titles.ean',
          }),
          width: 150,
        },
        {
          dataIndex: 'maxDiscount',
          title: formatMessage({
            id: 'max_discounts.table.titles.max_discount',
          }),
          width: 100,
          render: (text, { maxDiscount }) => (
            <>{maxDiscount || 0}%</>
          ),
        },
        {
          dataIndex: 'validFrom',
          title: formatMessage({
            id: 'max_discounts.table.titles.valid_from',
          }),
          width: 100,
        },
        {
          dataIndex: 'validTo',
          title: formatMessage({
            id: 'max_discounts.table.titles.valid_to',
          }),
          width: 100,
        },
        {
          dataIndex: 'state',
          title: formatMessage({
            id: 'max_discounts.table.titles.state',
          }),
          render: (text, { state }) => <RuleStateCell ruleState={state} />,
          width: 90,
        },
        {
          key: 'update-button',
          align: 'left',
          width: 125,
          className: 'action-column',
          render: (text, { id, type, state }) => (state !== RuleStates.LOCKED) ? (
            <>
              <UpdateButton
                onClick={e => {
                  e.preventDefault();
                  onEdit(id, type);
                }}
              />
              {(state === RuleStates.DRAFT || state === RuleStates.TO_DELETE) && <Icon
                className='delete-icon'
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove(id, type);
                }}
                type='delete'
              />}
            </>
          ) : <></>,
        },
      ]}
    />
  );
};

export default MaxDiscountsTable;
