import { InputNumberProps } from 'antd/lib/input-number';

export enum RuleStates {
  FINAL = 'FINAL',
  DRAFT = 'DRAFT',
  TO_DELETE = 'TO_DELETE',
  LOCKED = 'LOCKED',
}

export type ProductException = {
  id: number;
  productId: string;
  ean: string;
  evaluationExpression: string;
  parameters: Parameter[];
  validFrom: string;
  validTo: string;
  batchNumber?: number;
  active: boolean;
  state: RuleStates;
  sourceProductExceptions?: ProductException[];
};

export type ProductExceptionDeleteBody = {
  ids: number[];
};

export type ProductBatchStateUpdateBody = {
  ids: number[];
  state: RuleStates;
};

export type Parameter = {
  id: string;
  name: string;
};

export type ParameterIn = {
  id: string;
  name: string;
  externalId: string;
};

export type ProductExceptionIn = Omit<ProductException, 'batchNumber'>;

export type ProductExceptionsListItem = ProductException;

export type UserResponse = {
  id: number;
  login: string;
  name: string;
};

export interface MaxDiscount extends MaxDiscountListItem {}

export interface MaxDiscountListItem {
  id: number;
  type: 'PARAMETER' | 'PRODUCT';
  category: IdAndNameType;
  supplier: IdAndNameType;
  productId: string;
  ean: string;
  maxDiscount: number;
  parameter: IdAndNameType;
  validFrom: string;
  validTo: string;
  state: RuleStates;
  sourceMaxDiscounts?: MaxDiscountListItem[];
}

export interface MinMargin extends MinMarginListItem {}

export interface MinMarginListItem {
  id: number;
  category: IdAndNameType;
  minMargin: number;
  purchasePriceFrom: number;
  purchasePriceTo: number;
  state: RuleStates;
  sourceMinMargins?: MinMarginListItem[];
}

export interface PricingRule {
  id: number;
  name: string;
  suppliers: IdAndNameType[];
  categories: IdAndNameType[];
  parameterGroups: ParameterGroup[];
  validFrom: string;
  validTo: string;
  active: boolean;
  state: RuleStates;
  sourceRules?: PricingRule[];
}

export interface IdAndNameType {
  [key: string]: string | undefined;

  id: string;
  name: string;
}

export interface BusinessResultEntity {
  data: IdAndNameType[];
  total: number;
}

export interface IdAndNameTypeIn {
  id: string;
  name: string;
  externalId: string;
}

export type Interval = {
  id: number;
  priceFrom?: number;
  priceTo?: number;
  evaluationExpression?: string;
};

export type ParameterGroup = {
  id: number;
  name: string;
  priority?: number;
  parameters: Parameter[];
  intervals: Interval[];
};

export type ParameterGroupIn = {
  id: number;
  name: string;
  priority?: number;
  parameters: ParameterIn[];
  intervals: Interval[];
};

export interface TopNCategory extends TopNCategoryListItem {}

export interface TopNCategoryListItem {
  id: number;
  category: IdAndNameType;
  priceFrom?: number;
  priceTo?: number;
  topN: number;
  topNExpression: string;
  roudingType: 'NORMAL' | 'UP' | 'DOWN' | 'DOWN9';
  comparedShops: IdAndNameType[];
  state: RuleStates;
  priority: number;
  sourceTopNCategories?: TopNCategoryListItem[];
}

export interface Product {
  id: number;
  productId: string;
  ean: string;
  productDescription: string;
  supplier: IdAndNameType;
  category: IdAndNameType;
  parameters: IdAndNameType[];
  basePrice: number;
  actualPrice: number;
  actualPriceDiscount: number;
  newPrice: number;
  newPriceDiscount: number;
  change: number;
  pricingError: boolean;
}

export interface DashboardProductRequest {
  pageSize: number;
  page: number;
  sortField: string;
  sortDirection: 'ASC' | 'DESC';
  productId?: string;
  ean?: string;
  title?: string;
  supplierId?: string;
  categoryId?: string;
  parameterId?: string;
}

export interface DashboardProductResponse {
  data: Product[];
  page: {
    total: number;
    page: number;
    pageSize: number;
    sortField: string;
    sortDirection: 'ASC' | 'DESC';
  };
}

export interface DashboardProductRule {
  step: string;
  actualRule?: string;
  actualResult?: number;
  newRule?: string;
  newResult?: number;
}

export interface ProductDetail extends Product {
  skipTopN: boolean;
  skipMinMargin: boolean;
  skipMaxDiscount: boolean;
  purchasePrice: number;
  purchasePriceCurrency: string;
  rrp: number;
  rsp: number;
  sellingPriceRate: number;
  sellingPriceCurrency: string;
  rounding: number;
  finalPricingError?: string;
  draftPricingError?: string;
  finalPricingTime?: string;
  draftPricingTime?: string;
  rules: DashboardProductRule[];
}

export interface DashboardReportRow {
  id: string;
  name: string;
  noOfTitles: number;
  noOfTitlesPendingPrice: number;
  subRows?: DashboardReportRow[];
}

export interface PriceCalculationResponse {
  running: boolean;
  lastRunOutcome: 'OK' | 'ERROR' | 'PRODUCTS_ERROR';
}

export enum RecalculationStrategy {
  TESTING_RULE = 'TESTING_RULES',
  PRODUCT_SYNCHRONIZATION = 'PRODUCTS_SYNCHRONIZATION',
}

export interface ConfirmAllResponse extends PriceCalculationResponse {
  // calculationState: PriceCalculationResponse;
}

export interface InputNumberPropsExtended extends InputNumberProps {
  selectAll?: Function;
}
