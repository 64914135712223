import React from 'react';
import { Icon, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreState } from '../store/hooks';
import UpdateButton from './UpdateButton';
import { MinMarginListItem, RuleStates } from '../api/types';
import RuleStateCell from './RuleStateCell';
import { numberToText } from '../utils/DataManagement';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
}

const MinMarginsTable: React.FC<Props> = ({ onEdit, onView, onRemove }) => {
  const { list: data, isLoading } = useStoreState(state => state.minMargins);
  const { formatMessage } = useIntl();

  return (
    <Table<MinMarginListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      childrenColumnName='sourceMinMargins'
      rowClassName={() => `c-pointer`}
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'min_margins.table.titles.id' }),
          fixed: 'left',
          width: 70,
          align: 'center',
        },
        {
          dataIndex: 'category',
          title: formatMessage({
            id: 'min_margins.table.titles.category',
          }),
          render: (text, { category }) => <>{category ? category.name : ''}</>,
        },
        {
          dataIndex: 'purchasePriceFrom',
          title: formatMessage({
            id: 'min_margins.table.titles.purchase_price_from',
          }),
          render: (text, { purchasePriceFrom }) =>
            numberToText(purchasePriceFrom),
        },
        {
          dataIndex: 'purchasePriceTo',
          title: formatMessage({
            id: 'min_margins.table.titles.purchase_price_to',
          }),
          render: (text, { purchasePriceTo }) => numberToText(purchasePriceTo),
        },
        {
          dataIndex: 'minMargin',
          title: formatMessage({
            id: 'min_margins.table.titles.min_margin',
          }),
          width: 120,
          render: (text, { minMargin }) => <>{minMargin || 0}%</>,
        },
        {
          dataIndex: 'state',
          title: formatMessage({
            id: 'min_margins.table.titles.state',
          }),
          render: (text, { state }) => <RuleStateCell ruleState={state} />,
          width: 90,
        },
        {
          key: 'update-button',
          align: 'left',
          width: 125,
          className: 'action-column',
          render: (text, { id, state }) =>
            state !== RuleStates.LOCKED ? (
              <>
                <UpdateButton
                  onClick={e => {
                    e.preventDefault();
                    onEdit(id);
                  }}
                />
                {(state === RuleStates.DRAFT ||
                  state === RuleStates.TO_DELETE) && (
                  <Icon
                    className='delete-icon'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove(id);
                    }}
                    type='delete'
                  />
                )}
              </>
            ) : (
              <></>
            ),
        },
      ]}
    />
  );
};

export default MinMarginsTable;
