import { AxiosPromise } from 'axios';
import http from './httpBase';
import {
  ConfirmAllResponse,
  DashboardProductRequest,
  DashboardProductResponse, PriceCalculationResponse,
  ProductDetail, RecalculationStrategy,
} from './types';
import { dashboardProductResponse, productDetail } from './mockData';

const { get, mock, post, put } = http;
const BASE_URL = '/dashboard';

const getProducts = (
  request: DashboardProductRequest,
): AxiosPromise<DashboardProductResponse> =>
  post(`${BASE_URL}/products`, request);

mock.onPost(/\/dashboard\/products$/).reply(config => {
  const body = JSON.parse(config.data);
  const responseData = [...dashboardProductResponse.data];
  const template = responseData[0];
  const index = body.pageSize * body.page;
  const count = body.pageSize - responseData.length;
  for (let i = 0; i < count; i++) {
    responseData.push({
      ...template,
      id: index + i,
    });
  }

  return [200, { ...dashboardProductResponse, data: responseData }];
});

const getProductDetail = (id: number): AxiosPromise<ProductDetail> => {
  return get(`${BASE_URL}/products/${id}`, {});
};

mock.onGet(/\/dashboard\/products\/\d+/).reply(200, productDetail);

const recalculatePrices = (strategy: RecalculationStrategy): AxiosPromise<PriceCalculationResponse> => {
  return put(`${BASE_URL}/recalculation/?strategy=${strategy}`, {});
};

mock.onPut(/\/dashboard\/recalculation/).reply(200);

const getCalculationState = (): AxiosPromise<PriceCalculationResponse> => {
  return get(`${BASE_URL}/recalculation-state/`, {});
};

mock.onGet(/\/dashboard\/recalculation-state/).reply(200, { running: false, lastRunOutcome: 'OK' });

const confirmAllDraftRules = (): AxiosPromise<ConfirmAllResponse> => {
  return put(`${BASE_URL}/confirm-all`, {});
};

mock.onPut(/\/dashboard\/confirm-all/).reply(200);

export default {
  getProducts,
  getProductDetail,
  recalculatePrices,
  getCalculationState,
  confirmAllDraftRules,
};
