import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { DashboardProductRule } from '../api/types';

export interface Props {
  rules: DashboardProductRule[];
}

const ProductRulesTable: React.FC<Props> = ({ rules }) => {
  const { formatMessage } = useIntl();

  const dashOnEmptyRenderer = (value: any) => value ?? '-';

  return (
    <Table<DashboardProductRule>
      dataSource={rules}
      pagination={false}
      size='middle'
      rowKey={(rec, i) => i.toString()}
      columns={[
        {
          dataIndex: 'step',
          title: formatMessage({ id: 'dashboard_products.rulesDetail.step' }),
          width: 150,
          align: 'center',
          render: (text, { step }) => formatMessage({ id: `dashboard_products.rulesDetail.algorithmSteps.${step}` }),
        },
        {
          title: formatMessage({
            id: 'dashboard_products.rulesDetail.actualLabel',
          }),
          children: [
            {
              dataIndex: 'actualRule',
              title: formatMessage({
                id: 'dashboard_products.rulesDetail.rule',
              }),
              width: 200,
              align: 'center',
              render: dashOnEmptyRenderer,
            },
            {
              dataIndex: 'actualResult',
              title: formatMessage({
                id: 'dashboard_products.rulesDetail.result',
              }),
              ellipsis: true,
              width: 70,
              align: 'center',
              render: dashOnEmptyRenderer,
            },
          ],
        },
        {
          title: formatMessage({
            id: 'dashboard_products.rulesDetail.newLabel',
          }),
          children: [
            {
              dataIndex: 'newRule',
              title: formatMessage({
                id: 'dashboard_products.rulesDetail.rule',
              }),
              width: 200,
              align: 'center',
              render: dashOnEmptyRenderer,
            },
            {
              dataIndex: 'newResult',
              title: formatMessage({
                id: 'dashboard_products.rulesDetail.result',
              }),
              ellipsis: true,
              width: 70,
              align: 'center',
              render: dashOnEmptyRenderer,
            },
          ],
        },
      ]}
    />
  );
};

export default ProductRulesTable;
