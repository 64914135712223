import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, Col, Modal, Row } from 'antd';

import { useStoreActions, useStoreState } from '../store/hooks';

import ProductListTable from '../components/ProductListTable';
import ProductDetailForm from '../components/ProductDetailForm';
import ProductRulesTable from '../components/ProductRulesTable';
import { RecalculationStrategy } from '../api/types';

const DashboardListPage: React.FC = () => {
  const [isVisibleDetail, setVisibleDetail] = useState(false);
  const [isVisibleRules, setVisibleRules] = useState(false);
  const [isVisibleConfirm, setVisibleConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState<'RECALCULATE' | 'CONFIRM'>(
    'CONFIRM',
  );

  const [strategy, setStrategy] = useState<RecalculationStrategy>(
    RecalculationStrategy.TESTING_RULE,
  );
  const {
    fetchListByLastRequest,
    fetchProductDetail,
    fetchCalculationState,
    recalculatePrices,
    confirmAllDraftRules,
  } = useStoreActions(actions => actions.dashboardProducts);

  const { detail } = useStoreState(state => state.dashboardProducts);

  const { fetchCategories } = useStoreActions(actions => actions.general);

  useEffect(() => {
    fetchCategories();
    fetchCalculationState();

    const interval = setInterval(() => {
      fetchCalculationState();
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchCategories, fetchCalculationState]);

  const handleView = async (id: number) => {
    await fetchProductDetail({ id });
    setVisibleDetail(true);
  };

  const handleConfirmation = () => {
    if (confirmType === 'RECALCULATE') {
      recalculatePrices(strategy);
      setVisibleConfirm(false);
    } else {
      confirmAllDraftRules();
      setVisibleConfirm(false);
    }
  };

  return (
    <div className='dashboard-products'>
      <Row gutter={[16, 40]}>
        <Col>
          <Button
            shape='circle-outline'
            icon='sync'
            type='primary'
            onClick={e => fetchListByLastRequest()}
          />

          <Buttons
            onClick={(type, s) => {
              setVisibleConfirm(true);
              setConfirmType(type);
              if (type === 'RECALCULATE') {
                setStrategy(s!!);
              }
            }}
          />
        </Col>
      </Row>

      <ProductListTable onView={handleView} />

      <Modal
        title={<FormattedMessage id='common.confirmation' />}
        visible={isVisibleConfirm}
        width={700}
        onCancel={e => {
          e.preventDefault();
          setVisibleConfirm(false);
        }}
        onOk={handleConfirmation}
      >
        {confirmType === 'RECALCULATE' ? (
          <FormattedMessage id='dashboard_products.confirm_recalculate' />
        ) : (
          <FormattedMessage id='dashboard_products.confirm_changes' />
        )}
      </Modal>
      <Modal
        title={<FormattedMessage id='dashboard_products.detail.formTitle' />}
        visible={isVisibleDetail}
        width={900}
        onCancel={e => {
          e.preventDefault();
          setVisibleDetail(false);
        }}
        cancelText={<FormattedMessage id='common.close' />}
        okText={
          <FormattedMessage id='dashboard_products.detail.buttonShowRule' />
        }
        onOk={() => {
          setVisibleRules(true);
        }}
      >
        <ProductDetailForm product={detail} />
      </Modal>
      <Modal
        title={<FormattedMessage id='dashboard_products.rulesDetail.title' />}
        visible={isVisibleRules}
        centered
        className='product-rules-modal'
        footer={[
          <Button key='back' onClick={() => setVisibleRules(false)}>
            <FormattedMessage id='common.close' />
          </Button>,
        ]}
        width={850}
        onCancel={e => {
          e.preventDefault();
          setVisibleRules(false);
        }}
      >
        <ProductRulesTable rules={detail.rules} />
      </Modal>
    </div>
  );
};

interface Props {
  onClick: (
    type: 'CONFIRM' | 'RECALCULATE',
    strategy?: RecalculationStrategy,
  ) => void;
}

const Buttons: React.FC<Props> = ({ onClick }) => {
  const state = useStoreState(s => s.dashboardProducts.calculationState);

  return (
    <>
      <Button
        type='primary'
        className='recalculate-button'
        loading={state.running}
        onClick={e => {
          e.preventDefault();
          onClick('CONFIRM');
        }}
      >
        <FormattedMessage id='dashboard_products.button_confirm_all' />
      </Button>

      <Button
        type='primary'
        className='recalculate-button'
        loading={state.running}
        onClick={e => {
          e.preventDefault();
          onClick('RECALCULATE', RecalculationStrategy.PRODUCT_SYNCHRONIZATION);
        }}
      >
        <FormattedMessage id='dashboard_products.button_recalculate_actual' />
      </Button>

      <Button
        type='primary'
        className='recalculate-button'
        loading={state.running}
        onClick={e => {
          e.preventDefault();
          onClick('RECALCULATE', RecalculationStrategy.TESTING_RULE);
        }}
      >
        <FormattedMessage id='dashboard_products.button_recalculate' />
      </Button>

      {state.running && (
        <Alert
          style={{ display: 'inline-block' }}
          message={
            <FormattedMessage id='dashboard_products.message_calculating' />
          }
          type='info'
          showIcon
        />
      )}

      {!state.running && state.lastRunOutcome === 'ERROR' && (
        <Alert
          style={{ display: 'inline-block' }}
          message={
            <FormattedMessage id='dashboard_products.message_calculation_error' />
          }
          type='error'
          showIcon
        />
      )}
      {!state.running && state.lastRunOutcome === 'PRODUCTS_ERROR' && (
        <Alert
          style={{ display: 'inline-block' }}
          message={
            <FormattedMessage id='dashboard_products.message_products_errors' />
          }
          type='warning'
          showIcon
        />
      )}
    </>
  );
};

export default DashboardListPage;
