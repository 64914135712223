import { AxiosPromise } from 'axios';
import http from './httpBase';
import { DashboardReportRow } from './types';
import { reportByCategoryListData, reportBySupplierListData } from './mockData';

const { get, mock } = http;
export const DASHBOARD_REPORTS_URL = '/dashboard/reports';

const getReportByCategory = (
  reportType: 'byCategory' | 'bySupplier',
): AxiosPromise<DashboardReportRow[]> =>
  get(`${DASHBOARD_REPORTS_URL}`, {
    params: { reportType },
  });

mock.onGet(/\/dashboard\/reports\?*/g).reply(config => {
  return config.params.reportType === 'byCategory'
    ? [200, reportByCategoryListData]
    : [200, reportBySupplierListData];
});

export default {
  getReportByCategory,
};
