import React from 'react';
import { Alert, Col, Form, Row } from 'antd';

import { FormattedMessage } from 'react-intl';
import { ProductDetail, IdAndNameType } from '../api/types';
import { numberToText } from '../utils/DataManagement';

interface Props {
  product: ProductDetail;
}

const ProductDetailForm: React.FC<Props> = ({ product }) => {
  const singleItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const getName = (type: IdAndNameType): string => `${type.name} (${type.id})`;

  return (
    <Form
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
      className='pup-form dashboard-product-form'
    >
      <Form.Item
        {...singleItemLayout}
        label={<FormattedMessage id='dashboard_products.detail.id' />}
      >
        {product.productId}
      </Form.Item>
      <Form.Item
        {...singleItemLayout}
        label={<FormattedMessage id='dashboard_products.detail.ean' />}
      >
        {product.ean}
      </Form.Item>
      <Form.Item
        {...singleItemLayout}
        label={<FormattedMessage id='dashboard_products.detail.title' />}
      >
        {product.productDescription}
      </Form.Item>
      <Form.Item
        className='separator-row'
        label={<FormattedMessage id='dashboard_products.detail.supplier' />}
        {...singleItemLayout}
      >
        {getName(product.supplier)}
      </Form.Item>
      <Form.Item
        {...singleItemLayout}
        label={<FormattedMessage id='dashboard_products.detail.category' />}
      >
        {getName(product.category)}
      </Form.Item>
      <Form.Item
        {...singleItemLayout}
        label={<FormattedMessage id='dashboard_products.detail.parameters' />}
      >
        {product.parameters.map(p => getName(p)).join(', ')}
      </Form.Item>

      <Row
        className='separator-row'
        gutter={16}
        type='flex'
        justify='start'
        align='middle'
      >
        <Col span={6}>
          <Form.Item
            label={<FormattedMessage id='dashboard_products.detail.skipTopN' />}
          >
            <FormattedMessage id={`common.${product.skipTopN}`} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<FormattedMessage id='dashboard_products.detail.rrp' />}
          >
            {numberToText(product.rrp)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={<FormattedMessage id='dashboard_products.detail.rounding' />}
          >
            {product.rounding}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type='flex' justify='start' align='middle'>
        <Col span={6}>
          <Form.Item
            label={
              <FormattedMessage id='dashboard_products.detail.skipMinMargin' />
            }
          >
            <FormattedMessage id={`common.${product.skipMinMargin}`} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<FormattedMessage id='dashboard_products.detail.rsp' />}
          >
            {numberToText(product.rsp)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type='flex' justify='start' align='middle'>
        <Col span={6}>
          <Form.Item
            label={
              <FormattedMessage id='dashboard_products.detail.skipMaxDiscount' />
            }
          >
            <FormattedMessage id={`common.${product.skipMaxDiscount}`} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <FormattedMessage id='dashboard_products.detail.sellingPriceRate' />
            }
          >
            {product.sellingPriceRate}
          </Form.Item>
        </Col>
      </Row>

      <Row
        className='separator-row'
        gutter={16}
        type='flex'
        justify='start'
        align='middle'
      >
        <Col span={8}>
          <Form.Item
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            label={
              <FormattedMessage id='dashboard_products.detail.purchasePrice' />
            }
          >
            {product.purchasePrice} {product.purchasePriceCurrency}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <FormattedMessage id='dashboard_products.detail.basePrice' />
            }
          >
            {numberToText(product.basePrice)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type='flex' justify='start' align='middle'>
        {!product.finalPricingError && (
          <>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                label={
                  <FormattedMessage id='dashboard_products.detail.actualPrice' />
                }
              >
                {numberToText(product.actualPrice)}{' '}
                {product.sellingPriceCurrency}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <FormattedMessage id='dashboard_products.detail.actualPriceDiscount' />
                }
              >
                {numberToText(product.actualPriceDiscount)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                label={
                  <FormattedMessage id='dashboard_products.detail.priced' />
                }
              >
                {product.finalPricingTime}
              </Form.Item>
            </Col>
          </>
        )}
        {product.finalPricingError && (
          <>
            <Col span={24}>
              <Alert
                message={
                  <FormattedMessage id='dashboard_products.detail.actualError' />
                }
                description={product.finalPricingError}
                type='error'
                showIcon
              />
            </Col>
          </>
        )}
      </Row>
      <Row gutter={16} type='flex' justify='start' align='middle'>
        {!product.draftPricingError && (
          <>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                label={
                  <FormattedMessage id='dashboard_products.detail.newPrice' />
                }
              >
                {numberToText(product.newPrice)}{' '}
                {product.newPrice && product.sellingPriceCurrency}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <FormattedMessage id='dashboard_products.detail.newPriceDiscount' />
                }
              >
                {numberToText(product.newPriceDiscount)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                label={
                  <FormattedMessage id='dashboard_products.detail.priced' />
                }
              >
                {product.draftPricingTime}
              </Form.Item>
            </Col>
          </>
        )}
        {product.draftPricingError && (
          <>
            <Col span={24}>
              <Alert
                message={
                  <FormattedMessage id='dashboard_products.detail.newError' />
                }
                description={product.draftPricingError}
                type='error'
                showIcon
              />
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ProductDetailForm;
