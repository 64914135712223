import en from './en';
import cs from './cs';

export enum locale {
  ENGLISH = 'en',
  CZECH = 'cs',
}

export default {
  [locale.ENGLISH]: en,
  [locale.CZECH]: cs,
};
