import { AxiosPromise } from 'axios';
import http from './httpBase';
import { MaxDiscount, MaxDiscountListItem } from './types';
import { maxDiscountsData, maxDiscountsListData } from './mockData';

const { delete: deleteType, get, post, mock } = http;
export const MAX_DISCOUNTS_URL = '/max-discounts';

const getMaxDiscountsList = (): AxiosPromise<MaxDiscountListItem[]> =>
  get(`${MAX_DISCOUNTS_URL}`);

mock.onGet(MAX_DISCOUNTS_URL).reply(200, maxDiscountsListData);

const createMaxDiscount = (body: MaxDiscount): AxiosPromise<MaxDiscount> =>
  post(`${MAX_DISCOUNTS_URL}`, body);

mock.onPost(MAX_DISCOUNTS_URL).reply(200, maxDiscountsData);

const getMaxDiscountDetail = (
  id: number,
  type: 'PARAMETER' | 'PRODUCT',
): AxiosPromise<MaxDiscount> =>
  get(`${MAX_DISCOUNTS_URL}/${type.toLowerCase()}/${id}`);

mock.onGet(/\/max-discounts\/\w+\/\d+/).reply(200, maxDiscountsData);

const updateMaxDiscount = (
  type: 'PARAMETER' | 'PRODUCT',
  body: MaxDiscount,
): AxiosPromise<MaxDiscount> =>
  post(`${MAX_DISCOUNTS_URL}/${type.toLowerCase()}/${body.id}`, body);

mock.onPost(/\/max-discounts\/\w+\/\d+/).reply(200, maxDiscountsData);

const deleteMaxDiscount = (
  id: number,
  type: 'PARAMETER' | 'PRODUCT',
): AxiosPromise =>
  deleteType(`${MAX_DISCOUNTS_URL}/${type.toLowerCase()}/${id}`);

mock.onDelete(/\/max-discounts\/\w+\/\d+/).reply(200);

export default {
  getMaxDiscountsList,
  createMaxDiscount,
  getMaxDiscountDetail,
  updateMaxDiscount,
  deleteMaxDiscount,
};
