import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal } from 'antd';
import NewButton from '../components/NewButton';
import useFormRef from '../hooks/useFormRef';
import { Mode } from '../store/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { IdAndNameType, MaxDiscount, RuleStates } from '../api/types';
import { dateFormat } from '../components/PEForm';
import MaxDiscountsTable from '../components/MaxDiscountsTable';
import { defaultMaxDiscount } from '../store/maxDiscounts';
import MaxDiscountForm from '../components/MaxDiscountForm';
import RuleDetailFormButtons from '../components/RuleDetailFormButtons';
import RuleFormTitle from '../components/RuleFromTitle';

const MaxDiscountsPage: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const [isVisibleDC, setVisibleDC] = useState(false);

  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const { saveFormRef, formRef } = useFormRef<MaxDiscount>();
  const {
    setDetail,
    fetchMaxDiscount,
    createMaxDiscount,
    updateMaxDiscount,
    deleteMaxDiscount,
    fetchList,
    setSelectedDetail,
  } = useStoreActions(actions => actions.maxDiscounts);
  const { detail, selectedDetail } = useStoreState(state => state.maxDiscounts);
  const {
    fetchCategories,
    setSupplier,
    fetchSupplier,
    setParameter,
    fetchParameter,
  } = useStoreActions(actions => actions.general);
  const categoriesList = useStoreState(state => state.general.categoriesList);

  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchList();
    fetchCategories();
  }, [fetchList, fetchCategories]);

  const handleEdit = async (id: number, type: 'PRODUCT' | 'PARAMETER') => {
    await fetchMaxDiscount({ id, type });
    setMode(Mode.EDIT);
    setVisible(true);
  };

  const handleRemove = async (id: number, type: 'PRODUCT' | 'PARAMETER') => {
    setSelectedDetail({ id, type });
    setVisibleDC(true);
  };

  const handleView = async (id: number, type: 'PRODUCT' | 'PARAMETER') => {
    await fetchMaxDiscount({ id, type });
    setMode(Mode.VIEW);
    setVisible(true);
  };

  const getDefaultValueCategory = (): IdAndNameType => {
    if (!categoriesList || categoriesList.count() === 0) {
      return { id: '', name: '' };
    }

    const val = categoriesList.find(cL => cL.id === 'DEFAULT');
    return val ? { id: val.id, name: val.name } : { id: '', name: '' };
  };

  const getDefaultValueSupplier = (): IdAndNameType => {
    return { id: 'DEFAULT', name: 'Default supplier' };
  };

  const getDefaultValueParameter = (): IdAndNameType => {
    return { id: 'DEFAULT', name: 'Default parameter' };
  };

  const handleNew = () => {
    if (categoriesList && categoriesList.count() > 0) {
      defaultMaxDiscount.category = getDefaultValueCategory();
    }

    setSupplier(getDefaultValueSupplier());
    defaultMaxDiscount.supplier = getDefaultValueSupplier();

    setParameter(getDefaultValueParameter());
    defaultMaxDiscount.parameter = getDefaultValueParameter();

    setDetail(defaultMaxDiscount);
    setMode(Mode.CREATE);
    setVisible(true);
  };

  const getCategoryValue = (category: IdAndNameType): IdAndNameType => {
    if (
      !category ||
      !category.id ||
      !categoriesList ||
      categoriesList.count() === 0
    ) {
      return { id: '', name: '' };
    }

    const val = categoriesList.find(cL => cL.id === category.id);
    return val ? { id: val.id, name: val.name } : { id: '', name: '' };
  };

  const getSupplierValue = async (
    supplier: IdAndNameType,
  ): Promise<IdAndNameType> => {
    if (!supplier || !supplier.id) {
      return { id: '', name: '' };
    }

    const data = await fetchSupplier(supplier.id);
    return data ? (data as IdAndNameType) : { id: '', name: '' };
  };

  const getParameterValue = async (
    parameter: IdAndNameType,
  ): Promise<IdAndNameType> => {
    if (!parameter || !parameter.id) {
      return { id: '', name: '' };
    }

    const data = await fetchParameter(parameter.id);
    return data ? (data as IdAndNameType) : { id: '', name: '' };
  };

  const handleSave = (newState: RuleStates) => {
    formRef!.validateFields(
      async (
        err,
        {
          // @ts-ignore
          validFromM,
          // @ts-ignore
          validToM,
          category,
          supplier,
          parameter,
          // @ts-ignore
          originalType,
          ...fieldsValue
        },
      ) => {
        // @ts-ignore
        const inValues = {
          ...fieldsValue,
          // @ts-ignore
          validFrom: validFromM != null ? validFromM.format(dateFormat) : '',
          // @ts-ignore
          validTo: validToM != null ? validToM.format(dateFormat) : '',
          category: getCategoryValue(category),
          supplier: await getSupplierValue(supplier),
          parameter: await getParameterValue(parameter),
        } as MaxDiscount;

        if (!err) {
          switch (mode) {
            case Mode.CREATE:
              inValues.state = RuleStates.DRAFT;
              createMaxDiscount(inValues).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_new' }),
                  );
                  setVisible(false);
                }
              });

              break;
            case Mode.EDIT:
              inValues.state = newState;
              updateMaxDiscount({ originalType, data: inValues }).then(res => {
                if (res) {
                  message.success(
                    formatMessage({ id: 'common.message.success_edit' }),
                  );
                  setVisible(false);
                }
              });

              break;
            default:
              break;
          }
        }
      },
    );
  };

  const handleDeleteSelected = () => {
    if (selectedDetail) {
      deleteMaxDiscount(selectedDetail).then(res => {
        setVisibleDC(false);
        if (res) {
          message.success(
            formatMessage({ id: 'common.message.success_delete' }),
          );
          setVisible(false);
        }
      });
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setVisible(false);
    formRef!.resetFields();
  };

  return (
    <div className='max-discounts'>
      <NewButton onClick={handleNew} />
      <Modal
        title={<FormattedMessage id='common.confirmation' />}
        visible={isVisibleDC}
        width={700}
        onCancel={e => {
          e.preventDefault();
          setVisibleDC(false);
        }}
        okText={<FormattedMessage id='common.delete' />}
        onOk={handleDeleteSelected}
      >
        <FormattedMessage id='common.confirm_delete' />
      </Modal>
      <MaxDiscountsTable
        onEdit={handleEdit}
        onView={handleView}
        onRemove={handleRemove}
      />
      <Modal
        title={
          <RuleFormTitle
            mode={mode}
            ruleState={detail.state}
            messageId='max_discounts.detail.title'
          />
        }
        visible={isVisible}
        width={700}
        footer={
          mode === Mode.VIEW ? null : (
            <RuleDetailFormButtons
              ruleState={detail.state}
              mode={mode}
              onCancel={onCancel}
              onSave={newState => handleSave(newState)}
              onDelete={() => handleRemove(detail.id, detail.type)}
            />
          )
        }
        onCancel={onCancel}
      >
        <MaxDiscountForm ref={saveFormRef} data={detail} mode={mode} />
      </Modal>
    </div>
  );
};
export default MaxDiscountsPage;
