import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Form, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';

export interface Props {
  value?: string;
  onChange?: Function;
  disabled?: boolean;
  customLabelCol?: number;
  formItemCustomStyle?: any;
  isFilter?: boolean;
}

const CategoryInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      onChange,
      disabled,
      customLabelCol,
      formItemCustomStyle,
      isFilter = false,
    },
    ref,
  ) => {
    const { setSearchCategory } = useStoreActions(actions => actions.general);
    const categoriesList = useStoreState(state => state.general.categoriesList);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>(
      categoriesList.valueSeq().toArray(),
    );
    const categoryFormItemLayout = {
      labelCol: { span: customLabelCol || 6 },
      wrapperCol: { span: 18 },
    };

    const handleChange = (index: number, val: LabeledValue) => {
      if (onChange) {
        let valueClone;
        if (val) {
          // @ts-ignore
          valueClone = val?.label?.props?.children[0].props.children;
        }
        onChange(valueClone);
      }
    };

    const getCategoryInValue = (externalId?: string): IdAndNameType => {
      if (!externalId || !categoriesList || categoriesList.count() === 0) {
        return { id: '', name: '', externalId: '' };
      }

      const val = categoriesList.find(cL => cL.id === externalId);
      return val
        ? { id: val.id, name: val.name, externalId: val.id }
        : { id: '', name: '', externalId: '' };
    };

    const onSearch = (searchText: string, searchName?: string) => {
      setSearchCategory(searchText);

      if (!searchText) {
        setDataSource(categoriesList.valueSeq().toArray());
        return;
      }
      setDataSource(
        categoriesList
          .valueSeq()
          .toArray()
          .filter(
            pL =>
              pL.id.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
              pL.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
              (searchName
                ? pL.name.toUpperCase().indexOf(searchName.toUpperCase()) !== -1
                : false),
          ),
      );
    };

    const onFocus = () => {
      onSearch('');
    };

    const formatOptionText = (oneValue: IdAndNameType): any => {
      return (
        <>
          <span
            className='text-ellipsis'
            style={{
              float: 'left',
              maxWidth: '130px',
              display: 'inline-block',
            }}
          >
            {oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{
              float: 'right',
              width: '130px',
              maxWidth: '130px',
              textAlign: 'right',
            }}
          >
            {oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div>
          <FormattedMessage id='pricing_rules.detail.category_id' />
          <span style={{ float: 'right' }}>
            {' '}
            <FormattedMessage id='pricing_rules.detail.category_name' />
          </span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPCATEGORIES' label={renderGroupTitle()}>
        {dataSource.map(data => (
          <Option key={`${data.id}`} value={`${data.id} - ${data.name}`}>
            {formatOptionText(data)}
          </Option>
        ))}
      </OptGroup>,
    ];

    return (
      <div ref={ref}>
        <Row
          type='flex'
          align='middle'
          justify='space-around'
          className='minimal-size'
        >
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id='pricing_rules.detail.category' />}
              required={!isFilter}
              {...categoryFormItemLayout}
              style={formItemCustomStyle || {}}
            >
              <Select
                showSearch
                allowClear={isFilter && !!value}
                disabled={disabled}
                labelInValue
                filterOption={false}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                onChange={(val: LabeledValue) => handleChange(0, val)}
                dropdownStyle={{ width: 300 }}
                onSearch={onSearch}
                value={
                  {
                    key: value || '',
                    label:
                      getCategoryInValue(value) &&
                      (getCategoryInValue(value).externalId ||
                        getCategoryInValue(value).name)
                        ? `${getCategoryInValue(value).externalId} - ${
                            getCategoryInValue(value).name
                          }`
                        : undefined,
                  } as LabeledValue
                }
                onFocus={() => {
                  onFocus();
                }}
                notFoundContent={null}
              >
                {options}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  },
);

export default CategoryInput;
