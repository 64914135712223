import React from 'react';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { useStoreActions, useStoreState } from '../store/hooks';
import { ProductExceptionsListItem, RuleStates } from '../api/types';
import { flatParameters, parseExpressionValue } from '../utils/DataManagement';
import UpdateButton from './UpdateButton';
import RuleStateCell from './RuleStateCell';

export interface Props {
  onView: (id: number) => void;
  onEdit: (id: number) => void;
}

const ProductExceptionsTable: React.FC<Props> = ({ onEdit, onView }) => {
  const { list: data, isLoading, selectedRowKeys } = useStoreState(
    state => state.productExceptions,
  );
  const { setSelectedRowKeys } = useStoreActions(
    state => state.productExceptions,
  );
  const { formatMessage } = useIntl();

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeysParam: number[] | string[]) => {
      setSelectedRowKeys(selectedRowKeysParam);
    },
  };
  return (
    <Table<ProductExceptionsListItem>
      loading={isLoading}
      dataSource={data.valueSeq().toArray()}
      size='middle'
      rowKey='id'
      childrenColumnName='sourceProductExceptions'
      rowClassName={record =>
        `c-pointer${!record.active ? ' inactive-row' : ''}`
      }
      onRow={({ id }) => ({
        onClick: e => {
          e.preventDefault();
          onView(id);
        },
      })}
      rowSelection={rowSelection}
      columns={[
        {
          dataIndex: 'id',
          title: formatMessage({ id: 'product_exceptions.table.titles.id' }),
          width: 70,
        },
        {
          dataIndex: 'productId',
          title: formatMessage({
            id: 'product_exceptions.table.titles.productId',
          }),
          width: 120,
        },
        {
          dataIndex: 'ean',
          title: formatMessage({
            id: 'product_exceptions.table.titles.ean',
          }),
          width: 150,
        },
        {
          dataIndex: 'evaluationExpression',
          title: formatMessage({
            id: 'product_exceptions.table.titles.eval_expression',
          }),
          ellipsis: true,
          render: (text, { evaluationExpression }) => {
            if (!evaluationExpression) {
              return '';
            }
            const parsed = parseExpressionValue(evaluationExpression);
            const key = parsed.base ? parsed.base : 'ABS';
            return formatMessage({ id: `enums.expressions.${key}_help` }) + parsed.operator + parsed.numberValue;
          },
        },
        {
          dataIndex: 'parameters',
          title: formatMessage({
            id: 'product_exceptions.table.titles.parameters',
          }),
          render: (text, { parameters }) => (
            <>{flatParameters(parameters, formatMessage, ';')}</>
          ),
          ellipsis: true,
          width: 150,
        },
        {
          dataIndex: 'validFrom',
          title: formatMessage({
            id: 'product_exceptions.table.titles.valid_from',
          }),
          width: 100,
        },
        {
          dataIndex: 'validTo',
          title: formatMessage({
            id: 'product_exceptions.table.titles.valid_to',
          }),
          width: 100,
        },
        {
          dataIndex: 'batchNumber',
          title: formatMessage({
            id: 'product_exceptions.table.titles.batch',
          }),
          width: 70,
        },
        {
          dataIndex: 'active',
          title: formatMessage({
            id: 'product_exceptions.table.titles.active',
          }),
          render: (text, { active }) => <>{active ? 'true' : 'false'}</>,
          width: 70,
        },
        {
          dataIndex: 'state',
          title: formatMessage({
            id: 'product_exceptions.table.titles.state',
          }),
          render: (text, { state }) => <RuleStateCell ruleState={state} />,
          width: 110,
        },
        {
          key: 'update-button',
          align: 'left',
          width: 100,
          render: (text, { id, state }) => (state !== RuleStates.LOCKED) ? (
            <UpdateButton
              onClick={e => {
                e.preventDefault();
                onEdit(id);
              }}
            />
          ) : <></>,
        },
      ]}
    />
  );
};

export default ProductExceptionsTable;
