import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Divider, Form, Icon, List, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType, IdAndNameTypeIn } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { debounce } from '../utils/DataManagement';
import { GENERAL_PAGE_SIZE } from '../store/general';

export interface Props {
  value?: IdAndNameTypeIn[];
  onChange?: Function;
  disabled?: boolean;
  forcedPage?: number;
}

export const LIST_PAGE_ITEMS_COUNT = 4;

const SupplierInputs = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const { setSearchSupplier, fetchSuppliers } = useStoreActions(actions => actions.general);
    const loadingSuppliers = useStoreState(state => state.general.loadingSuppliers);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>([]);
    const [total, setTotal] = useState(-1);
    const supplierFormItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    const handleChange = (
      index: number,
      val: LabeledValue,
    ) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          if (val) {
            // @ts-ignore
            valueClone[index].externalId = val?.label?.props?.children[0].props.children;
            // @ts-ignore
            valueClone[index].name = val?.label?.props?.children[1].props.children;
          }
          onChange(valueClone);
        }
      }
    };

    const handleRemove = (obj: IdAndNameTypeIn) => {
      if (onChange && value) {
        onChange(value.filter(v => v !== obj));
      }
    };

    const onSearch = (searchText: string) => {
      setSearchSupplier(searchText);

      // if (!searchText || searchText.length < 3) {
      //   setDataSource([]);
      //   setTotal(-1);
      //   return;
      // }

      fetchSuppliers().then((data: any) => {
        if (data) {
          setDataSource(data.data);
          setTotal(data.total);
        } else {
          setDataSource([]);
          setTotal(-1);
        }
      });
    };

    const onSearchDebounce = debounce(onSearch, 700);

    const onFocus = () => {
      onSearchDebounce('').then();
    };

    const formatOptionText = (
      oneValue: IdAndNameType,
    ): any => {

      return (
        <>
          <span
            className='text-ellipsis'
            style={{ float: 'left', maxWidth: '130px', display: 'inline-block' }}
          >{oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{ float: 'right', width: '130px', maxWidth: '130px', textAlign: 'right' }}
          >{oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div><FormattedMessage id='pricing_rules.detail.supplier_id' />
          <span style={{ float: 'right' }}> <FormattedMessage id='pricing_rules.detail.supplier_name' /></span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPSUPPLIERS' label={renderGroupTitle()}>
        {dataSource.map(data =>
          <Option
            key={`${data.id}`}
            value={`${data.id} - ${data.name}`}
          >{formatOptionText(data)}
          </Option>)}
      </OptGroup>,
    ];

    return (
      <div ref={ref} className='scrolling-container'>
        <List
          itemLayout='vertical'
          size='small'
          dataSource={value}
          renderItem={(item, index) => (
            <List.Item
              // eslint-disable-next-line react/no-array-index-key
              key={`SUP${item.id}-${index}`}
            >
              <Row
                type='flex'
                align='middle'
                justify='space-around'
                className='minimal-size'
              >
                <Col span={23}>
                  <Form.Item
                    label={
                      <FormattedMessage id='pricing_rules.detail.supplier' />
                    }
                    required
                    // help={<FormattedMessage id='common.combo_search_placeholder' />}
                    {...supplierFormItemLayout}
                  >
                    <Select
                      showSearch
                      disabled={disabled}
                      labelInValue
                      filterOption={false}
                      style={{ width: '100%' }}
                      dropdownMatchSelectWidth={false}
                      onChange={(val: LabeledValue) =>
                        handleChange(
                          index,
                          val,
                        )
                      }
                      dropdownStyle={{ width: 300 }}
                      onSearch={onSearchDebounce}
                      value={{
                        key: item.externalId,
                        label: item.externalId || item.name ? `${item.externalId} - ${item.name}` : undefined,
                      } as LabeledValue}
                      onFocus={() => {
                        onFocus();
                      }}
                      notFoundContent={null}
                      loading={loadingSuppliers}
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          {total > GENERAL_PAGE_SIZE && (
                            <>
                              <Divider style={{ margin: '4px 0' }} />
                              <div style={{ padding: '5px 10px' }} className='ant-form-explain'>
                                <FormattedMessage
                                  id='common.combo_search_more_results'
                                  values={{ total, limit: GENERAL_PAGE_SIZE }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Col>
                {!disabled && (
                  <Col span={1}>
                    <Icon
                      className='delete-icon'
                      onClick={e => {
                        e.preventDefault();
                        handleRemove(item);
                      }}
                      type='delete'
                    />
                  </Col>
                )}
              </Row>
            </List.Item>
          )}
        />
      </div>
    );
  },
);

export default SupplierInputs;
