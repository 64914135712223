import axios, { AxiosBasicCredentials } from 'axios';
import { Alert, message, Modal } from 'antd';
import MockAdapter from 'axios-mock-adapter';
import $ from 'jquery';
import { createElement } from 'react';

let username = '';
let password = '';

export const AUTH_COOKIE_NAME = 'pms-admin-data';

function setWithExpiry(value: any) {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + 30 * 60 * 1000, // 30 minutes
  };
  localStorage.setItem(AUTH_COOKIE_NAME, JSON.stringify(item));
}

export function getWithExpiry() {
  const itemStr = localStorage.getItem(AUTH_COOKIE_NAME);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(AUTH_COOKIE_NAME);
    // eslint-disable-next-line no-console
    console.log('Credentials expired, please login again.');
    return null;
  }
  return item.value;
}

function getWithoutExpiry() {
  const itemStr = localStorage.getItem(AUTH_COOKIE_NAME);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  return item.value;
}

const axiosInstance = axios.create({
  // @ts-ignore
  baseURL: window.configuration.apiUrl,
  paramsSerializer: params => $.param(params, true),
});

const mock = new MockAdapter(axiosInstance, { delayResponse: 200 });

// @ts-ignore
if (!window.configuration.mock) {
  mock.restore();
}

axiosInstance.interceptors.response.use(
  res => res,
  error => {
    const expectedError = error?.response?.status === 200;

    if (!expectedError) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.code === 'VALIDATION_ERROR'
      ) {
        if (
          error.response.data.validationErrors &&
          error.response.config.url === '/product-exceptions/upload'
        ) {
          Modal.error({
            title: 'Errors',
            width: 820,
            content: error.response.data.validationErrors.map(
              (v: { details: string }) => {
                return createElement(Alert, {
                  key: v.details,
                  style: {
                    marginBottom: '5px',
                  },
                  type: 'error',
                  message: v.details,
                  closable: true,
                });
              },
            ),
          });
        } else if (error.response.data.validationErrors) {
          error.response.data.validationErrors.map(
            (v: {
              code: string;
              codeDescription: string;
              field: string;
              details: string;
            }) => {
              message.error(v.details, 5);
              return v;
            },
          );
        } else if (error.response.data.message) {
          message.error(error.response.data.message, 5);
        } else {
          message.error(error.response.data.details, 5);
        }
      } else if (error.response?.data?.details) {
        message.error(error.response.data.details);
      } else if (error.response?.data?.codeDescription) {
        message.error(error.response.data.codeDescription);
      } else {
        message.error(`Error! ${error.response?.status || ''}`);
      }
    }

    return Promise.reject(error);
  },
);

// eslint-disable-next-line func-names
axiosInstance.interceptors.request.use(function(axiosConfig) {
  const credentials = getWithoutExpiry();
  if (
    credentials &&
    credentials.username !== '' &&
    credentials.password !== ''
  ) {
    axiosConfig.auth = {
      username: credentials.username,
      password: credentials.password,
    };
  }

  return axiosConfig;
});

function setAuth(credentials: AxiosBasicCredentials) {
  username = credentials.username;
  password = credentials.password;

  setWithExpiry({ username, password });
}

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  patch: axiosInstance.patch,
  delete: axiosInstance.delete,
  mock,
  setAuth,
  setWithExpiry,
};
