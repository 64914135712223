import React from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { IdAndNameType, IdAndNameTypeIn, TopNCategory } from '../api/types';
import { Mode } from '../store/types';
import { addFieldExternalId, transformFormData } from '../utils/DataManagement';
import CategoryInput from './CategoryInput';
import NewButton from './NewButton';
import HeurekaShopInputs from './HeurekaShopInputs';
import EvaluationExpressionInput from './EvaluationExpressionInput';

interface Props extends FormComponentProps<TopNCategory> {
  data: TopNCategory;
  mode: Mode;
}

const types = ['NORMAL', 'UP', 'DOWN', 'DOWN9'];

const TopNCategoryForm: React.FC<Props> = ({
  form: { getFieldDecorator, getFieldValue, setFieldsValue },
  mode,
}) => {
  const { formatMessage } = useIntl();

  const isView = mode === Mode.VIEW;
  const comparedShops: IdAndNameType[] = getFieldValue('comparedShops');
  const addComparedShop = () => {
    setFieldsValue({
      comparedShops: [...comparedShops, { id: '', name: '', externalId: '' }],
    });
  };
  getFieldDecorator('id');

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      className='pup-form'
    >
      <Row
        gutter={16}
        justify='space-around'
        align='middle'
        style={{ marginRight: 0 }}
      >
        <Col span={24}>
          {getFieldDecorator('category.id')(
            <CategoryInput
              disabled={isView}
              customLabelCol={4}
              formItemCustomStyle={{ marginLeft: '-12px' }}
            />,
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            label={<FormattedMessage id='top_n_categories.detail.price_from' />}
          >
            {getFieldDecorator('priceFrom', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<InputNumber disabled={isView} min={0} max={99999999} />)}
          </Form.Item>
        </Col>
        <Col span={15}>
          <Form.Item
            label={<FormattedMessage id='top_n_categories.detail.price_to' />}
          >
            {getFieldDecorator(
              'priceTo',
              {},
            )(<InputNumber disabled={isView} min={0} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            label={<FormattedMessage id='top_n_categories.detail.top_n' />}
          >
            {getFieldDecorator('topN', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<InputNumber disabled={isView} min={0} max={99999999} />)}
          </Form.Item>
        </Col>
        <Col span={15}>
          <Form.Item
            label={
              <FormattedMessage id='top_n_categories.detail.top_n_expression' />
            }
          >
            {getFieldDecorator('topNExpression', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <EvaluationExpressionInput
                disabled={isView}
                expressionData={[
                  {
                    id: 'sPRICE',
                    text: formatMessage({
                      id: `top_n_categories.detail.top_n_expression_choice.sPRICE`,
                    }),
                    help: formatMessage({
                      id: `top_n_categories.detail.top_n_expression_choice.sPRICE_help`,
                    }),
                  },
                  {
                    id: 'pPRICE',
                    text: formatMessage({
                      id: `top_n_categories.detail.top_n_expression_choice.pPRICE`,
                    }),
                    help: formatMessage({
                      id: `top_n_categories.detail.top_n_expression_choice.pPRICE_help`,
                    }),
                  },
                ]}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            label={<FormattedMessage id='top_n_categories.detail.rounding' />}
          >
            {getFieldDecorator('roudingType', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(
              <Select disabled={isView}>
                {types.map(value => (
                  <Select.Option key={value} value={value}>
                    {formatMessage({ id: `enums.rounding.${value}` })}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            label={<FormattedMessage id='top_n_categories.detail.priority' />}
          >
            {getFieldDecorator('priority', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={32} className='params-title'>
          <Form.Item>
            <div
              className='ant-form-text ant-modal-title'
              style={{ width: '200px', textAlign: 'center' }}
            >
              <FormattedMessage id='top_n_categories.detail.compared_shops' />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify='space-around' align='middle'>
        <Col span={32}>
          {!isView && <NewButton onClick={addComparedShop} />}
          {getFieldDecorator('comparedShops')(
            <HeurekaShopInputs disabled={isView} />,
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data: { category, comparedShops, ...rest } }) => {
    return transformFormData({
      category: { ...category, externalId: category.id } as IdAndNameTypeIn,
      comparedShops: addFieldExternalId(comparedShops),
      ...rest,
    });
  },
})(TopNCategoryForm);
