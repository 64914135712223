import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { PricingRule, RuleStates } from '../api/types';
import { StoreModel } from './types';
import { pricingRulesApi } from '../api';

export const defaultPricingRule: PricingRule = {
  id: -1,
  categories: [{ name: '', id: '' }],
  suppliers: [{ name: '', id: '' }],
  state:RuleStates.DRAFT,
  name: '',
  parameterGroups: [{
    id: -1, name: 'Parameter group 1',
    intervals: [{ id: -1 }],
    parameters: [{ id: '', name: '' }],
  }],
  validFrom: '',
  validTo: '',
  active: true,
};

export interface PricingRulesStore {
  isLoading: boolean;
  list: OrderedMap<number, PricingRule>;
  detail: PricingRule;
  selectedDetailId: number;
  setSelectedDetailId: Action<PricingRulesStore, number>;
  setLoading: Action<PricingRulesStore, boolean>;
  setList: Action<PricingRulesStore, PricingRule[]>;
  setDetail: Action<PricingRulesStore, PricingRule>;
  fetchList: Thunk<PricingRulesStore, void, any, StoreModel>;
  fetchPricingRule: Thunk<PricingRulesStore,
    number,
    any,
    StoreModel,
    Promise<boolean>>;
  createPricingRule: Thunk<PricingRulesStore,
    PricingRule,
    any,
    StoreModel,
    Promise<boolean>>;
  updatePricingRule: Thunk<PricingRulesStore,
    PricingRule,
    any,
    StoreModel,
    Promise<boolean>>;
  deletePricingRule: Thunk<PricingRulesStore,
    number,
    any,
    StoreModel,
    Promise<boolean>>;
}

const pricingRules: PricingRulesStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultPricingRule,
  selectedDetailId: -1,
  setSelectedDetailId: action((state, payload) => {
    state.selectedDetailId = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await pricingRulesApi.getPricingRulesList();
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchPricingRule: thunk(async (actions, id) => {
    try {
      const { data } = await pricingRulesApi.getPricingRuleDetail(id);
      if (data && data.parameterGroups) {
        data.parameterGroups.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        });
      }
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createPricingRule: thunk(async (actions, value) => {
    try {
      await pricingRulesApi.createPricingRule(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updatePricingRule: thunk(async (actions, value) => {
    try {
      await pricingRulesApi.updatePricingRule(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  deletePricingRule: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await pricingRulesApi.deletePricingRule(value);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default pricingRules;
