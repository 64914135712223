import React from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { IdAndNameTypeIn, MinMargin } from '../api/types';
import { Mode } from '../store/types';
import { transformFormData } from '../utils/DataManagement';
import CategoryInput from './CategoryInput';

interface Props extends FormComponentProps<MinMargin> {
  data: MinMargin;
  mode: Mode;
}

export const dateFormat = 'YYYY-MM-DD';

const MinMarginForm: React.FC<Props> = ({
                                          form: { getFieldDecorator },
                                          mode,
                                        }) => {
  const isView = mode === Mode.VIEW;
  getFieldDecorator('id');

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      className='pup-form'
    >
      <Row gutter={16} justify='space-around' align='middle' style={{ marginRight: 0 }}>
        <Col span={24}>
          {getFieldDecorator('category.id')(
            <CategoryInput disabled={isView} />,
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='min_margins.detail.purchase_price_from' />
            }
          >
            {getFieldDecorator('purchasePriceFrom', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<InputNumber
              disabled={isView}
              min={0}
              max={99999999}
            />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='min_margins.detail.purchase_price_to' />
            }
          >
            {getFieldDecorator('purchasePriceTo', {})(<InputNumber
              disabled={isView}
              min={0}
              max={99999999}
            />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='min_margins.detail.min_margin' />
            }
          >
            {getFieldDecorator('minMargin', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<InputNumber
              disabled={isView}
              formatter={value => value ? `${value}%` : ''}
              min={0}
              max={100}
              step={1}
              parser={value => value ? value.replace('%', '') : ''}
            />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({ data: { category, ...rest } }) => {
    return transformFormData({
      category: { ...category, externalId: category.id } as IdAndNameTypeIn,
      ...rest,
    });
  },
})(MinMarginForm);
