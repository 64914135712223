import { Action, action, Thunk, thunk } from 'easy-peasy';
import { DashboardReportRow } from '../api/types';
import { StoreModel } from './types';
import { dashboardApi } from '../api';

export interface DashboardReportsStore {
  isLoadingCategories: boolean;
  isLoadingSuppliers: boolean;
  listOfCategories: DashboardReportRow[];
  listOfSuppliers: DashboardReportRow[];
  setLoadingCategories: Action<DashboardReportsStore, boolean>;
  setLoadingSuppliers: Action<DashboardReportsStore, boolean>;
  setListCategories: Action<DashboardReportsStore, DashboardReportRow[]>;
  setListSuppliers: Action<DashboardReportsStore, DashboardReportRow[]>;
  fetchListCategories: Thunk<DashboardReportsStore, void, any, StoreModel>;
  fetchListSuppliers: Thunk<DashboardReportsStore, void, any, StoreModel>;
}

const dashboardReports: DashboardReportsStore = {
  isLoadingCategories: false,
  isLoadingSuppliers: false,
  listOfCategories: [],
  listOfSuppliers: [],
  setLoadingCategories: action((state, payload) => {
    state.isLoadingCategories = payload;
  }),
  setLoadingSuppliers: action((state, payload) => {
    state.isLoadingSuppliers = payload;
  }),
  setListCategories: action((state, payload) => {
    state.listOfCategories = payload;
  }),
  setListSuppliers: action((state, payload) => {
    state.listOfSuppliers = payload;
  }),
  fetchListCategories: thunk(async actions => {
    try {
      actions.setLoadingCategories(true);
      const { data } = await dashboardApi.getReportByCategory('byCategory');
      actions.setListCategories(data);
      actions.setLoadingCategories(false);
    } catch (error) {
      actions.setLoadingCategories(false);
    }
  }),
  fetchListSuppliers: thunk(async actions => {
    try {
      actions.setLoadingSuppliers(true);
      const { data } = await dashboardApi.getReportByCategory('bySupplier');
      actions.setListSuppliers(data);
      actions.setLoadingSuppliers(false);
    } catch (error) {
      actions.setLoadingSuppliers(false);
    }
  }),
};

export default dashboardReports;
