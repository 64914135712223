import React, { forwardRef, useEffect, useState } from 'react';
import {
  AutoComplete,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LabeledValue } from 'antd/es/select';
import {
  IdAndNameType,
  Interval,
  ParameterGroupIn,
  ParameterIn,
} from '../api/types';
import NewButton from './NewButton';
import { useStoreActions, useStoreState } from '../store/hooks';
import EvaluationExpressionInput from './EvaluationExpressionInput';
import { GENERAL_PAGE_SIZE } from '../store/general';
import { debounce } from '../utils/DataManagement';

export interface Props {
  value?: ParameterGroupIn[];
  onChange?: Function;
  disabled?: boolean;
}

const ParametersGroupInputs = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const { formatMessage } = useIntl();
    const [currentGroupIndex, setCurrentGroupIndex] = useState(
      value && value.length > 0 ? 0 : -1,
    );
    const { setSearchParameter, fetchParameters } = useStoreActions(
      actions => actions.general,
    );
    const loadingParameters = useStoreState(
      state => state.general.loadingParameters,
    );
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>([]);
    const [total, setTotal] = useState(-1);
    const [
      selectedGroup,
      setSelectedGroup,
    ] = useState<ParameterGroupIn | null>();
    const [isVisibleDCG, setVisibleDCG] = useState(false);
    const paramFormItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    useEffect(() => {
      if (
        !value ||
        currentGroupIndex >= value.length ||
        (currentGroupIndex === -1 && value && value.length > 0)
      ) {
        setCurrentGroupIndex(value && value.length > 0 ? 0 : -1);
      }
      // eslint-disable-next-line
    }, [value]);

    const parameterFormItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 12 },
    };
    const intervalFormItemLayout = {
      labelCol: { span: 15 },
      wrapperCol: { span: 7 },
    };
    const intervalEvalExpressionFormItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    };
    const getCurrentGroup = () => {
      return currentGroupIndex > -1 && value ? value[currentGroupIndex] : null;
    };
    const getGroupName = (index: number) => {
      const targetIndex = currentGroupIndex + index;

      return targetIndex > -1 && value && value.length > targetIndex
        ? value[targetIndex].name
        : '';
    };

    const handleChange = (field: 'name', index: number, val: string = '') => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          valueClone[index][field] = val;
          onChange(valueClone);
        }
      }
    };

    const handleChangeNumber = (
      field: 'priority',
      index: number,
      val?: number,
    ) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          valueClone[index][field] = val;
          onChange(valueClone);
        }
      }
    };

    const handleChangeParam = (index: number, val: LabeledValue) => {
      if (onChange) {
        if (value && value.length > 0 && currentGroupIndex > -1) {
          if (val) {
            // @ts-ignore
            value[currentGroupIndex].parameters[index].externalId =
              // @ts-ignore
              val?.label?.props?.children[0].props.children;
            // @ts-ignore
            value[currentGroupIndex].parameters[index].name =
              // @ts-ignore
              val?.label?.props?.children[1].props.children;
          }

          onChange(value);
        }
      }
    };

    const handleChangeInterval = (
      field: 'evaluationExpression',
      index: number,
      val: string = '',
    ) => {
      if (onChange) {
        if (value && value.length > 0 && currentGroupIndex > -1) {
          value[currentGroupIndex].intervals[index][field] = val;

          onChange(value);
        }
      }
    };

    const handleChangeIntervalNumber = (
      field: 'priceFrom' | 'priceTo',
      index: number,
      val?: number,
    ) => {
      if (onChange) {
        if (value && value.length > 0 && currentGroupIndex > -1) {
          value[currentGroupIndex].intervals[index][field] = val;

          onChange(value);
        }
      }
    };

    const addParamGroup = () => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [
            ...value,
            {
              id: -1,
              name: `New param group ${value.length + 1}`,
              parameters: [],
              intervals: [],
            } as ParameterGroupIn,
          ];
          onChange(valueClone);
          setCurrentGroupIndex(valueClone.length - 1);
        } else if (value) {
          const valueClone = [
            {
              id: -1,
              name: `New param group 1`,
              parameters: [],
              intervals: [],
            } as ParameterGroupIn,
          ];
          onChange(valueClone);
          setCurrentGroupIndex(0);
        }
      }
    };

    const handleRemoveGroup = (obj: ParameterGroupIn | null) => {
      setSelectedGroup(obj);
      setVisibleDCG(true);
    };

    const handleDeleteSelected = () => {
      setVisibleDCG(false);
      if (onChange && selectedGroup && value) {
        const filtered = value.filter(v => v !== selectedGroup);
        onChange(filtered);
        setCurrentGroupIndex(filtered && filtered.length > 0 ? 0 : -1);
      }
    };

    const handleNavigationOnClick = (offset: number) => {
      const newGroupIndex = currentGroupIndex + offset;
      if (value && newGroupIndex > -1 && newGroupIndex < value.length) {
        setCurrentGroupIndex(newGroupIndex);
      }
    };

    const addParameter = () => {
      if (value && currentGroupIndex > -1) {
        value[currentGroupIndex].parameters.push({
          id: '',
          name: '',
          externalId: '',
        } as ParameterIn);
        if (onChange) {
          onChange(value);
        }
      }
    };

    const handleRemoveParam = (id: string) => {
      if (
        onChange &&
        value &&
        currentGroupIndex > -1 &&
        value[currentGroupIndex].parameters
      ) {
        value[currentGroupIndex].parameters = value[
          currentGroupIndex
        ].parameters.filter(v => v.externalId !== id);
        onChange(value);
      }
    };

    const addInterval = () => {
      if (value && currentGroupIndex > -1) {
        value[currentGroupIndex].intervals.push({ id: -1 } as Interval);
        if (onChange) {
          onChange(value);
        }
      }
    };

    const handleRemoveInterval = (id: number) => {
      if (
        onChange &&
        value &&
        currentGroupIndex > -1 &&
        value[currentGroupIndex].intervals
      ) {
        value[currentGroupIndex].intervals = value[
          currentGroupIndex
        ].intervals.filter(v => v.id !== id);
        onChange(value);
      }
    };

    const onSearch = (searchText: string) => {
      setSearchParameter(searchText);

      // if (!searchText || searchText.length < 3) {
      //   setDataSource([]);
      //   setTotal(-1);
      //   return;
      // }

      fetchParameters().then((data: any) => {
        if (data) {
          setDataSource(data.data);
          setTotal(data.total);
        } else {
          setDataSource([]);
          setTotal(-1);
        }
      });
    };

    const onSearchDebounce = debounce(onSearch, 700);

    const onFocus = () => {
      onSearchDebounce('');
    };

    const formatOptionText = (oneValue: IdAndNameType): any => {
      return (
        <>
          <span
            className='text-ellipsis'
            style={{
              float: 'left',
              maxWidth: '130px',
              display: 'inline-block',
            }}
          >
            {oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{
              float: 'right',
              width: '130px',
              maxWidth: '130px',
              textAlign: 'right',
            }}
          >
            {oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div>
          <FormattedMessage id='product_exceptions.detail.params.param_id' />
          <span style={{ float: 'right' }}>
            {' '}
            <FormattedMessage id='product_exceptions.detail.params.param_name' />
          </span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPPARAMS' label={renderGroupTitle()}>
        {dataSource.map(data => (
          <Option key={`${data.id}`} value={`${data.id} - ${data.name}`}>
            {formatOptionText(data)}
          </Option>
        ))}
      </OptGroup>,
    ];

    return (
      <div ref={ref}>
        <Row
          gutter={16}
          type='flex'
          justify={getCurrentGroup() != null ? 'center' : 'end'}
          align='middle'
          style={{ textAlign: 'center', marginTop: '-10px' }}
        >
          {getCurrentGroup() != null && (
            <Col span={24}>
              <div className='ant-tabs-nav-wrap'>
                <div className='ant-tabs-nav-scroll'>
                  <div className='ant-tabs-nav'>
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <div
                        role='tab'
                        aria-disabled='true'
                        aria-selected='false'
                        className='ant-tabs-tab tabs-custom  non-active-tab'
                        onClick={() => handleNavigationOnClick(-1)}
                        style={{
                          visibility: getGroupName(-1) ? 'inherit' : 'hidden',
                        }}
                      >
                        {getGroupName(-1) || <span>&nbsp;</span>}
                      </div>
                      <div
                        role='tab'
                        aria-disabled='false'
                        aria-selected='true'
                        className=' ant-tabs-tab tabs-custom ant-tabs-tab-active '
                      >
                        <Input
                          disabled={disabled}
                          value={getCurrentGroup()?.name}
                          style={{
                            width: 'auto',
                            maxWidth: '100%',
                            minWidth: '40%',
                          }}
                          onChange={val =>
                            handleChange(
                              'name',
                              currentGroupIndex,
                              val.currentTarget.value,
                            )
                          }
                        />
                      </div>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <div
                        role='tab'
                        aria-disabled='true'
                        aria-selected='false'
                        className='ant-tabs-tab tabs-custom non-active-tab'
                        onClick={() => handleNavigationOnClick(1)}
                        style={{
                          visibility: getGroupName(1) ? 'inherit' : 'hidden',
                        }}
                      >
                        {getGroupName(1) || <span>&nbsp;</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          {!disabled && getCurrentGroup() != null && (
            <span style={{ position: 'absolute', right: '7px' }}>
              <Icon
                type='plus-circle'
                className='add-icon no-select'
                style={{ marginRight: '15px' }}
                onClick={e => {
                  e.preventDefault();
                  addParamGroup();
                }}
              />
              <Icon
                className='delete-icon no-select'
                onClick={e => {
                  e.preventDefault();
                  handleRemoveGroup(getCurrentGroup());
                }}
                type='delete'
              />
              <Modal
                title={<FormattedMessage id='common.confirmation' />}
                visible={isVisibleDCG}
                width={700}
                onCancel={e => {
                  e.preventDefault();
                  setVisibleDCG(false);
                }}
                okText={<FormattedMessage id='common.delete' />}
                onOk={handleDeleteSelected}
              >
                <FormattedMessage id='common.confirm_delete_group' />
              </Modal>
            </span>
          )}
          {!disabled && getCurrentGroup() == null && (
            <span style={{ marginRight: '10px' }}>
              <Icon
                type='plus-circle'
                className='add-icon no-select'
                onClick={e => {
                  e.preventDefault();
                  addParamGroup();
                }}
              />
            </span>
          )}
        </Row>

        {getCurrentGroup() != null && (
          <>
            <Row
              gutter={16}
              style={{ marginTop: '10px', paddingBottom: '5px' }}
            >
              <Col span={9}>
                <Form.Item
                  label={
                    <span
                      title={formatMessage({
                        id: 'pricing_rules.detail.priority_tooltip',
                      })}
                    >
                      <FormattedMessage id='pricing_rules.detail.priority' />
                    </span>
                  }
                  required
                  // validateStatus='error' // todo for further study of requirement of dynamic fields
                  // help={<FormattedMessage id='validations.required' />}
                  {...parameterFormItemLayout}
                >
                  <InputNumber
                    disabled={disabled}
                    value={getCurrentGroup()?.priority}
                    onChange={val =>
                      handleChangeNumber('priority', currentGroupIndex, val)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <span className='ant-form-explain'>
                  <FormattedMessage
                    id='pricing_rules.detail.record_count'
                    values={{
                      actual:
                        currentGroupIndex > -1 ? currentGroupIndex + 1 : 0,
                      total: value ? value.length : 0,
                    }}
                  />
                </span>
              </Col>
            </Row>
            <Row gutter={16} justify='space-around' align='middle'>
              <Col
                span={32}
                className='parameter-pagination scrolling-container scrolling-container-two-row'
              >
                {!disabled && (
                  <NewButton
                    onClick={addInterval}
                    title={formatMessage({
                      id: 'pricing_rules.detail.add_an_interval',
                    })}
                  />
                )}
                <List
                  itemLayout='vertical'
                  size='small'
                  dataSource={getCurrentGroup()?.intervals}
                  renderItem={(item, index) => (
                    <List.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={`INT${item.id}-${index}`}
                    >
                      <Row
                        type='flex'
                        align='middle'
                        justify='space-around'
                        className='minimal-size'
                      >
                        <Col span={5}>
                          <Form.Item
                            label={
                              <FormattedMessage id='pricing_rules.detail.price_from' />
                            }
                            required
                            {...intervalFormItemLayout}
                          >
                            <InputNumber
                              disabled={disabled}
                              value={item.priceFrom}
                              min={0}
                              max={99999999}
                              className='input-number-smaller'
                              onChange={val =>
                                handleChangeIntervalNumber(
                                  'priceFrom',
                                  index,
                                  val,
                                )
                              }
                              style={{ width: '70px' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label={
                              <FormattedMessage id='pricing_rules.detail.price_to' />
                            }
                            {...intervalFormItemLayout}
                          >
                            <InputNumber
                              disabled={disabled}
                              value={item.priceTo}
                              min={0}
                              className='input-number-smaller'
                              onChange={val =>
                                handleChangeIntervalNumber(
                                  'priceTo',
                                  index,
                                  val,
                                )
                              }
                              style={{ width: '70px' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={14} style={{ paddingBottom: '12px' }}>
                          <Form.Item
                            label={
                              <FormattedMessage id='pricing_rules.detail.eval_expression' />
                            }
                            required
                            {...intervalEvalExpressionFormItemLayout}
                          >
                            <EvaluationExpressionInput
                              disabled={disabled}
                              value={item.evaluationExpression}
                              onChangeToParent={val =>
                                handleChangeInterval(
                                  'evaluationExpression',
                                  index,
                                  val,
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        {!disabled && (
                          <Col span={1}>
                            <Icon
                              className='delete-icon'
                              onClick={e => {
                                e.preventDefault();
                                handleRemoveInterval(item.id);
                              }}
                              type='delete'
                            />
                          </Col>
                        )}
                      </Row>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              justify='space-around'
              align='middle'
              style={{ paddingTop: '10px' }}
            >
              <Col
                span={32}
                className='parameter-pagination scrolling-container scrolling-container-two-row'
              >
                {!disabled && (
                  <NewButton
                    onClick={addParameter}
                    title={formatMessage({
                      id: 'pricing_rules.detail.add_a_parameter',
                    })}
                  />
                )}
                <List
                  itemLayout='vertical'
                  size='small'
                  dataSource={getCurrentGroup()?.parameters}
                  renderItem={(item, index) => (
                    <List.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={`PAR${item.id}-${index}`}
                    >
                      <Row
                        type='flex'
                        align='middle'
                        justify='space-around'
                        className='minimal-size'
                      >
                        <Col span={23}>
                          <Form.Item
                            label={
                              <FormattedMessage id='pricing_rules.detail.param' />
                            }
                            required
                            // help={<FormattedMessage id='common.combo_search_placeholder' />}
                            {...paramFormItemLayout}
                          >
                            <Select
                              showSearch
                              disabled={disabled}
                              labelInValue
                              filterOption={false}
                              style={{ width: '100%' }}
                              dropdownMatchSelectWidth={false}
                              onChange={(val: LabeledValue) =>
                                handleChangeParam(index, val)
                              }
                              dropdownStyle={{ width: 300 }}
                              onSearch={onSearchDebounce}
                              value={
                                {
                                  key: item.externalId,
                                  label:
                                    item.externalId || item.name
                                      ? `${item.externalId} - ${item.name}`
                                      : undefined,
                                } as LabeledValue
                              }
                              onFocus={() => {
                                onFocus();
                              }}
                              notFoundContent={null}
                              loading={loadingParameters}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  {total > GENERAL_PAGE_SIZE && (
                                    <>
                                      <Divider style={{ margin: '4px 0' }} />
                                      <div
                                        style={{ padding: '5px 10px' }}
                                        className='ant-form-explain'
                                      >
                                        <FormattedMessage
                                          id='common.combo_search_more_results'
                                          values={{
                                            total,
                                            limit: GENERAL_PAGE_SIZE,
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            >
                              {options}
                            </Select>
                          </Form.Item>
                        </Col>
                        {!disabled && (
                          <Col span={1}>
                            <Icon
                              className='delete-icon'
                              onClick={e => {
                                e.preventDefault();
                                handleRemoveParam(item.externalId);
                              }}
                              type='delete'
                            />
                          </Col>
                        )}
                      </Row>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  },
);

export default ParametersGroupInputs;
