import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import {
  IdAndNameType,
  RuleStates,
  TopNCategory,
  TopNCategoryListItem,
} from '../api/types';
import { StoreModel } from './types';
import { topNCategoriesApi } from '../api';

export const defaultTopNCategory: TopNCategory = {
  id: -1,
  state: RuleStates.DRAFT,
  category: { id: '', name: '' },
  comparedShops: [] as IdAndNameType[],
  priceFrom: 0,
  priority: 0,
  priceTo: undefined,
  roudingType: 'NORMAL',
  topN: 1,
  topNExpression: '',
};

export interface TopNCategoriesStore {
  isLoading: boolean;
  list: OrderedMap<number, TopNCategoryListItem>;
  detail: TopNCategory;
  selectedDetailId: number;
  setSelectedDetailId: Action<TopNCategoriesStore, number>;
  setLoading: Action<TopNCategoriesStore, boolean>;
  setList: Action<TopNCategoriesStore, TopNCategoryListItem[]>;
  setDetail: Action<TopNCategoriesStore, TopNCategory>;
  fetchList: Thunk<TopNCategoriesStore, void, any, StoreModel>;
  fetchTopNCategory: Thunk<
    TopNCategoriesStore,
    number,
    any,
    StoreModel,
    Promise<boolean>
  >;
  createTopNCategory: Thunk<
    TopNCategoriesStore,
    TopNCategory,
    any,
    StoreModel,
    Promise<boolean>
  >;
  updateTopNCategory: Thunk<
    TopNCategoriesStore,
    TopNCategory,
    any,
    StoreModel,
    Promise<boolean>
  >;
  deleteTopNCategory: Thunk<
    TopNCategoriesStore,
    number,
    any,
    StoreModel,
    Promise<boolean>
  >;
}

const topNCategories: TopNCategoriesStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultTopNCategory,
  selectedDetailId: -1,
  setSelectedDetailId: action((state, payload) => {
    state.selectedDetailId = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await topNCategoriesApi.getTopNCategoriesList();
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchTopNCategory: thunk(async (actions, id) => {
    try {
      const { data } = await topNCategoriesApi.getTopNCategoryDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createTopNCategory: thunk(async (actions, value) => {
    try {
      await topNCategoriesApi.createTopNCategory(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updateTopNCategory: thunk(async (actions, value) => {
    try {
      await topNCategoriesApi.updateTopNCategory(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  deleteTopNCategory: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await topNCategoriesApi.deleteTopNCategory(value);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default topNCategories;
