import React, { useEffect, useState } from 'react';
import { Tooltip, Table, Row, Col, Form, Icon } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table/interface';
import Search from 'antd/es/input/Search';
import { useStoreActions, useStoreState } from '../store/hooks';
import { Product } from '../api/types';
import SupplierInput from './SupplierInput';
import CategoryInput from './CategoryInput';
import ParameterInput from './ParameterInput';
import { numberToText } from '../utils/DataManagement';

export interface Props {
  onView: (id: number) => void;
}

const ChangeLabel: React.FC<{ change: number }> = ({ change }) => {
  if (!change) {
    return <span>0</span>;
  }
  const sign = change > 0 ? '+' : '';
  let className = '';
  if (change < 0) className = 'label-red';
  else if (change > 0) className = 'label-green';
  return <span className={className}> {`${sign}${numberToText(change)}`}</span>;
};

const ProductListTable: React.FC<Props> = ({ onView }) => {
  const PAGE_SIZE = 50;

  const { list: data, isLoading, pagination } = useStoreState(
    state => state.dashboardProducts,
  );

  const { fetchList } = useStoreActions(actions => actions.dashboardProducts);

  const [categoryId, setCategoryId] = useState<string>('');
  const [supplierId, setSupplierId] = useState<string>('');
  const [parameterId, setParameterId] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [ean, setEan] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const [tablePagination, setTablePagination] = useState<PaginationConfig>({
    pageSize: PAGE_SIZE,
  });

  const [sortBy, setSortBy] = useState<{
    sortField: string;
    sortOrder: string;
  }>({
    sortField: 'pricingError',
    sortOrder: 'descend',
  });

  const { formatMessage } = useIntl();

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const fetchData = (
    pageSize: number,
    currentPage: number,
    sortField: string,
    sortOrder: string,
  ) => {
    fetchList({
      pageSize,
      page: currentPage,
      sortField,
      sortDirection: sortOrder === 'ascend' ? 'ASC' : 'DESC',
      productId: productId === '' ? undefined : productId,
      ean: ean === '' ? undefined : ean,
      title: title === '' ? undefined : title,
      supplierId: supplierId === '' ? undefined : supplierId,
      categoryId: categoryId === '' ? undefined : categoryId,
      parameterId: parameterId === '' ? undefined : parameterId,
    });
  };

  const onTableChange = (
    paginationConfig: PaginationConfig,
    filters: any,
    sorter: SorterResult<Product>,
  ) => {
    const pager = { ...tablePagination };
    pager.current = paginationConfig.current;
    pager.pageSize = paginationConfig.pageSize;
    setTablePagination(pager);

    let sortField = sorter.field;
    let sortOrder = sorter.order;
    if (!sortOrder) {
      sortField = 'id';
      sortOrder = 'ascend';
    }

    setSortBy({ sortField, sortOrder });
    fetchData(
      paginationConfig.pageSize || PAGE_SIZE,
      paginationConfig.current || 1,
      sortField,
      sortOrder,
    );
  };

  useEffect(() => {
    fetchData(
      tablePagination.pageSize || PAGE_SIZE,
      tablePagination.current || 1,
      sortBy.sortField,
      sortBy.sortOrder,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ean, productId, title, supplierId, categoryId, parameterId]);

  useEffect(() => {
    const pager = { ...tablePagination };
    pager.total = pagination.total;
    setTablePagination(pager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <Row gutter={[16, 40]}>
        <Col>
          <Form className='pup-form'>
            <Row gutter={16}>
              <Col lg={7}>
                <Form.Item
                  label={
                    <FormattedMessage id='dashboard_products.table.titles.id' />
                  }
                  {...formItemLayout}
                >
                  <Search allowClear onSearch={value => setProductId(value)} />
                </Form.Item>
              </Col>
              <Col lg={7}>
                <Form.Item
                  label={
                    <FormattedMessage id='dashboard_products.table.titles.ean' />
                  }
                  {...formItemLayout}
                >
                  <Search allowClear onSearch={value => setEan(value)} />
                </Form.Item>
              </Col>
              <Col lg={7}>
                <Form.Item
                  label={
                    <FormattedMessage id='dashboard_products.table.titles.title' />
                  }
                  {...formItemLayout}
                >
                  <Search allowClear onSearch={value => setTitle(value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={7}>
                <SupplierInput
                  value={supplierId}
                  isFilter
                  onChange={setSupplierId}
                />
              </Col>
              <Col lg={7}>
                <CategoryInput
                  value={categoryId}
                  isFilter
                  onChange={setCategoryId}
                />
              </Col>
              <Col lg={7}>
                <ParameterInput
                  value={parameterId}
                  isFilter
                  onChange={setParameterId}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table<Product>
        loading={isLoading}
        dataSource={data.valueSeq().toArray()}
        size='middle'
        rowKey={rec => `${rec.id}`}
        rowClassName={() => `c-pointer`}
        onRow={({ id }) => ({
          onClick: e => {
            e.preventDefault();
            onView(id);
          },
        })}
        onChange={onTableChange}
        pagination={tablePagination}
        scroll={{ y: true, x: 1330 }}
        columns={[
          {
            dataIndex: 'pricingError',
            title: ' ',
            width: 40,
            align: 'left',
            sorter: true,
            render: (text, { pricingError }) =>
              pricingError ? (
                <Icon
                  type='close-circle'
                  theme='outlined'
                  className='error-icon'
                />
              ) : (
                <Icon type='check-circle' className='ok-icon' />
              ),
          },
          {
            dataIndex: 'productId',
            title: formatMessage({ id: 'dashboard_products.table.titles.id' }),
            width: 100,
            align: 'center',
            sorter: true,
          },
          {
            dataIndex: 'ean',
            title: formatMessage({
              id: 'dashboard_products.table.titles.ean',
            }),
            width: 120,
            align: 'center',
            sorter: true,
          },
          {
            dataIndex: 'productDescription',
            title: formatMessage({
              id: 'dashboard_products.table.titles.title',
            }),
            ellipsis: true,
            width: 200,
            align: 'center',
            sorter: true,
          },
          {
            dataIndex: 'supplier.name',
            title: formatMessage({
              id: 'dashboard_products.table.titles.supplier',
            }),
            width: 120,
            align: 'center',
            ellipsis: true,
            sorter: true,
          },
          {
            dataIndex: 'category.name',
            title: formatMessage({
              id: 'dashboard_products.table.titles.category',
            }),
            width: 120,
            align: 'center',
            ellipsis: true,
            sorter: true,
          },
          {
            dataIndex: 'parameters',
            title: formatMessage({
              id: 'dashboard_products.table.titles.parameters',
            }),
            width: 90,
            align: 'center',
            render: (text, { parameters }) => (
              <Tooltip title={parameters.map(p => p.name).join(', ')}>
                {`(${parameters.length})`}
              </Tooltip>
            ),
          },
          {
            dataIndex: 'basePrice',
            title: formatMessage({
              id: 'dashboard_products.table.titles.basePrice',
            }),
            width: 100,
            align: 'center',
            sorter: true,
            render: (text, { basePrice }) => numberToText(basePrice),
          },
          {
            dataIndex: 'actualPrice',
            title: formatMessage({
              id: 'dashboard_products.table.titles.actualPrice',
            }),
            align: 'center',
            width: 100,
            sorter: true,
            render: (text, { actualPrice }) => numberToText(actualPrice),
          },
          {
            dataIndex: 'actualPriceDiscount',
            title: formatMessage({
              id: 'dashboard_products.table.titles.actualPriceDiscount',
            }),
            width: 120,
            sorter: true,
            render: (text, { actualPriceDiscount }) =>
              numberToText(actualPriceDiscount),
          },
          {
            dataIndex: 'newPrice',
            title: formatMessage({
              id: 'dashboard_products.table.titles.newPrice',
            }),
            width: 90,
            align: 'center',
            sorter: true,
            render: (text, { newPrice }) => numberToText(newPrice),
          },
          {
            dataIndex: 'newPriceDiscount',
            title: formatMessage({
              id: 'dashboard_products.table.titles.newPriceDiscount',
            }),
            width: 120,
            align: 'center',
            sorter: true,
            render: (text, { newPriceDiscount }) =>
              numberToText(newPriceDiscount),
          },
          {
            dataIndex: 'change',
            title: formatMessage({
              id: 'dashboard_products.table.titles.change',
            }),
            width: 90,
            align: 'center',
            sorter: true,
            render: (text, { change }) => <ChangeLabel change={change} />,
          },
        ]}
      />
    </>
  );
};

export default ProductListTable;
