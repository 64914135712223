import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RuleStates } from '../api/types';
import { Mode } from '../store/types';

interface Props {
  ruleState: RuleStates;
  mode: Mode;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  onSave: (newState: RuleStates) => void;
  onDelete: () => void;
}

const RuleDetailFormButtons: React.FC<Props> = ({ ruleState, mode, onCancel, onSave, onDelete }) => {
  return (
    <>
      <Button key='back' onClick={onCancel}>
        <FormattedMessage id='common.cancel' />
      </Button>
      {ruleState === RuleStates.DRAFT &&
      <>
        <Button key='submit' type='primary' onClick={(e) => onSave(RuleStates.DRAFT)}>
          <FormattedMessage id='common.save' />
        </Button>
        {mode === Mode.EDIT &&
        <>
          <Button key='submit' type='primary' onClick={(e) => onSave(RuleStates.FINAL)}>
            <FormattedMessage id='common.confirmDraft' />
          </Button>
          <Button type='danger' onClick={(e) => onDelete()}>
            <FormattedMessage id='common.delete' />
          </Button>
        </>}
      </>}
      {ruleState === RuleStates.FINAL &&
      <>
        <Button type='primary' onClick={(e) => onSave(RuleStates.DRAFT)}>
          <FormattedMessage id='common.saveAsDraft' />
        </Button>
        <Button type='danger' onClick={(e) => onSave(RuleStates.TO_DELETE)}>
          <FormattedMessage id='common.markForDelete' />
        </Button>
      </>}
      {ruleState === RuleStates.TO_DELETE &&
      <>
        <Button type='danger' onClick={(e) => onDelete()}>
          <FormattedMessage id='common.confirmDelete' />
        </Button>

        <Button type='primary' onClick={(e) => onSave(RuleStates.FINAL)}>
          <FormattedMessage id='common.revertDelete' />
        </Button>
      </>}
    </>
  );
};
;

export default RuleDetailFormButtons;
