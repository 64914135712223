import React from 'react';
import { Icon, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  DASHBOARD_LIST,
  DASHBOARD_REPORTS,
  MAX_DISCOUNTS,
  MIN_MARGINS,
  PRICING_RULES,
  PRODUCT_EXCEPTIONS,
  TOP_N_CATEGORIES,
} from '../routes/Routes';
import { useStoreActions, useStoreState } from '../store/hooks';

function getTitleLink(path: string): string {
  switch (path) {
    case PRODUCT_EXCEPTIONS:
      return 'product_exceptions';
    case PRICING_RULES:
      return 'pricing_rules';
    case MAX_DISCOUNTS:
      return 'max_discounts';
    case MIN_MARGINS:
      return 'min_margins';
    case TOP_N_CATEGORIES:
      return 'top_n_categories';
    case DASHBOARD_LIST:
      return 'dashboard_list_full';
    case DASHBOARD_REPORTS:
      return 'dashboard_reports_full';
    default:
      return '';
  }
}

export interface Props {
}

const HeaderBar: React.FC<Props> = () => {
  const collapsed = useStoreState(state => state.general.collapsed);
  const toggle = useStoreActions(actions => actions.general.setCollapsed);
  const { pathname } = useLocation();
  const { isLoggedIn, name, username } = useStoreState(state => state.user);
  return isLoggedIn ? (
    <Layout.Header className='header'>
      <Menu mode='horizontal'>
        <Menu.Item disabled className='no-padding'>
          <Icon
            className='trigger'
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={() => toggle(!collapsed)}
          />
          <h2 className='title'>
            {getTitleLink(pathname) !== '' && (
              <FormattedMessage id={`side_bar.${getTitleLink(pathname)}`} />
            )}
          </h2>
        </Menu.Item>
        <Menu.SubMenu
          title={
            <span className='submenu-title-wrapper'>
              <i className='fas fa-user' style={{ marginRight: 6 }} />
              {`[${username}] ${name}`}
            </span>
          }
          className='user-menu'
        >
          <Menu.Item>
            <Link to='/logout'>
              <Icon type='logout' />
              <FormattedMessage id='header.logout' />
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  ) : null;
};

export default HeaderBar;
