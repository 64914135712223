export default {
  side_bar: {
    product_exceptions: 'Produktové výjimky',
    pricing_rules: 'Slevová pravidla',
    max_discounts: 'Maximální slevy',
    min_margins: 'Minimální marže',
    top_n_categories: 'Top N',
    version: 'verze',
    dashboard: 'Sestavy',
    dashboard_list: 'Produkty',
    dashboard_reports: 'Plánované změny',
    dashboard_list_full: 'Seznam produktů',
    dashboard_reports_full: 'Přehled plánovaných změn cen produktů',
  },
  header: { logout: 'Odhlášení' },
  common: {
    update: 'editovat',
    inactive: 'neaktivní',
    save: 'Uložit',
    saveAsDraft: 'Uložit jako návrh',
    confirmDraft: 'Potvrdit návrh',
    confirmation: 'Potvrzení',
    confirm_delete_selected: 'Opravdu chcete odstranit vybrané položky?',
    confirm_selected: 'Opravdu chcete potvrdit vybrané položky?',
    confirm_delete: 'Opravdu chcete odstranit vybranou položku',
    confirm_delete_group: 'Opravdu chcete odstranit vybranou skupinu?',
    delete: 'Smazat',
    markForDelete: 'Označit ke smazání',
    confirmDelete: 'Potvrdit smazání',
    revertDelete: 'Zrušit smazání',
    date_mask: 'YYYY-MM-DD',
    message: {
      success_new: 'Záznam byl úspěšně vytvořen.',
      success_edit: 'Záznam byl uložen.',
      error: 'Chyba! Zaznam se nepodařilo uložit.',
      success_upload: 'Soubor byl úspěšně nahrán',
      error_upload: 'Soubor se nepodařilo nahrát.',
      no_records_selected: 'Nejsou vybrané žádné položky!',
      success_delete: 'Záznam byl úspěšně odstraněn.',
    },
    combo_search_placeholder: 'Zadejte alespoň 3 znaky pro vyhledání',
    combo_search_more_results:
      'Existuje celkem {total} výsledků, zobrazeno prvních {limit}, prosím, upřesněte zadání...',
    result_preview: 'Náhled',
    ok: 'OK',
    true: 'ano',
    false: 'ne',
    close: 'Zavřít',
    cancel: 'Storno',
    ruleStates: {
      DRAFT: 'Návrh pravidla',
      LOCKED: 'Výchozí pravidlo pro návrh',
      FINAL: 'Současné pravidlo',
      TO_DELETE: 'Označeno ke smazání',
      DRAFT_tooltip:
        'Pravidlo musí být potvrzeno, než bude použito pro naceňování produktů.',
      LOCKED_tooltip:
        'Pravidlo může být použito pro naceňování produktů, ale nelze jej editovat, protože má nepotvrzený návrh změny.',
      FINAL_tooltip: 'Pravidlo může být použito pro naceňování produktů.',
      TO_DELETE_tooltip:
        'Pravidlo může být použito pro naceňování produktů, ale je navrženo ke smazání.',
      usedRule: 'Aktuální',
      draftRule: 'Návrh',
      newRule: 'Nové',
    },
  },
  login: {
    title: 'Přihlášení',
    username: 'Jméno',
    password: 'Heslo',
    remember: 'Zapamatovat',
    button: 'Přihlásit',
    validations: {
      username: 'Prosím vyplňte přihlašovací jméno!',
      password: 'Prosím vyplňte přihlašovací heslo!',
    },
  },
  pricing_rules: {
    table: {
      titles: {
        id: 'ID',
        name: 'Název',
        suppliers: 'Nakladatel',
        categories: 'Sortiment',
        parameters: 'Parametry',
        valid_from: 'Platné od',
        valid_to: 'Platné do',
        active: 'Aktivní',
        state: 'Stav',
      },
    },
    detail: {
      title: 'Slevové pravidlo',
      name: 'Název',
      active: 'Aktivní',
      valid_from: 'Platné od',
      valid_to: 'Platné do',
      category_id: 'ID sortimentu',
      category_name: 'Sortiment',
      supplier_id: 'ID nakladatele',
      supplier_name: 'Nakladatel',
      parameter_id: 'ID parametru',
      parameter_name: 'Parametr',
      priority: 'Priorita',
      priority_tooltip: 'Vyšší priorita má přednost.',
      add_an_interval: 'přidat interval',
      price_from: 'Cena od',
      price_to: 'Cena do',
      eval_expression: 'Pravidlo',
      add_a_parameter: 'přidat parametr',
      create_a_new_param_group: 'Vytvořit novou skupinu parametrů',
      supplier: 'Nakladatel',
      category: 'Sortiment',
      param: 'Parametr',
      record_count: '{actual}. z {total} skupin parametrů ',
    },
  },
  min_margins: {
    table: {
      titles: {
        id: 'ID',
        category: 'Sortiment',
        purchase_price_from: 'Nákupní cena od',
        purchase_price_to: 'Nákupní cena do',
        min_margin: 'Minimální marže',
        state: 'Stav',
      },
    },
    detail: {
      title: 'Minimální marže',
      category_id: 'ID sortimentu',
      category_name: 'Sortiment',
      purchase_price_from: 'Nákupní cena od',
      purchase_price_to: 'Nákupní cena do',
      min_margin: 'Minimální marže',
    },
  },
  max_discounts: {
    table: {
      titles: {
        id: 'ID',
        productId: 'ID produktu',
        ean: 'EAN',
        type: 'Typ',
        parameter: 'Parametr',
        valid_from: 'Platné od',
        valid_to: 'Platné do',
        max_discount: 'Maximální sleva',
        supplier: 'Nakladatel',
        category: 'Sortiment',
        state: 'Stav',
      },
    },
    detail: {
      title: 'Maximální sleva',
      type: 'Typ',
      category_id: 'ID sortimentu',
      category_name: 'Sortiment',
      supplier_id: 'ID nakladatele',
      supplier_name: 'Nakladatel',
      parameter_id: 'ID parametru',
      parameter_name: 'Parametr',
      supplier: 'Nakladatel',
      parameter: 'Parametr',
    },
  },
  product_exceptions: {
    search_placeholder: 'Číslo importu',
    import_csv: 'Nahrát CSV',
    select_all: 'Označit všechny podle čísla importu',
    delete_selected: 'Smazat vybrané',
    confirm_selected: 'Potvrdit vybrané',
    mark_delete_selected: 'Označit vybrané ke smazání',
    import_tooltip: 'Kliknutím zobrazíte informace o formátu CSV souboru...',
    import_hint_title: 'Příklad CSV souboru',
    import_hint_text:
      'Sloupce jsou v CSV souboru odděleny středníkem. CSV soubor musí splňovat následující formát:',
    import_hint_text_example: 'Níže najdete příklad CSV souboru:',
    import_hint_text_csv: `product_id;ean;evaluation_expression;active;valid_from;valid_to;param_id;param_id;param_id\n
      product123;9788086938998;pPRICE*1.21;TRUE;2021-03-13;2022-03-13;param001;param002;param003\n`,
    import_hint_text_csv2: `product_id: id produktu. Nepovinné s podmínkou alespoň jeden atribut z product_id, ean nebo param_id musí být vyplněn.<br />
      ean: ean produktu.  Nepovinné s podmínkou alespoň jeden atribut z product_id, ean nebo param_id musí být vyplněn.<br />
      evaluation_expression: pravidlo pro nacenění produktu. Může být použita jedna z následujících možností: <br />
      <ul>
      <li>číselná hodnota (například: 129)</li>
<li>vypočítaná cena podle</li>
<ul>
<li>DPC - doporučené prodejní cena produktu (například DPC+13)</li>
<li>MOC - Maloobchodní prodejní cena produktu (například MOC*0.9)</li>
<li>pPRICE - Nižší hodnota z DPC a MOC (například pPRICE-1)</li>
<li>bPRICE - Nákupní cena produktu (bPRICE*1.1)</li>
</ul>
</ul>    
      active: TRUE/FALSE<br />
      valid_from: YYYY-MM-DD (například: 2021-03-13)<br />
      valid_to: YYYY-MM-DD (například: 2021-03-13)<br />
      param_id: id parametru. V případě použití více parametrů se může vyskytovat vícekrát v ramci CSV souboru. Nepovinné s podmínkou alespoň jeden atribut z product_id, ean nebo param_id musí být vyplněn.`,
    import_hint_link:
      'Příklad CSV souboru ke stažení <a href="{link}" target="_blank">zde</a>.',
    table: {
      titles: {
        id: 'ID',
        productId: 'ID produktu',
        ean: 'EAN',
        eval_expression: 'Pravidlo',
        parameters: 'Parametry',
        valid_from: 'Platné od',
        valid_to: 'Platné do',
        batch: 'Číslo importu',
        active: 'Aktivní',
        state: 'Stav',
      },
    },
    detail: {
      title: 'Produktová výjimka',
      params: {
        param_id: 'ID parametru',
        param_name: 'Parametr',
        param: 'Parametr',
      },
    },
  },
  top_n_categories: {
    table: {
      titles: {
        id: 'ID',
        category: 'Sortiment',
        priority: 'Priorita',
        price_from: 'Cena od',
        price_to: 'Cena do',
        top_n: 'Top N umístění',
        top_n_expression: 'Top N pravidlo',
        rounding: 'Zaokrouhlení',
        compared_shops_ids: 'ID porovnávaných eshopů',
        state: 'Stav',
      },
    },
    detail: {
      title: 'TOP N pravidlo',
      category: 'Sortiment',
      price_from: 'Cena od',
      price_to: 'Cena do',
      top_n: 'Top N umístění',
      top_n_expression: 'Top N pravidlo',
      top_n_expression_choice: {
        sPRICE: 'Konkurence',
        pPRICE: 'Po slevě',
        sPRICE_help: 'Nejbližší cena konkurence',
        pPRICE_help: 'Cena s již započítanou slevou',
      },
      rounding: 'Zaokrouhlení',
      priority: 'Priorita',
      compared_shops: 'Porovnávané eshopy',
      heureka_shop: 'Heureka eshop',
      heureka_shop_id: 'ID Heureka eshopu',
      heureka_shop_name: 'Heureka eshop',
    },
  },
  dashboard_products: {
    button_recalculate: 'Přepočítat ceny návrhů',
    button_recalculate_actual: 'Přepočítat aktualní ceny',
    button_confirm_all: 'Potvrdit všechny návrhy',
    confirm_recalculate:
      'Opravdu chcete spustit přepočítaní cen všech produktů?',
    confirm_changes:
      'Opravdu chcete potvrdit všechny návrhy připravených pravidel?',
    message_calculating: 'Probíhá přepočet cen produktů...',
    message_products_errors:
      'U některých produktů se nepodařilo spočítat cenu, více informací u detailu produktu.',
    message_calculation_error:
      'Při posledním spuštění přepočítání cen došlo k neočekávané chybě.',
    table: {
      titles: {
        id: 'ID produktu',
        ean: 'EAN',
        title: 'Název',
        supplier: 'Nakladatel',
        category: 'Sortiment',
        parameters: 'Parametry',
        basePrice: 'Základní cena',
        actualPrice: 'Současná cena',
        actualPriceDiscount: 'Sleva současné ceny',
        newPrice: 'Nová cena',
        newPriceDiscount: 'Sleva nové ceny',
        change: 'Rozdíl',
      },
    },
    detail: {
      formTitle: 'Produkt',
      buttonShowRule: 'Zobraz pravidla',
      id: 'ID produktu',
      ean: 'EAN',
      title: 'Název',
      supplier: 'Nakladatel',
      category: 'Sortiment',
      parameters: 'Parametry',
      skipTopN: 'Vynechat TOP N',
      skipMinMargin: 'Vynechat min. marži',
      skipMaxDiscount: 'Vynechat max. slevu',
      rrp: 'DPC',
      rsp: 'MOC',
      sellingPriceRate: 'Konverze',
      rounding: 'Zaokrouhlování (počet míst)',
      purchasePrice: 'Nákupní cena',
      basePrice: 'Základní cena',
      actualPrice: 'Současná cena',
      actualPriceDiscount: 'Sleva současné ceny',
      newPrice: 'Nová cena',
      newPriceDiscount: 'Sleva nové ceny',
      actualError: 'Chyba současného necenění',
      newError: 'Chyba nového nacenění',
      priced: 'Čas nacenění',
    },
    rulesDetail: {
      title: 'Pravidla',
      step: 'Krok výpočtu (pravidlo)',
      actualLabel: 'Současná cena',
      newLabel: 'Nová cena',
      rule: 'Id pravidla',
      result: 'Výsledek',
      algorithmSteps: {
        PRODUCT_EXCEPTIONS: 'Produktová výjimka',
        DISCOUNT_RULES: 'Slevové pravidlo',
        TOP_N: 'Top N',
        MIN_MARGIN: 'Minimální marže',
        MAX_DISCOUNT: 'Maximální sleva',
      },
    },
  },
  logout: 'Odhlášení',
  validations: {
    required: 'Povinné pole',
  },
  dashboard_reports: {
    by_category: 'Dle sortimentu',
    by_supplier: 'Dle nakladatele',
    category: 'Sortiment',
    number_of_titles: 'Počet produktů',
    number_of_titles_pending: 'Počet produktů s plánovanou novou cenou',
    supplier: 'Nakladatel',
    sum_title:
      'Celkový počet produktů s plánovanou novou cenou je {sumPending} z celkového počtu {sum} produktů.',
  },
  enums: {
    rounding: {
      NORMAL: 'Aritmetické',
      UP: 'Nahoru',
      DOWN: 'Dolů',
      DOWN9: 'Dolů na 9',
    },

    expressions: {
      ABS: 'Hodnota',
      DPC: 'DPC',
      MOC: 'MOC',
      pPRICE: 'Základní cena',
      bPRICE: 'Nákupní',
      ABS_help: ' ',
      DPC_help: 'Doporučená prodejní cena',
      MOC_help: 'Maloobchodní cena',
      pPRICE_help: 'Nižší cena z páru MOC a DPC',
      bPRICE_help: 'Nákupní cena',
    },
  },
};
