import React, { forwardRef, useEffect, useState } from 'react';
import { AutoComplete, InputNumber, Select } from 'antd';
import { useIntl } from 'react-intl';
import { SelectValue } from 'antd/es/select';
import { parseExpressionValue } from '../utils/DataManagement';

export interface Props {
  value?: string;
  onChange?: Function;
  disabled?: boolean;
  onChangeToParent?: (value: string) => void;
  expressionData?: { id: string; text: string; help: string }[];
}

const EvaluationExpressionInput = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled, onChangeToParent, expressionData }, ref) => {
    const { Option } = AutoComplete;
    const { formatMessage } = useIntl();

    const expressionBaseData: {
      id: string;
      text: string;
      help: string;
    }[] = expressionData || [
      {
        id: '',
        text: formatMessage({ id: `enums.expressions.ABS` }),
        help: formatMessage({ id: `enums.expressions.ABS_help` }),
      },
      {
        id: 'DPC',
        text: formatMessage({ id: `enums.expressions.DPC` }),
        help: formatMessage({ id: `enums.expressions.DPC_help` }),
      },
      {
        id: 'MOC',
        text: formatMessage({ id: `enums.expressions.MOC` }),
        help: formatMessage({ id: `enums.expressions.MOC_help` }),
      },
      {
        id: 'pPRICE',
        text: formatMessage({ id: `enums.expressions.pPRICE` }),
        help: formatMessage({ id: `enums.expressions.pPRICE_help` }),
      },
      {
        id: 'bPRICE',
        text: formatMessage({ id: `enums.expressions.bPRICE` }),
        help: formatMessage({ id: `enums.expressions.bPRICE_help` }),
      },
    ];
    const operatorsData: string[] = ['+', '-', '*'];

    const parsedExpression = parseExpressionValue(value);

    const [expressionBase, setExpressionBase] = useState(parsedExpression.base);
    const [operator, setOperator] = useState(parsedExpression.operator);
    const [numberValue, setNumberValue] = useState<number | undefined>(
      parsedExpression.numberValue,
    );
    const [, setEvalExpression] = useState(value || '');
    const [helpText, setHelpText] = useState(value || '');

    useEffect(() => {
      const expression = parseExpressionValue(value);

      setExpressionBase(
        expression.base ? expression.base : expressionBaseData[0].id,
      );
      setOperator(expression.operator);
      setNumberValue(expression.numberValue);
      setEvalExpression(value || '');

      const expData = expressionBaseData.find(d => d.id === expression.base);
      setHelpText(
        `${expData?.help ?? ''}${expression.operator ??
          ''}${expression.numberValue ?? ''}`,
      );
    }, [value, expressionBaseData]);

    const onInputChange = (
      val: string | number | undefined,
      type: 'expressionBase' | 'operator' | 'numberValue',
    ) => {
      const evalExpressionObj = {
        expressionBase,
        operator,
        numberValue: numberValue || '',
      };
      evalExpressionObj[type] = val as string;

      switch (type) {
        case 'expressionBase': {
          setExpressionBase(val as string);
          if (!val) {
            setOperator('');
            evalExpressionObj.operator = '';
          }
          break;
        }
        case 'numberValue':
          setNumberValue(val as number);
          break;
        case 'operator':
          setOperator(val as string);
          break;
        default:
          break;
      }

      setEvalExpression(
        `${evalExpressionObj.expressionBase}${evalExpressionObj.operator}${evalExpressionObj.numberValue}`,
      );

      const expData = expressionBaseData.find(
        d => d.id === evalExpressionObj.expressionBase,
      );
      setHelpText(
        `${expData?.help ?? ''}${evalExpressionObj.operator ??
          ''}${evalExpressionObj.numberValue ?? ''}`,
      );

      if (onChange) {
        onChange(
          `${evalExpressionObj.expressionBase}${evalExpressionObj.operator}${evalExpressionObj.numberValue}`,
        );
      }
      if (onChangeToParent) {
        onChangeToParent(
          `${evalExpressionObj.expressionBase}${evalExpressionObj.operator}${evalExpressionObj.numberValue}`,
        );
      }
    };

    return (
      <div ref={ref}>
        <Select
          style={{ width: '110px' }}
          dropdownMatchSelectWidth={false}
          disabled={disabled}
          value={expressionBase}
          onChange={(val: SelectValue) =>
            onInputChange(val as string, 'expressionBase')
          }
        >
          {expressionBaseData.map(data => (
            <Option key={`${data.id}`} value={`${data.id}`}>
              {data.text}
            </Option>
          ))}
        </Select>
        {expressionBase && (
          <Select
            disabled={disabled}
            style={{ width: '47px' }}
            value={operator}
            onChange={(val: SelectValue) =>
              onInputChange(val as string, 'operator')
            }
          >
            {operatorsData.map(data => (
              <Option key={`${data}`} value={`${data}`}>
                {data}
              </Option>
            ))}
          </Select>
        )}
        <InputNumber
          style={{ width: '72px' }}
          disabled={disabled}
          step={0.01}
          min={0}
          max={99999999}
          value={numberValue}
          onChange={val => onInputChange(val, 'numberValue')}
        />
        <div
          className='ant-form-explain'
          style={{ width: '240px', position: 'absolute' }}
        >
          {helpText}
        </div>
      </div>
    );
  },
);

export default EvaluationExpressionInput;
