import {
  BusinessResultEntity,
  DashboardProductResponse,
  DashboardReportRow,
  IdAndNameType,
  MaxDiscount,
  MaxDiscountListItem,
  MinMargin,
  MinMarginListItem,
  PricingRule,
  ProductDetail,
  ProductException,
  ProductExceptionsListItem,
  RuleStates,
  TopNCategory,
  TopNCategoryListItem,
  UserResponse,
} from './types';

function generateState(id: number): RuleStates {
  switch (id) {
    case 1:
      return RuleStates.DRAFT;
    case 2:
      return RuleStates.FINAL;
    case 3:
      return RuleStates.TO_DELETE;
    case 4:
      return RuleStates.LOCKED;
    default:
      return RuleStates.FINAL;
  }
}

export const productExceptionsListData: ProductExceptionsListItem[] = [
  1,
  2,
  3,
  4,
  5,
].map(v => ({
  id: v,
  state: generateState(v),
  productId: '234344323',
  ean: '658567476765',
  evaluationExpression: `pPRICE*${v}`,
  parameters: [
    {
      name: 'NOVINKA',
      id: 'PAR3455',
    },
    {
      name: 'BEST SELLER',
      id: 'PAR34535',
    },
    {
      name: 'VYPRODEJ',
      id: 'PAR3455s',
    },
  ],
  validFrom: '2020-01-01',
  validTo: '2020-01-12',
  batchNumber: 17,
  active: true,
}));

export const productExceptionsData: ProductException = {
  id: 2,
  state: RuleStates.DRAFT,
  productId: '234344323',
  ean: '658567476765',
  evaluationExpression: `pPRICE+45`,
  parameters: [
    {
      name: 'NOVINKA',
      id: 'PAR3455',
    },
    {
      name: 'BEST SELLER',
      id: 'PAR34535',
    },
    {
      name: 'VYPRODEJ',
      id: 'PAR3455s',
    },
  ],
  validFrom: '2020-01-01',
  validTo: '2020-01-12',
  batchNumber: 17,
  active: true,
};

export const userData: UserResponse = {
  id: 1,
  login: 'ferdam',
  name: 'Ferda Mravenec',
};

export const maxDiscountsListData: MaxDiscountListItem[] = [1, 2, 3, 4, 5].map(
  v => ({
    id: v,
    state: generateState(v),
    category: { id: 'CATID1', name: 'NAME1' },
    type: v % 2 === 0 ? 'PARAMETER' : 'PRODUCT',
    supplier: { id: 'SUPID1', name: 'NAME1' },
    maxDiscount: v * 10,
    productId: '234344323',
    ean: '658567476765',
    parameter: { id: 'PARID1', name: 'NAME1' },
    validFrom: '2020-01-01',
    validTo: '2020-01-12',
  }),
);

export const maxDiscountsData: MaxDiscount = {
  id: 1,
  state: RuleStates.FINAL,
  category: { id: 'CATID1', name: 'NAME1' },
  type: 'PRODUCT',
  supplier: { id: 'SUPID1', name: 'NAME1' },
  maxDiscount: 30,
  productId: '234344323',
  ean: '658567476765',
  parameter: { id: 'PARID1', name: 'NAME1' },
  validFrom: '2020-01-01',
  validTo: '2020-01-12',
};

export const minMarginsListData: MinMarginListItem[] = [1, 2, 3, 4, 5].map(
  v => ({
    id: v,
    state: generateState(v),
    category: { id: 'CATID1', name: 'NAME1' },
    minMargin: v * 10,
    purchasePriceTo: 5 * v,
    purchasePriceFrom: v,
  }),
);

export const minMarginsData: MinMargin = {
  id: 12,
  state: RuleStates.DRAFT,
  category: { id: 'CATID1', name: 'NAME1' },
  minMargin: 10,
  purchasePriceTo: 70,
  purchasePriceFrom: 5,
};

export const pricingRulesListData: PricingRule[] = [1, 2, 3, 4, 5].map(v => ({
  id: v,
  name: `Sortiment ${v}`,
  state: generateState(v),
  parentRuleId: v === 1 ? 4 : undefined,
  validFrom: '2020-01-01',
  validTo: '2020-01-12',
  active: v % 2 === 0,
  suppliers: [
    { id: '1', name: 'GRADA' },
    { id: '2', name: 'DEFAULT' },
    { id: '11', name: 'SUPPLIER 1' },
    { id: '22', name: 'SUPPLIER 2' },
    { id: '111', name: 'SUPPLIER 3' },
  ],
  categories: [
    { id: '1', name: 'KNIHY' },
    { id: '2', name: 'DEFAULT' },
    { id: '11', name: 'KNIHY 1' },
    { id: '21', name: 'DEFAULT 1' },
    { id: '111', name: 'KNIHY 2' },
    { id: '211', name: 'DEFAULT 2' },
  ],
  parameterGroups: [
    {
      name: `Parameter group ${v}`,
      parameters: [
        { id: 'PAR1', name: 'TEST' },
        { id: 'PAR2', name: 'NOVINKA' },
        { id: 'PAR3', name: 'OLD 1' },
        { id: 'PAR11', name: 'TEST 1' },
        { id: 'PAR22', name: 'NOVINKA 2' },
        { id: 'PAR33', name: 'OLD 2' },
        { id: 'PAR333', name: 'OLD 3' },
      ],
      id: 1,
      intervals: [
        { id: 1, priceFrom: 40, evaluationExpression: '' },
        {
          id: 2,
          priceFrom: 0,
          priceTo: 1500,
          evaluationExpression: 'pPRICE*1.21',
        },
        { id: 3, priceFrom: 1500, evaluationExpression: 'pPRICE*1.19' },
      ],
      priority: v,
    },
  ],
}));

export const pricingRulesData: PricingRule = {
  id: 23,
  name: `Sortiment 1`,
  validFrom: '2020-01-01',
  validTo: '2020-01-12',
  state: RuleStates.FINAL,
  active: true,
  suppliers: [
    { id: '1', name: 'GRADA' },
    { id: '2', name: 'DEFAULT' },
    { id: '11', name: 'SUPPLIER 1' },
    { id: '22', name: 'SUPPLIER 2' },
    { id: '111', name: 'SUPPLIER 3' },
    { id: '222', name: 'SUPPLIER 4' },
  ],
  categories: [
    { id: '1', name: 'KNIHY' },
    { id: '2', name: 'DEFAULT' },
    { id: '11', name: 'KNIHY 1' },
    { id: '21', name: 'DEFAULT 1' },
    { id: '211', name: 'DEFAULT 2' },
  ],
  parameterGroups: [
    {
      name: `Parameter group 1`,
      parameters: [
        { id: 'PAR1', name: 'TEST' },
        { id: 'PAR2', name: 'NOVINKA' },
        { id: 'PAR3', name: 'OLD 1' },
        { id: 'PAR11', name: 'TEST 1' },
        { id: 'PAR22', name: 'NOVINKA 2' },
        { id: 'PAR33', name: 'OLD 2' },
        { id: 'PAR333', name: 'OLD 3' },
      ],
      id: 1,
      intervals: [
        { id: 1, priceFrom: 40, evaluationExpression: '' },
        {
          id: 2,
          priceFrom: 0,
          priceTo: 1500,
          evaluationExpression: 'pPRICE*1.21',
        },
        { id: 3, priceFrom: 1500, evaluationExpression: 'pPRICE*1.19' },
      ],
      priority: 2,
    },
  ],
};

export const businessParametersData: BusinessResultEntity = {
  data: [1, 2, 3, 4, 5].map(v => ({
    id: `PARID${v}`,
    name: `NAME${v}`,
  })),
  total: 5,
};

export const businessSuppliersData: BusinessResultEntity = {
  data: [1, 2, 3, 4, 5].map(v => ({
    id: `SUPID${v}`,
    name: `NAME${v}`,
  })),
  total: 5,
};

export const businessCategoriesData: BusinessResultEntity = {
  data: [1, 2, 3, 4, 5].map(v => ({
    id: `CATID${v}`,
    name: `NAME${v}`,
  })),
  total: 5,
};

export const businessHeurekaShopsData: IdAndNameType[] = [
  { id: '10233', name: 'Maxíkovy hračky' },
  { id: '37983', name: 'Knihy Dobrovský' },
  { id: '14122', name: 'knihy.cz' },
  { id: '17501', name: 'MegaKnihy.cz' },
  { id: '24511', name: 'Luxor.cz' },
  { id: '3001', name: 'Dobré knihy' },
  { id: '5166', name: 'NEJLEVNĚJŠÍ KNIHY' },
  { id: '3855', name: 'SEVT' },
  { id: '10599', name: 'LIBRUM.CZ' },
  { id: '25761', name: 'Martinus.cz' },
  { id: '4127', name: 'MegaHračky.cz' },
  { id: '11141', name: 'KNIHCENTRUM.CZ' },
  { id: '7270', name: 'Booktook.cz' },
  { id: '280', name: 'MALL.CZ' },
  { id: '868', name: 'Kosmas.cz' },
  { id: '61186', name: 'Knihkupectví Ládví' },
];

export const topNCategoriesListData: TopNCategoryListItem[] = [
  1,
  2,
  3,
  4,
  5,
].map(v => ({
  id: v,
  state: generateState(v),
  category: { id: 'CATID1', name: 'NAME1' },
  roudingType: v % 2 === 0 ? 'NORMAL' : 'DOWN',
  topNExpression: `sPrice-${v}`,
  topN: v,
  priority: 5,
  priceFrom: 5 * v,
  priceTo: 100 * v,
  comparedShops: [
    { id: '5166', name: 'BOOKS4YOU.CZ' },
    { id: '24511', name: 'Luxor.cz' },
  ],
}));

export const topNCategoryData: TopNCategory = {
  id: 1,
  state: RuleStates.DRAFT,
  category: { id: 'CATID1', name: 'NAME1' },
  roudingType: 'NORMAL',
  topNExpression: `sPrice-1`,
  topN: 1,
  priceFrom: 5,
  priceTo: 1000,
  priority: 5,
  comparedShops: [
    { id: '5166', name: 'BOOKS4YOU.CZ' },
    { id: '24511', name: 'Luxor.cz' },
  ],
};

export const reportByCategoryListData: DashboardReportRow[] = [
  1,
  2,
  3,
  4,
  5,
].map(v => ({
  id: `CATID${v}`,
  name: `BOOKS${v}`,
  noOfTitles: v * 10,
  noOfTitlesPendingPrice: v * 4,
  subRows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(vSub => ({
    id: `SUPPID${vSub}`,
    name: `SUPPLIER${vSub}`,
    noOfTitles: vSub * 12,
    noOfTitlesPendingPrice: vSub * 6,
  })),
}));

export const reportBySupplierListData: DashboardReportRow[] = [
  1,
  2,
  3,
  4,
  5,
].map(v => ({
  id: `SUPPID${v}`,
  name: `SUPPLIER${v}`,
  noOfTitles: v * 101,
  noOfTitlesPendingPrice: v * 42,
  subRows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(vSub => ({
    id: `CATID${vSub}`,
    name: `BOOKS${vSub}`,
    noOfTitles: vSub * 12,
    noOfTitlesPendingPrice: vSub * 6,
  })),
}));

export const dashboardProductResponse: DashboardProductResponse = {
  data: [
    {
      id: 113,
      productId: '11133',
      ean: '894456451688',
      productDescription: 'Java for dummies',
      supplier: { id: 'GRADA', name: 'Grada' },
      category: { id: 'FTS', name: 'Fantasy' },
      parameters: [
        { id: 'PAR1', name: 'TEST' },
        { id: 'PAR2', name: 'NOVINKA' },
        { id: 'PAR3', name: 'OLD 1' },
      ],
      basePrice: 150,
      actualPrice: 125,
      actualPriceDiscount: 25,
      newPrice: 100,
      newPriceDiscount: 50,
      change: 25,
      pricingError: false,
    },
    {
      id: 123,
      productId: '1233',
      ean: '892111151688',
      productDescription: 'Smrt obchodního cestujícího',
      supplier: { id: 'GRADA', name: 'Grada' },
      category: { id: 'KH', name: 'KNIHY' },
      parameters: [],
      basePrice: 350,
      actualPrice: 350,
      actualPriceDiscount: 0,
      newPrice: 340,
      newPriceDiscount: 10,
      change: 10,
      pricingError: true,
    },
    {
      id: 33,
      productId: '33333',
      ean: '1112223333444',
      productDescription: 'Kytice',
      supplier: { id: 'alb', name: 'Albatros' },
      category: { id: 'FTS', name: 'Fantasy' },
      parameters: [{ id: 'PAR5', name: 'SLEVA' }],
      basePrice: 150,
      actualPrice: 140,
      actualPriceDiscount: 10,
      newPrice: 145,
      newPriceDiscount: 5,
      change: -5,
      pricingError: false,
    },
  ],
  page: {
    total: 222,
    pageSize: 10,
    page: 1,
    sortField: 'id',
    sortDirection: 'ASC',
  },
};

export const productDetail: ProductDetail = {
  id: 113,
  productId: '113333',
  ean: '894456451688',
  productDescription: 'Java for dummies',
  supplier: { id: 'GRADA', name: 'Grada' },
  category: { id: 'FTS', name: 'Fantasy' },
  parameters: [
    { id: 'PAR1', name: 'TEST' },
    { id: 'PAR2', name: 'NOVINKA' },
    { id: 'PAR3', name: 'OLD 1' },
  ],
  skipTopN: true,
  skipMinMargin: false,
  skipMaxDiscount: true,
  purchasePrice: 12,
  purchasePriceCurrency: 'CZK',
  rrp: 12,
  rsp: 5.5,
  sellingPriceRate: 66,
  sellingPriceCurrency: 'CZK',
  rounding: 2,
  basePrice: 150,
  actualPrice: 125,
  actualPriceDiscount: 25,
  newPrice: 100,
  newPriceDiscount: 50,
  change: 0,
  pricingError: false,
  finalPricingTime: '2021-04-14T13:58:05.688798+02:00',
  draftPricingError: 'No rule found',
  rules: [
    {
      step: 'Product exceptions',
    },
    {
      step: 'Discount rule',
      actualRule: 'RULE 1 (12)',
      actualResult: 11,
      newRule: 'RULE 1 (13)',
      newResult: 44,
    },
    {
      step: 'Top N',
      actualRule: '6',
      actualResult: 1,
      newRule: '6',
      newResult: 4,
    },
    {
      step: 'Minimal margin',
    },
    {
      step: 'Max discount',
    },
  ],
};
