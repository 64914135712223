import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { StoreModel } from './types';
import { minMarginsApi } from '../api';
import { MinMargin, MinMarginListItem, RuleStates } from '../api/types';

export const defaultMinMargin: MinMargin = {
  id: -1,
  state: RuleStates.DRAFT,
  minMargin: 0,
  category: { id: '', name: '' },
  purchasePriceFrom: 0,
  purchasePriceTo: 0,
};

export interface MinMarginsStore {
  isLoading: boolean;
  list: OrderedMap<number, MinMarginListItem>;
  detail: MinMargin;
  selectedDetailId: number;
  setSelectedDetailId: Action<MinMarginsStore, number>;
  setLoading: Action<MinMarginsStore, boolean>;
  setList: Action<MinMarginsStore, MinMarginListItem[]>;
  setDetail: Action<MinMarginsStore, MinMargin>;
  fetchList: Thunk<MinMarginsStore, void, any, StoreModel>;
  fetchMinMargin: Thunk<MinMarginsStore,
    number,
    any,
    StoreModel,
    Promise<boolean>>;
  createMinMargin: Thunk<MinMarginsStore,
    MinMargin,
    any,
    StoreModel,
    Promise<boolean>>;
  updateMinMargin: Thunk<MinMarginsStore,
    MinMargin,
    any,
    StoreModel,
    Promise<boolean>>;
  deleteMinMargin: Thunk<MinMarginsStore,
    number,
    any,
    StoreModel,
    Promise<boolean>>;
}

const MinMargins: MinMarginsStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultMinMargin,
  selectedDetailId: -1,
  setSelectedDetailId: action((state, payload) => {
    state.selectedDetailId = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [v.id, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await minMarginsApi.getMinMarginsList();
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchMinMargin: thunk(async (actions, id) => {
    try {
      const { data } = await minMarginsApi.getMinMarginDetail(id);
      actions.setDetail(data);
    } catch (error) {
      return false;
    }
    return true;
  }),
  createMinMargin: thunk(async (actions, value) => {
    try {
      await minMarginsApi.createMinMargin(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updateMinMargin: thunk(async (actions, value) => {
    try {
      await minMarginsApi.updateMinMargin(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  deleteMinMargin: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await minMarginsApi.deleteMinMargin(value);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default MinMargins;
