import React from 'react';
import { Icon } from 'antd';
import { useIntl } from 'react-intl';
import { RuleStates } from '../api/types';

interface Props {
  ruleState: RuleStates;
}

const RuleStateCell: React.FC<Props> = ({ ruleState }) => {
  const { formatMessage } = useIntl();

  let stateClass: string;
  let icon: string;
  switch (ruleState) {
    case RuleStates.DRAFT:
      stateClass = 'rule-state-draft';
      icon = 'form';
      break;
    case RuleStates.FINAL:
      stateClass = 'rule-state-final';
      icon = 'check-circle';
      break;
    case RuleStates.LOCKED:
      stateClass = 'rule-state-locked';
      icon = 'lock';
      break;
    case RuleStates.TO_DELETE:
      stateClass = 'rule-state-to-delete';
      icon = 'close-circle';
      break;
    default:
      stateClass = '';
      icon = '';
      break;
  }

  const getText = (state: RuleStates) => {
    if (state === RuleStates.DRAFT) {
      return formatMessage({ id: 'common.ruleStates.draftRule' });
    }

    return formatMessage({ id: 'common.ruleStates.usedRule' });
  };

  return (
    <span className={stateClass} title={formatMessage({ id: `common.ruleStates.${ruleState}_tooltip` })}>
      <Icon type={icon} />
      {getText(ruleState)}
    </span>
  );
};

export default RuleStateCell;
