import React, { useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Switch, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { IdAndNameType, PricingRule } from '../api/types';
import { Mode } from '../store/types';
import { addFieldExternalId, addFieldExternalIdToParamGroup, transformFormData } from '../utils/DataManagement';
import { dateFormat } from './PEForm';
import NewButton from './NewButton';
import SupplierInputs, { LIST_PAGE_ITEMS_COUNT } from './SupplierInputs';
import CategoryInputs from './CategoryInputs';
import ParametersGroupInputs from './ParametersGroupInputs';

interface Props extends FormComponentProps<PricingRule> {
  data: PricingRule;
  mode: Mode;
}

const PricingRuleForm: React.FC<Props> = ({
                                            form, mode, data,
                                          }) => {
  const isView = mode === Mode.VIEW;
  const { formatMessage } = useIntl();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [currentPageSuppliers, setCurrentPageSuppliers] = useState(1);
  const [currentPageCategories, setCurrentPageCategories] = useState(1);
  const suppliers: IdAndNameType[] = getFieldValue('suppliers');
  const addSupplier = () => {
    setFieldsValue({
      suppliers: [...suppliers, { id: '', name: '', externalId: '' }],
    });
    setCurrentPageSuppliers(Math.ceil((suppliers.length + 1) / LIST_PAGE_ITEMS_COUNT));
  };
  const categories: IdAndNameType[] = getFieldValue('categories');
  const addCategory = () => {
    setFieldsValue({
      categories: [...categories, { id: '', name: '', externalId: '' }],
    });
    setCurrentPageCategories(Math.ceil((categories.length + 1) / LIST_PAGE_ITEMS_COUNT));
  };

  getFieldDecorator('id');

  return (
    <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} className='pup-form'>
      <Row gutter={16} type='flex' justify='space-around' align='middle'>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id='pricing_rules.detail.name' />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id='validations.required' />,
                },
              ],
            })(<Input disabled={isView} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='product_exceptions.table.titles.valid_from' />
            }
          >
            {getFieldDecorator('validFromM', {
              initialValue:
                data.validFrom != null &&
                data.validFrom &&
                data.validFrom !== ''
                  ? moment(data.validFrom, dateFormat)
                  : null,
            })(
              <DatePicker
                format={dateFormat}
                disabled={isView}
                placeholder={formatMessage({ id: 'common.date_mask' })}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={<FormattedMessage id='pricing_rules.detail.active' />}>
            {getFieldDecorator('active', {
              valuePropName: 'checked',
            })(<Switch disabled={isView} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage id='product_exceptions.table.titles.valid_to' />
            }
          >
            {getFieldDecorator('validToM', {
              initialValue:
                data.validTo != null && data.validTo && data.validTo !== ''
                  ? moment(data.validTo, dateFormat)
                  : null,
            })(
              <DatePicker
                format={dateFormat}
                disabled={isView}
                placeholder={formatMessage({ id: 'common.date_mask' })}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type='flex' justify='space-around' align='middle'>
        <Tabs defaultActiveKey='1' style={{ width: '100%', marginTop: '-17px' }}>
          <Tabs.TabPane
            tab={<FormattedMessage id='pricing_rules.table.titles.categories' />}
            key='1'
          >
            <Row gutter={16} justify='space-around' align='middle'>
              <Col span={32}>
                {!isView && <NewButton onClick={addCategory} />}
                {getFieldDecorator('categories')(
                  <CategoryInputs disabled={isView} forcedPage={currentPageCategories} />,
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<FormattedMessage id='pricing_rules.table.titles.suppliers' />}
            key='2'
          >
            <Row gutter={16} justify='space-around' align='middle'>
              <Col span={32}>
                {!isView && <NewButton onClick={addSupplier} />}
                {getFieldDecorator('suppliers')(
                  <SupplierInputs disabled={isView} forcedPage={currentPageSuppliers} />,
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<FormattedMessage id='pricing_rules.table.titles.parameters' />}
            key='3'
          >
            <Row gutter={16} justify='space-around' align='middle'>
              <Col span={32}>
                {getFieldDecorator('parameterGroups')(
                  <ParametersGroupInputs disabled={isView} />,
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({
  mapPropsToFields: ({
                       data: {
                         validFrom, validTo, suppliers,
                         categories, parameterGroups, ...rest
                       },
                     }) =>
    transformFormData({
      validFromM:
        validFrom != null && validFrom && validFrom !== ''
          ? moment(validFrom, dateFormat)
          : null,
      validToM:
        validTo != null && validTo && validTo !== ''
          ? moment(validTo, dateFormat)
          : null,
      suppliers: addFieldExternalId(suppliers),
      categories: addFieldExternalId(categories),
      parameterGroups: addFieldExternalIdToParamGroup(parameterGroups),
      ...rest,
    }),
})(PricingRuleForm);

