import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Divider, Form, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';
import { debounce } from '../utils/DataManagement';
import { GENERAL_PAGE_SIZE } from '../store/general';

export interface Props {
  value?: string;
  onChange?: Function;
  disabled?: boolean;
  isFilter?: boolean;
}

const ParameterInput = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled, isFilter = false }, ref) => {
    const {
      setSearchParameter,
      fetchParameters,
      fetchParameter,
      setParameter,
    } = useStoreActions(actions => actions.general);

    const loadingParameters = useStoreState(state => state.general.loadingParameters);
    const parameter = useStoreState(state => state.general.parameter);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>([]);
    const [total, setTotal] = useState(-1);
    const supplierFormItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const handleChange = (index: number, val: LabeledValue) => {
      if (onChange) {
        let valueClone = '';
        if (val) {
          // @ts-ignore
          valueClone = val?.label?.props?.children[0].props.children;
        }

        fetchParameter(valueClone).then((data: IdAndNameType | boolean) => {
          if (data) {
            setParameter(data as IdAndNameType);
          }
          onChange(valueClone);
        });
      }
    };

    const getParameterInValue = (externalId?: string): IdAndNameType => {
      if (!externalId || !parameter) {
        return { id: '', name: '', externalId: '' };
      }

      return { ...parameter, externalId: parameter.id };
    };

    const onSearch = (searchText: string) => {
      setSearchParameter(searchText);

      // if (!searchText || searchText.length < 3) {
      //   setDataSource([]);
      //   setTotal(-1);
      //   return;
      // }

      fetchParameters().then((data: any) => {
        if (data) {
          setDataSource(data.data);
          setTotal(data.total);
        } else {
          setDataSource([]);
          setTotal(-1);
        }
      });
    };

    const onSearchDebounce = debounce(onSearch, 700);

    const onFocus = () => {
      onSearchDebounce('');
    };

    const formatOptionText = (oneValue: IdAndNameType): any => {
      return (
        <>
          <span
            className='text-ellipsis'
            style={{
              float: 'left',
              maxWidth: '130px',
              display: 'inline-block',
            }}
          >
            {oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{
              float: 'right',
              width: '130px',
              maxWidth: '130px',
              textAlign: 'right',
            }}
          >
            {oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div>
          <FormattedMessage id='max_discounts.detail.parameter_id' />
          <span style={{ float: 'right' }}>
            {' '}
            <FormattedMessage id='pricing_rules.detail.parameter_name' />
          </span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPPAM' label={renderGroupTitle()}>
        {dataSource.map(data => (
          <Option key={`${data.id}`} value={`${data.id} - ${data.name}`}>
            {formatOptionText(data)}
          </Option>
        ))}
      </OptGroup>,
    ];

    return (
      <div ref={ref}>
        <Row
          type='flex'
          align='middle'
          justify='space-around'
          className='minimal-size'
        >
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id='max_discounts.detail.parameter' />}
              required={!isFilter}
              // help={<FormattedMessage id='common.combo_search_placeholder' />}
              {...supplierFormItemLayout}
            >
              <Select
                showSearch
                allowClear={isFilter && !!value}
                disabled={disabled}
                labelInValue
                filterOption={false}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                onChange={(val: LabeledValue) => handleChange(0, val)}
                dropdownStyle={{ width: 300 }}
                onSearch={onSearchDebounce}
                value={
                  {
                    key: value || '',
                    label:
                      getParameterInValue(value) &&
                      (getParameterInValue(value).externalId ||
                        getParameterInValue(value).name)
                        ? `${getParameterInValue(value).externalId} - ${
                          getParameterInValue(value).name
                        }`
                        : undefined,
                  } as LabeledValue
                }
                onFocus={() => {
                  onFocus();
                }}
                notFoundContent={null}
                loading={loadingParameters}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    {total > GENERAL_PAGE_SIZE && (
                      <>
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '5px 10px' }}
                          className='ant-form-explain'
                        >
                          <FormattedMessage
                            id='common.combo_search_more_results'
                            values={{ total, limit: GENERAL_PAGE_SIZE }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              >
                {options}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  },
);

export default ParameterInput;
