import React, { useEffect } from 'react';
import { InputNumber } from 'antd';
import { useIntl } from 'react-intl';
import { InputNumberPropsExtended } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';

const PESearchButton: React.FC<InputNumberPropsExtended> = props => {
  const searchValue = useStoreState(
    state => state.productExceptions.searchValue,
  );
  const { formatMessage } = useIntl();
  const { fetchList, setSearchValue } = useStoreActions(
    actions => actions.productExceptions,
  );
  const handleChange = (val?: number) => {
    if (props.selectAll) {
      props.selectAll(false);
    }
    setSearchValue(val);
  };

  useEffect(() => {
    fetchList();
  }, [fetchList, searchValue]);

  return (
    <InputNumber
      placeholder={formatMessage({
        id: 'product_exceptions.search_placeholder',
      })}
      onChange={val => handleChange(val)}
      value={searchValue}
      {...props}
    />
  );
};

export default PESearchButton;
