import React, { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Form, Icon, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType, IdAndNameTypeIn } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';

export interface Props {
  value?: IdAndNameTypeIn[];
  onChange?: Function;
  disabled?: boolean;
}

const HeurekaShopInputs = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const heurekaShopList = useStoreState(
      state => state.general.heurekaShopList,
    );
    const { fetchHeurekaShop } = useStoreActions(actions => actions.general);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>(
      heurekaShopList.valueSeq().toArray(),
    );
    const shopFormItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    const handleChange = (index: number, val: LabeledValue) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          if (val) {
            valueClone[index].externalId =
              // @ts-ignore
              val?.label?.props?.children[0].props.children;
            valueClone[index].name =
              // @ts-ignore
              val?.label?.props?.children[1].props.children;
          }
          onChange(valueClone);
        }
      }
    };

    const handleRemove = (obj: IdAndNameTypeIn) => {
      if (onChange && value) {
        onChange(value.filter(v => v !== obj));
      }
    };

    const onSearch = (searchText: string, searchName?: string) => {
      if (!searchText) {
        fetchHeurekaShop('');

        return;
      }

      const list = heurekaShopList
        .valueSeq()
        .toArray()
        .filter(
          pL =>
            pL.id.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
            pL.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1 ||
            (searchName
              ? pL.name.toUpperCase().indexOf(searchName.toUpperCase()) !== -1
              : false),
        );

      if (list.length) {
        setDataSource(list);
      } else {
        fetchHeurekaShop(searchText);
      }
    };

    const onFocus = () => {
      setDataSource(heurekaShopList.valueSeq().toArray());
    };

    useEffect(() => {
      setDataSource(heurekaShopList.valueSeq().toArray());
    }, [heurekaShopList]);

    const formatOptionText = (oneValue: IdAndNameType): any => {
      return (
        <>
          <span
            className='text-ellipsis'
            style={{
              float: 'left',
              maxWidth: '130px',
              display: 'inline-block',
            }}
          >
            {oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{
              float: 'right',
              width: '130px',
              maxWidth: '130px',
              textAlign: 'right',
            }}
          >
            {oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div>
          <FormattedMessage id='top_n_categories.detail.heureka_shop_id' />
          <span style={{ float: 'right' }}>
            {' '}
            <FormattedMessage id='top_n_categories.detail.heureka_shop_name' />
          </span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPSHOPS' label={renderGroupTitle()}>
        {dataSource.map(data => (
          <Option key={`${data.id}`} value={`${data.id} - ${data.name}`}>
            {formatOptionText(data)}
          </Option>
        ))}
      </OptGroup>,
    ];

    return (
      <div ref={ref}>
        {value
          ? value.map((shop, index) => (
              <Row
                // eslint-disable-next-line react/no-array-index-key
                key={`${shop.id}-${index}`}
                type='flex'
                align='middle'
                justify='space-around'
              >
                <Col span={23}>
                  <Form.Item
                    label={
                      <FormattedMessage id='top_n_categories.detail.heureka_shop' />
                    }
                    required
                    {...shopFormItemLayout}
                  >
                    <Select
                      showSearch
                      disabled={disabled}
                      labelInValue
                      filterOption={false}
                      style={{ width: '100%' }}
                      dropdownMatchSelectWidth={false}
                      onChange={(val: LabeledValue) => handleChange(index, val)}
                      dropdownStyle={{ width: 300 }}
                      onSearch={onSearch}
                      value={
                        {
                          key: shop.externalId,
                          label:
                            shop.externalId || shop.name
                              ? `${shop.externalId} - ${shop.name}`
                              : undefined,
                        } as LabeledValue
                      }
                      onFocus={() => {
                        onFocus();
                      }}
                      notFoundContent={null}
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Col>
                {!disabled && (
                  <Col span={1}>
                    <Icon
                      className='delete-icon'
                      onClick={e => {
                        e.preventDefault();
                        handleRemove(shop);
                      }}
                      type='delete'
                    />
                  </Col>
                )}
              </Row>
            ))
          : null}
      </div>
    );
  },
);

export default HeurekaShopInputs;
