import React, { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutoComplete, Col, Form, Icon, List, Row, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { IdAndNameType, IdAndNameTypeIn } from '../api/types';
import { useStoreActions, useStoreState } from '../store/hooks';

export interface Props {
  value?: IdAndNameTypeIn[];
  onChange?: Function;
  disabled?: boolean;
  forcedPage?: number;
}

const CategoryInputs = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, disabled }, ref) => {
    const { setSearchCategory } = useStoreActions(actions => actions.general);
    const categoriesList = useStoreState(state => state.general.categoriesList);
    const { Option, OptGroup } = AutoComplete;
    const [dataSource, setDataSource] = useState<IdAndNameType[]>(categoriesList.valueSeq().toArray());
    const categoryFormItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    const handleChange = (
      index: number,
      val: LabeledValue,
    ) => {
      if (onChange) {
        if (value && value.length > 0) {
          const valueClone = [...value];
          if (val) {
            // @ts-ignore
            valueClone[index].externalId = val?.label?.props?.children[0].props.children;
            // @ts-ignore
            valueClone[index].name = val?.label?.props?.children[1].props.children;
          }
          onChange(valueClone);
        }
      }
    };

    const handleRemove = (obj: IdAndNameTypeIn) => {
      if (onChange && value) {
        onChange(value.filter(v => v !== obj));
      }
    };

    const onSearch = (searchText: string, searchName?: string) => {
      setSearchCategory(searchText);

      if (!searchText) {
        setDataSource(categoriesList.valueSeq().toArray());
        return;
      }
      setDataSource(categoriesList.valueSeq().toArray().filter(pL =>
        pL.id.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
        || pL.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1
        || (searchName ? (pL.name.toUpperCase().indexOf(searchName.toUpperCase()) !== -1) : false)),
      );
    };

    const onFocus = () => {
      onSearch('');
    };

    const formatOptionText = (
      oneValue: IdAndNameType,
    ): any => {

      return (
        <>
          <span
            className='text-ellipsis'
            style={{ float: 'left', maxWidth: '130px', display: 'inline-block' }}
          >{oneValue.id}
          </span>
          <span
            className='text-ellipsis'
            style={{ float: 'right', width: '130px', maxWidth: '130px', textAlign: 'right' }}
          >{oneValue.name}
          </span>
        </>
      );
    };

    const renderGroupTitle = () => {
      return (
        <div><FormattedMessage id='pricing_rules.detail.category_id' />
          <span style={{ float: 'right' }}> <FormattedMessage id='pricing_rules.detail.category_name' /></span>
        </div>
      );
    };

    const options = [
      <OptGroup key='GROUPCATEGORIES' label={renderGroupTitle()}>
        {dataSource.map(data =>
          <Option
            key={`${data.id}`}
            value={`${data.id} - ${data.name}`}
          >{formatOptionText(data)}
          </Option>)}
      </OptGroup>,
    ];

    return (
      <div ref={ref} className='scrolling-container'>
        <List
          itemLayout='vertical'
          size='small'
          dataSource={value}
          renderItem={(item, index) => (
            <List.Item
              // eslint-disable-next-line react/no-array-index-key
              key={`CAT${item.id}-${index}`}
            >
              <Row
                type='flex'
                align='middle'
                justify='space-around'
                className='minimal-size'
              >
                <Col span={23}>
                  <Form.Item
                    label={
                      <FormattedMessage id='pricing_rules.detail.category' />
                    }
                    required
                    {...categoryFormItemLayout}
                  >
                    <Select
                      showSearch
                      disabled={disabled}
                      labelInValue
                      filterOption={false}
                      style={{ width: '100%' }}
                      dropdownMatchSelectWidth={false}
                      onChange={(val: LabeledValue) =>
                        handleChange(
                          index,
                          val,
                        )
                      }
                      dropdownStyle={{ width: 300 }}
                      onSearch={onSearch}
                      value={{
                        key: item.externalId,
                        label: item.externalId || item.name ? `${item.externalId} - ${item.name}` : undefined,
                      } as LabeledValue}
                      onFocus={() => {
                        onFocus();
                      }}
                      notFoundContent={null}
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Col>
                {!disabled && (
                  <Col span={1}>
                    <Icon
                      className='delete-icon'
                      onClick={e => {
                        e.preventDefault();
                        handleRemove(item);
                      }}
                      type='delete'
                    />
                  </Col>
                )}
              </Row>
            </List.Item>
          )}
        />
      </div>
    );
  },
);

export default CategoryInputs;
