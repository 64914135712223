import { Action, action, Thunk, thunk } from 'easy-peasy';
import { OrderedMap } from 'immutable';
import { MaxDiscount, MaxDiscountListItem, RuleStates } from '../api/types';
import { StoreModel } from './types';
import { maxDiscountsApi } from '../api';

export const defaultMaxDiscount: MaxDiscount = {
  id: -1,
  state: RuleStates.DRAFT,
  maxDiscount: 0,
  productId: '',
  supplier: { id: '', name: '' },
  type: 'PARAMETER',
  category: { id: '', name: '' },
  parameter: { id: '', name: '' },
  ean: '',
  validFrom: '',
  validTo: '',
};

export interface MaxDiscountsStore {
  isLoading: boolean;
  list: OrderedMap<string, MaxDiscountListItem>;
  detail: MaxDiscount;
  selectedDetail?: { id: number, type: 'PRODUCT' | 'PARAMETER' };
  setSelectedDetail: Action<MaxDiscountsStore, { id: number, type: 'PRODUCT' | 'PARAMETER' }>;
  setLoading: Action<MaxDiscountsStore, boolean>;
  setList: Action<MaxDiscountsStore, MaxDiscountListItem[]>;
  setDetail: Action<MaxDiscountsStore, MaxDiscount>;
  fetchList: Thunk<MaxDiscountsStore, void, any, StoreModel>;
  fetchMaxDiscount: Thunk<MaxDiscountsStore,
    { id: number, type: 'PRODUCT' | 'PARAMETER' },
    any,
    StoreModel,
    Promise<boolean>>;
  createMaxDiscount: Thunk<MaxDiscountsStore,
    MaxDiscount,
    any,
    StoreModel,
    Promise<boolean>>;
  updateMaxDiscount: Thunk<MaxDiscountsStore,
    { originalType: 'PRODUCT' | 'PARAMETER', data: MaxDiscount },
    any,
    StoreModel,
    Promise<boolean>>;
  deleteMaxDiscount: Thunk<MaxDiscountsStore,
    { id: number, type: 'PRODUCT' | 'PARAMETER' },
    any,
    StoreModel,
    Promise<boolean>>;
}

const maxDiscounts: MaxDiscountsStore = {
  isLoading: false,
  list: OrderedMap(),
  detail: defaultMaxDiscount,
  selectedDetail: undefined,
  setSelectedDetail: action((state, payload) => {
    state.selectedDetail = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setList: action((state, payload) => {
    state.list = OrderedMap(payload.map(v => [`${v.id}_${v.type}`, v]));
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchList: thunk(async actions => {
    try {
      actions.setLoading(true);
      const { data } = await maxDiscountsApi.getMaxDiscountsList();
      actions.setList(data);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
    }
  }),
  fetchMaxDiscount: thunk(async (actions, value, { getStoreActions }) => {
    try {
      const { data } = await maxDiscountsApi.getMaxDiscountDetail(value.id, value.type);
      actions.setDetail(data);
      getStoreActions().general.setSupplier(data.supplier);
      getStoreActions().general.setParameter(data.parameter);

    } catch (error) {
      return false;
    }
    return true;
  }),
  createMaxDiscount: thunk(async (actions, value) => {
    try {
      await maxDiscountsApi.createMaxDiscount(value);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  updateMaxDiscount: thunk(async (actions, value) => {
    try {
      await maxDiscountsApi.updateMaxDiscount(value.originalType, value.data);
      await actions.fetchList();
    } catch (error) {
      return false;
    }
    return true;
  }),
  deleteMaxDiscount: thunk(async (actions, value) => {
    try {
      actions.setLoading(true);
      await maxDiscountsApi.deleteMaxDiscount(value.id, value.type);
      await actions.fetchList();
    } catch (error) {
      actions.setLoading(false);
      return false;
    }
    return true;
  }),
};

export default maxDiscounts;
