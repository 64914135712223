export default {
  side_bar: {
    product_exceptions: 'Product exceptions',
    pricing_rules: 'Pricing rules',
    max_discounts: 'Max discounts',
    min_margins: 'Min margins',
    top_n_categories: 'Top N categories',
    version: 'version',
    dashboard: 'Dashboard',
    dashboard_list: 'List',
    dashboard_reports: 'Reports',
    dashboard_list_full: 'Dashboard - list',
    dashboard_reports_full: 'Dashboard - reports',
  },
  header: { logout: 'Log out' },
  common: {
    update: 'update',
    inactive: 'inactive',
    save: 'Save',
    saveAsDraft: 'Save as draft',
    confirmDraft: 'Confirm draft',
    confirmation: 'Confirmation',
    confirm_delete_selected: 'Are you sure to delete selected items?',
    confirm_selected: 'Are you sure to confirm selected items?',
    confirm_delete: 'Are you sure to delete selected item?',
    confirm_delete_group: 'Are you sure to delete current group?',
    delete: 'Delete',
    markForDelete: 'Mark for delete',
    confirmDelete: 'Confirm delete',
    revertDelete: 'Revert deletion',
    date_mask: 'YYYY-MM-DD',
    message: {
      success_new: 'Record was successfully created.',
      success_edit: 'Record was successfully updated.',
      error: 'Error! Record was not saved.',
      success_upload: 'File uploaded successfully.',
      error_upload: 'File uploaded failed.',
      no_records_selected: 'No records selected!',
      success_delete: 'Record was successfully deleted.',
    },
    combo_search_placeholder: 'Please type least 3 chars for search',
    combo_search_more_results:
      'There are total {total} results, showing first {limit}, please be more specific ...',
    result_preview: 'Result preview',
    ok: 'OK',
    true: 'true',
    false: 'false',
    close: 'Close',
    cancel: 'Cancel',
    ruleStates: {
      DRAFT: 'Draft',
      LOCKED: 'Source for draft',
      FINAL: 'Final',
      TO_DELETE: 'Marked for deletion',
      DRAFT_tooltip:
        'Rule must be confirmed before it might be used for product pricing',
      LOCKED_tooltip:
        'Rule might be used for product pricing but has pending change and cannot be edited',
      FINAL_tooltip: 'Rule might be used for product pricing',
      TO_DELETE_tooltip:
        'Rule might be used for product pricing but is marked for delete',
      usedRule: 'Ready',
      draftRule: 'Draft',
      newRule: 'New',
    },
  },
  login: {
    title: 'Log in',
    username: 'Username',
    password: 'Password',
    remember: 'Remember me',
    button: 'Log in',
    validations: {
      username: 'Please input your username!',
      password: 'Please input your password!',
    },
  },
  pricing_rules: {
    table: {
      titles: {
        id: 'ID',
        name: 'Name',
        suppliers: 'Suppliers',
        categories: 'Categories',
        parameters: 'Parameters',
        valid_from: 'Valid from',
        valid_to: 'Valid to',
        active: 'Active',
        state: 'State',
      },
    },
    detail: {
      title: 'Pricing rule detail',
      name: 'Name',
      active: 'Active',
      valid_from: 'Valid from',
      valid_to: 'Valid to',
      category_id: 'Category ID',
      category_name: 'Category name',
      supplier_id: 'Supplier ID',
      supplier_name: 'Supplier name',
      parameter_id: 'Parameter ID',
      parameter_name: 'Parameter name',
      priority: 'Priority',
      priority_tooltip: 'Highest priority takes precedence.',
      add_an_interval: 'add an interval',
      price_from: 'Price from',
      price_to: 'Price to',
      eval_expression: 'Eval. expression',
      add_a_parameter: 'add a parameter',
      create_a_new_param_group: 'Create a new parameter group',
      supplier: 'Supplier',
      category: 'Category',
      param: 'Parameter',
      record_count: 'Showing {actual} from {total} groups ',
    },
  },
  min_margins: {
    table: {
      titles: {
        id: 'ID',
        category: 'Category',
        purchase_price_from: 'Purchase price from',
        purchase_price_to: 'Purchase price to',
        min_margin: 'Min margin',
        state: 'State',
      },
    },
    detail: {
      title: 'Min margin detail',
      category_id: 'Category ID',
      category_name: 'Category name',
      purchase_price_from: 'Purchase price from',
      purchase_price_to: 'Purchase price to',
      min_margin: 'Min margin',
    },
  },
  max_discounts: {
    table: {
      titles: {
        id: 'ID',
        productId: 'Product ID',
        ean: 'EAN',
        type: 'Type',
        parameter: 'Parameter',
        valid_from: 'Valid from',
        valid_to: 'Valid to',
        max_discount: 'Max discount',
        supplier: 'Supplier',
        category: 'Category',
        state: 'State',
      },
    },
    detail: {
      title: 'Max discount detail',
      type: 'Type',
      category_id: 'Category ID',
      category_name: 'Category name',
      supplier_id: 'Supplier ID',
      supplier_name: 'Supplier name',
      parameter_id: 'Parameter ID',
      parameter_name: 'Parameter name',
      supplier: 'Supplier',
      parameter: 'Parameter',
    },
  },
  product_exceptions: {
    search_placeholder: 'Batch # filter',
    import_csv: 'Import CSV',
    select_all: 'Select all based on import number',
    delete_selected: 'Delete selected',
    confirm_selected: 'Confirm selected',
    mark_delete_selected: 'Mark for delete selected',
    import_tooltip: 'Click to read more about import format and example ...',
    import_hint_title: 'Import CSV example',
    import_hint_text:
      'Columns in the CSV file are separated by a semicolon. CSV file to import needs to meet this file structure:',
    import_hint_text_example: 'Below you can find an example of a CSV file:',
    import_hint_text_csv: `product_id;ean;evaluation_expression;active;valid_from;valid_to;param_id;param_id;param_id\n
      product123;9788086938998;pPRICE*1.21;TRUE;2021-03-13;2022-03-13;param001;param002;param003\n`,
    import_hint_text_csv2: `product_id: id of the product. Conditionally optional - at least one of product_id, ean or param_id needs to be used.<br />
      ean: ean of the product. Conditionally optional - at least one of product_id, ean or param_id needs to be used.<br />
      evaluation_expression: price expression for the product. One of the following options can be used: <br />
      <ul>
      <li>absolute value (example: 129)</li>
<li>calculated price based on</li>
<ul>
<li>DPC - Recommended retail price of the product (example DPC+13)</li>
<li>MOC - Retail selling price of the product (example MOC*0.9)</li>
<li>pPRICE - Lower value from DPC and MOC (example pPRICE-1)</li>
<li>bPRICE - Purchase price of the product (bPRICE*1.1)</li>
</ul>
</ul>    
      active: TRUE/FALSE<br />
      valid_from: YYYY-MM-DD (example: 2021-03-13)<br />
      valid_to: YYYY-MM-DD (example: 2021-03-13)<br />
      param_id: id of the parameter. If more parameters need to be added, it can repeat multiple times within CSV. Conditionally optional - at least one of product_id, ean or param_id needs to be used.`,
    import_hint_link:
      'Example CSV file to download <a href="{link}" target="_blank">here.</a>',
    table: {
      titles: {
        id: 'ID',
        productId: 'Product ID',
        ean: 'EAN',
        eval_expression: 'Eval. expression',
        parameters: 'Parameters',
        valid_from: 'Valid from',
        valid_to: 'Valid to',
        batch: 'Batch#',
        active: 'Active',
        state: 'State',
      },
    },
    detail: {
      title: 'Product exception detail',
      params: {
        param_id: 'Parameter ID',
        param_name: 'Parameter Name',
        param: 'Parameter',
      },
    },
  },
  top_n_categories: {
    table: {
      titles: {
        id: 'ID',
        category: 'Category',
        priority: 'Priority',
        price_from: 'Price from',
        price_to: 'Price to',
        top_n: 'Top N rank',
        top_n_expression: 'Top N expression',
        rounding: 'Rounding',
        compared_shops_ids: 'Compared shops ids',
        state: 'State',
      },
    },
    detail: {
      title: 'TOP N category range detail',
      category: 'Category',
      price_from: 'Price from',
      price_to: 'Price to',
      top_n: 'Top N rank',
      top_n_expression: 'Top N expr.',
      top_n_expression_choice: {
        sPRICE: 'Competitor`s price',
        pPRICE: 'Discount price',
        sPRICE_help: 'Competitor`s price',
        pPRICE_help: 'Discount price',
      },
      rounding: 'Rounding',
      priority: 'Priority',
      compared_shops: 'Compared shops',
      heureka_shop: 'Heureka shop',
      heureka_shop_id: 'Heureka shop id',
      heureka_shop_name: 'Heureka shop name',
    },
  },
  dashboard_products: {
    button_recalculate: 'RECALCULATE DRAFT PRICES',
    button_recalculate_actual: 'RECALCULATE ACTUAL PRICES',
    button_confirm_all: 'CONFIRM ALL CHANGES',
    confirm_recalculate: 'Are you sure to recalculate all prices?',
    confirm_changes: 'Are you sure to confirm all prepared rule?',
    message_calculating: 'The price of the products is being recalculated.',
    message_products_errors:
      'There are products for which the price could not be calculated, please check each product for details.',
    message_calculation_error: 'There was error in last pricing recalculation',
    table: {
      titles: {
        id: 'Product id',
        ean: 'EAN',
        title: 'Title',
        supplier: 'Supplier',
        category: 'Category',
        parameters: 'Parameters',
        basePrice: 'Base price',
        actualPrice: 'Actual price',
        actualPriceDiscount: 'Actual price discount',
        newPrice: 'New price',
        newPriceDiscount: 'New price discount',
        change: 'Change',
      },
    },
    detail: {
      formTitle: 'Product detail',
      buttonShowRule: 'Show rules',
      id: 'Product id',
      ean: 'EAN',
      title: 'Title',
      supplier: 'Supplier',
      category: 'Category',
      parameters: 'Parameters',
      skipTopN: 'Skip TOP N',
      skipMinMargin: 'Skip min margin',
      skipMaxDiscount: 'Skip max discount',
      rrp: 'RRP',
      rsp: 'RSP',
      purchasePrice: 'Purchase price',
      sellingPriceRate: 'X Rate',
      rounding: 'Rounding digits',
      basePrice: 'Base price',
      actualPrice: 'Actual price',
      actualPriceDiscount: 'Actual discount',
      newPrice: 'New price',
      newPriceDiscount: 'New discount',
      actualError: 'Actual pricing error',
      newError: 'New pricing error',
      priced: 'Priced',
    },
    rulesDetail: {
      title: 'Rules',
      step: 'Algorithm step',
      actualLabel: 'Actual price',
      newLabel: 'New price',
      rule: 'Rule Id',
      result: 'Result',
      algorithmSteps: {
        PRODUCT_EXCEPTIONS: 'Product exceptions',
        DISCOUNT_RULES: 'Discount rules',
        TOP_N: 'Top N',
        MIN_MARGIN: 'Minimal margin',
        MAX_DISCOUNT: 'Max discount',
      },
    },
  },
  logout: 'Logging out...',
  validations: {
    required: 'This field is required!',
  },
  dashboard_reports: {
    by_category: 'By category',
    by_supplier: 'By supplier',
    category: 'Category',
    number_of_titles: 'Number of titles',
    number_of_titles_pending: 'Number of titles with pending price change',
    supplier: 'Supplier',
    sum_title:
      'There is {sumPending} products with pending price change from total {sum} products.',
  },
  enums: {
    rounding: {
      NORMAL: 'NORMAL',
      UP: 'UP',
      DOWN: 'DOWN',
      DOWN9: 'DOWN9',
    },
    expressions: {
      ABS: 'Absolute',
      DPC: 'Rec. Retail Price',
      MOC: 'Retail selling price',
      pPRICE: 'Lowest retail price',
      bPRICE: 'Purchase price',
      ABS_help: '',
      DPC_help: 'Rec. retail price',
      MOC_help: 'Retail selling price',
      pPRICE_help: 'Lowest retail price',
      bPRICE_help: 'Purchase price',
    },
  },
};
