import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Card, Col, Row, Table } from 'antd';
import { useStoreActions, useStoreState } from '../store/hooks';
import { DashboardReportRow } from '../api/types';
import CategoryInput from '../components/CategoryInput';
import SupplierInput from '../components/SupplierInput';

const DashboardReportsPage: React.FC = () => {
  const {
    listOfCategories,
    isLoadingCategories,
    isLoadingSuppliers,
    listOfSuppliers,
  } = useStoreState(state => state.dashboardReports);
  const { fetchListCategories, fetchListSuppliers } = useStoreActions(
    actions => actions.dashboardReports,
  );
  const { fetchCategories } = useStoreActions(actions => actions.general);

  const [category, setCategory] = useState('');
  const [supplier, setSupplier] = useState('');

  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchListCategories();
    fetchListSuppliers();
    fetchCategories();
  }, [fetchListCategories, fetchListSuppliers, fetchCategories]);

  const sortData = (data?: DashboardReportRow[], filterId?: string) => {
    if (!data) {
      return [];
    }

    if (filterId) {
      data = data.filter(d => d.id === filterId);
    }

    data.sort((a, b) => {
      if (a.noOfTitlesPendingPrice < b.noOfTitlesPendingPrice) {
        return 1;
      }
      if (a.noOfTitlesPendingPrice > b.noOfTitlesPendingPrice) {
        return -1;
      }

      if (a.noOfTitles < b.noOfTitles) {
        return 1;
      }
      if (a.noOfTitles > b.noOfTitles) {
        return -1;
      }

      return 0;
    });

    return data;
  };

  const expandedCategoryRowRender = (record: DashboardReportRow) => {
    return (
      <Table<DashboardReportRow>
        dataSource={sortData(record.subRows)}
        size='middle'
        rowKey={rec =>
          `c_sub_${rec.name}_${rec.noOfTitles}_${rec.noOfTitlesPendingPrice}`
        }
        className='nested-table'
        pagination={{ pageSize: 10 }}
        columns={[
          {
            dataIndex: 'name',
            title: formatMessage({ id: 'dashboard_reports.supplier' }),
          },
          {
            dataIndex: 'noOfTitles',
            title: formatMessage({
              id: 'dashboard_reports.number_of_titles',
            }),
            width: 200,
            align: 'center',
          },
          {
            dataIndex: 'noOfTitlesPendingPrice',
            title: formatMessage({
              id: 'dashboard_reports.number_of_titles_pending',
            }),
            width: 200,
            align: 'center',
          },
        ]}
      />
    );
  };

  const expandedSupplierRowRender = (record: DashboardReportRow) => {
    return (
      <Table<DashboardReportRow>
        dataSource={sortData(record.subRows)}
        size='middle'
        rowKey={rec =>
          `s_sub_${rec.name}_${rec.noOfTitles}_${rec.noOfTitlesPendingPrice}`
        }
        className='nested-table'
        pagination={{ pageSize: 10 }}
        columns={[
          {
            dataIndex: 'name',
            title: formatMessage({ id: 'dashboard_reports.category' }),
          },
          {
            dataIndex: 'noOfTitles',
            title: formatMessage({
              id: 'dashboard_reports.number_of_titles',
            }),
            width: 200,
            align: 'center',
          },
          {
            dataIndex: 'noOfTitlesPendingPrice',
            title: formatMessage({
              id: 'dashboard_reports.number_of_titles_pending',
            }),
            width: 200,
            align: 'center',
          },
        ]}
      />
    );
  };

  let sum = 0;
  let sumPending = 0;
  listOfCategories.forEach(c => {
    sum += c.noOfTitles;
    sumPending += c.noOfTitlesPendingPrice;
  });

  return (
    <div className='dashboard dashboard-reports'>
      <Row gutter={[24, 40]}>
        <Col>
          {!isLoadingCategories && (
            <Alert
              message={
                <FormattedMessage
                  id='dashboard_reports.sum_title'
                  values={{ sum, sumPending }}
                />
              }
              type='info'
              showIcon
            />
          )}
        </Col>
      </Row>
      <Row gutter={[24, 40]}>
        <Col span={12}>
          <Card
            title={
              <CategoryInput
                customLabelCol={4}
                value={category}
                onChange={setCategory}
                isFilter
              />
            }
            bordered
          >
            <Table<DashboardReportRow>
              loading={isLoadingCategories}
              dataSource={sortData(listOfCategories, category)}
              size='middle'
              rowKey={rec =>
                `c_${rec.name}_${rec.noOfTitles}_${rec.noOfTitlesPendingPrice}`
              }
              expandedRowRender={expandedCategoryRowRender}
              columns={[
                {
                  dataIndex: 'name',
                  title: formatMessage({ id: 'dashboard_reports.category' }),
                  align: 'left',
                  width: 150,
                },
                {
                  dataIndex: 'noOfTitles',
                  title: formatMessage({
                    id: 'dashboard_reports.number_of_titles',
                  }),
                  width: 200,
                  align: 'center',
                },
                {
                  dataIndex: 'noOfTitlesPendingPrice',
                  title: formatMessage({
                    id: 'dashboard_reports.number_of_titles_pending',
                  }),
                  width: 200,
                  align: 'center',
                },
              ]}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <SupplierInput
                customLabelCol={4}
                value={supplier}
                onChange={setSupplier}
                isFilter
              />
            }
            bordered
          >
            <Table<DashboardReportRow>
              loading={isLoadingSuppliers}
              dataSource={sortData(listOfSuppliers, supplier)}
              size='middle'
              rowKey={rec =>
                `s_${rec.name}_${rec.noOfTitles}_${rec.noOfTitlesPendingPrice}`
              }
              expandedRowRender={expandedSupplierRowRender}
              columns={[
                {
                  dataIndex: 'name',
                  title: formatMessage({ id: 'dashboard_reports.supplier' }),
                  align: 'left',
                  width: 150,
                },
                {
                  dataIndex: 'noOfTitles',
                  title: formatMessage({
                    id: 'dashboard_reports.number_of_titles',
                  }),
                  width: 200,
                  align: 'center',
                },
                {
                  dataIndex: 'noOfTitlesPendingPrice',
                  title: formatMessage({
                    id: 'dashboard_reports.number_of_titles_pending',
                  }),
                  width: 200,
                  align: 'center',
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default DashboardReportsPage;
