import { AxiosPromise } from 'axios';
import http from './httpBase';
import { BusinessResultEntity, IdAndNameType } from './types';
import {
  businessCategoriesData,
  businessHeurekaShopsData,
  businessParametersData,
  businessSuppliersData,
} from './mockData';

const { get, mock } = http;
const BUSINESS_URL = '/business-entities';

const getParameters = (search: string, pageSize: number): AxiosPromise<BusinessResultEntity> =>
  get(`${BUSINESS_URL}/parameter`, {
    params: { search, pageSize },
  });

mock.onGet(/\/business-entities\/parameter/).reply(200, businessParametersData);

const getParameter = (externalId: string): AxiosPromise<IdAndNameType> =>
  get(`${BUSINESS_URL}/parameter/${externalId}`);

mock.onGet(/\/business-entities\/parameter\/\w+/).reply(200, businessParametersData.data[0]);


const getSuppliers = (search: string, pageSize: number): AxiosPromise<BusinessResultEntity> =>
  get(`${BUSINESS_URL}/supplier`, {
    params: { search, pageSize },
  });

mock.onGet(/\/business-entities\/supplier/).reply(200, businessSuppliersData);

const getSupplier = (externalId: string): AxiosPromise<IdAndNameType> =>
  get(`${BUSINESS_URL}/supplier/${externalId}`);

mock.onGet(/\/business-entities\/supplier\/\w+/).reply(200, businessSuppliersData.data[0]);


const getCategories = (search: string, pageSize: number): AxiosPromise<BusinessResultEntity> =>
  get(`${BUSINESS_URL}/category`, {
    params: { search, pageSize },
  });

mock.onGet(/\/business-entities\/category/).reply(200, businessCategoriesData);

const getHeurekaShops = (search: string, pageSize: number): AxiosPromise<BusinessResultEntity> =>
  get(`${BUSINESS_URL}/heureka-shop`, {
    params: { search, pageSize },
  });

mock
  .onGet(/\/business-entities\/heureka-shop/)
  .reply(200, businessHeurekaShopsData);

export default {
  getParameters,
  getSuppliers,
  getCategories,
  getHeurekaShops,
  getSupplier,
  getParameter,
};
